import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Collapse,
  Fab,
  Divider,
} from "@mui/material";
import Guides from "../../components/Guides";
import Hero from "../../components/courses/Hero";
import Sidebar from "../../components/courses/Sidebar";
import CoursesSlider from "../../components/courses/CoursesSlider";
import ContentSlider from "../../components/courses/ContentSlider";
import NavGroup from "../../components/courses/NavGroup";
import MenuDropdownLink from "../../components/menuDropdown/MenuDropdownLink";
import ParshaSection from "./ParshaSection";
import { pxToRem } from "px2rem2px";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { ReactComponent as Bookmarks } from "../../images/bookmarks.svg"

const SidebarCourses = ({
  isSmallScreen,
  isMediumScreen
}) => {
  return (
    <Box
    sx={{
      display: "flex",
      width: {xs: "100%", md: "180px"},
      flexDirection: isSmallScreen
        ? "column"
        : isMediumScreen
        ? "row"
        : "column",
      px: { xs: pxToRem(10), md: 0 },
      mt: { xs: pxToRem(10), lg: 0 },
    }}
  >
      <NavGroup title="Recently Added" sx={{borderBottom: "none", mb: "0px"}} noTitle>
        <MenuDropdownLink to="/">Submenu link 01</MenuDropdownLink>
        <MenuDropdownLink to="/">Another link 02</MenuDropdownLink>
        <MenuDropdownLink to="/">Submenu link 03</MenuDropdownLink>
        <MenuDropdownLink to="/">Another link 04</MenuDropdownLink>
        <MenuDropdownLink to="/">Another link 05</MenuDropdownLink>
        <MenuDropdownLink to="/">Another link 06</MenuDropdownLink>
      </NavGroup>
      <Box display={{xs: "none", md: "block"}}>
        <Typography
          fontSize="14px"
          lineHeight="18px"
          fontWeight={600}
          mb="20px"
        >
          Your Bookmarks
        </Typography>
        <Button
          fullWidth
          variant="green"
          size="small"
          sx={{ backgroundColor: "#026670", color: "#FCE181", fontSize: "14px !important"}}
        >
          <Bookmarks style={{ marginRight: "8px"}}/>Bookmarks
        </Button>
      </Box>
      <Divider
          sx={{ my: { xs: "20px", md: pxToRem(30) }, display: {xs: "none", md: "block"} }}
        />
      {!isMediumScreen && (
      <Box>
        <Typography
          fontSize="14px"
          lineHeight="18px"
          fontWeight={600}
          mb="20px"
        >
          Receive notifications for new lessons!
        </Typography>
        <Button
          fullWidth
          variant="outlined"
          size="small"
          sx={{ fontSize: "14px !important", "&:hover .MuiSvgIcon-root": {color: "#FCE181", transition: "all 200ms ease-out"} }}
        >
          <NotificationsIcon sx={{color: "#026670", mr: "8px", width: "26px", height: "30px"}}/>Get Notified
        </Button>
      </Box>
      )}
    </Box>
  )
}

const Parsha = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery("(max-width:899px)");
  const isLargeScreen = useMediaQuery("(min-width:1227px)");
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
    useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box position="relative">
      {mobile_ssm && (
        <Box>
          <Fab
            size="small"
            aria-label="scroll back to top"
            onClick={scrollToTop}
            sx={{
              position: "fixed",
              bottom: 17,
              right: 17,
              zIndex: 50,
              opacity: isVisible ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
              boxShadow: "0px 10px 20px #00000012",
              backgroundColor: "#FCE181",
              color: "#026670",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#026670",
                color: "#FCE181",
              },
            }}
          >
            <KeyboardArrowUpIcon style={{ width: "28px", height: "28px" }} />
          </Fab>
        </Box>
      )}
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Hero
        title="Video Library"
        sx={{
          marginBottom: { xs: pxToRem(63), md: pxToRem(100) },
          overflow: isLargeScreen ? "visible" : "hidden",
        }}
      />

      <Box position="relative">
         <Box
          sx={{
            width: { xs: "100%", md: "90%" },
            margin: isMediumScreen ? "auto" : undefined,
          }}
        >
          <Stack
            flexDirection={isMediumScreen ? "column" : "row"}
            justifyContent="space-between"
            gap="5%"
            mb="100px"
            width="100%"
            maxWidth="1367px"
            position="relative"
          >
           {mobile_ssm && (
              <Box
                sx={{ height: "70px" }}
              ></Box>
            )}
            <Sidebar
             display="flex"
             sx={{
               gap: "0",
               padding: isMediumScreen ? "20px 25px" : pxToRem(50),
               position: { xs: "absolute", ssm: "static" },
               zIndex: { xs: 2, ssm: 0 },
               mx: { ssm: "25px", md: 0 },
             }}
            >
              {isMediumScreen ? (
                <>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ cursor: "pointer" }}
                    onClick={handleToggle}
                  >
                    <Stack flexDirection="row" alignItems="center">
                      <Typography sx={{ fontWeight: 600, fontSize: "17px" }}>
                        Recently Added
                      </Typography>
                    </Stack>
                    <KeyboardArrowDownIcon
                      sx={{
                        width: pxToRem(31),
                        height: pxToRem(31),
                        transition: "all 300ms ease-in-out",
                        transform: isExpanded ? "rotate(-180deg)" : "rotate(0)",
                      }}
                    />
                  </Stack>
                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <SidebarCourses
                      isSmallScreen={isSmallScreen}
                      isMediumScreen={isMediumScreen}
                    />
                  </Collapse>
                </>
              ) : (
                <SidebarCourses
                  isSmallScreen={isSmallScreen}
                  isMediumScreen={isMediumScreen}
                />
              )}
            </Sidebar>
            <Box mx={ {xs: "25px", md: 0}} mt={ {xs: "20px", md: 0}} marginBottom={{ xs: pxToRem(63), ssm: pxToRem(94), md: 0 }} display={{md: "none"}}>
              <Button
                fullWidth
                variant="green"
                size="small"
                sx={{ backgroundColor: "#026670", color: "#FCE181", fontSize: "14px !important"}}
              >
                <Bookmarks style={{ marginRight: "8px"}}/>Bookmarks
              </Button>
            </Box>
            {/* </Stack> */}
            <Box flex={1} padding={{ xs: "0 25px", md: "0" }} width={isMediumScreen ? "100%" : "70%"}>
              <CoursesSlider />
              <ParshaSection />
            </Box>
          </Stack>
        </Box>
      </Box>

      <Container disableGutters>
        <ContentSlider />
      </Container>
    </Box>
  );
};

export default Parsha;
