// const hebrewRegex = /[\u0590-\u05FF]/;
// const englishRegex = /[A-Za-z]/;

// const stripHTML = (html) => {
//   const div = document.createElement("div");
//   div.innerHTML = html;
//   return div.textContent || div.innerText || "";
// };

// export const getLanguageClass = (html,fontOnly,directionOnly) => {
//   // plain text
//   const text = stripHTML(html).trim();

//   for (let i = 0; i < text?.length; i++) {
//     const char = text[i];
//     if (hebrewRegex.test(char)) {
//       if(fontOnly){
//         return "hebrew-font";
//       }
//       return "is-hebrew-text";
//     } else if (englishRegex.test(char)) {
//       if(fontOnly){
//         return "english-font";
//       }
//       return "is-english-text";
//     }
//   }

//   return null;
// };

const hebrewRegex = /[\u0590-\u05FF]/;
const englishRegex = /[A-Za-z]/;

const stripHTML = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

export const getLanguageClass = (html, fontOnly, directionOnly,log) => {
  // plain text
  const text = stripHTML(html).trim();
  // console.log("getLanguageClass called")

  if (log) {
    console.log("The text contains:", text);
  }

  for (let i = 0; i < text?.length; i++) {
    const char = text[i];
    if (hebrewRegex.test(char)) {
      if (directionOnly) {
        return "rtl-direction";
      } else if (fontOnly) {
        return "hebrew-font";
      }
      return "is-hebrew-text";
    } else if (englishRegex.test(char)) {
      if (directionOnly) {
        return "ltr-direction";
      } else if (fontOnly) {
        return "english-font";
      }
      return "is-english-text";
    }
  }

  return null;
};
