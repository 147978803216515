import { url } from "./config";
import axios from "axios";

export const getBlogs = async () => {
  const response = await axios.get(`${url}/api/blogs`);
  return response;
};
export const getBlog = (id) => {
  return axios
    .get(`${url}/api/blogs/${id}`)
    .then((response) => response)
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        console.error(`Blog with ID ${id} not found.`);
        window.location.href = "/404"; // Redirect to the 404 page
      } else {
        console.error("Error fetching blog:", error);
        throw error;
      }
    });
};
