import React from "react";
import { Box, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px"; // Adjust path if necessary

export default function InfoBlock({ title, children }) {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: pxToRem(20),
        p: {
          xs: pxToRem(30),
          ssm: `${pxToRem(36)} ${pxToRem(40)} ${pxToRem(30)}`,
        },
        mb: pxToRem(20),
      }}
    >
      <Typography
        fontSize={pxToRem(20)}
        lineHeight={pxToRem(30)}
        fontWeight={700}
        mb={pxToRem(30)}
      >
        {title}
      </Typography>
      <Box borderTop="1px solid rgba(191,190,187,0.5)" pt={pxToRem(30)} >
        {children}
      </Box>
    </Box>
  );
}
