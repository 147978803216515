import api from "./api";
import axios from "axios";
import { url } from "./config";
import { checkAuth } from "../utils/checkAuth";

export async function getCategories() {
  const res = await axios.get(`${url}/api/parshas/categories/`);
  return res.data;
}

export async function getSubgroupVideos(subgroupId) {
  const res = await axios.get(`${url}/api/parshas/${subgroupId}/videos/`);
  return res;
}


export async function postVideoLike(videoId) {
  const res = await axios.post(`${url}/api/parshas/videos/${videoId}/like/`);
  return res.data;
}

/* export async function getGroups(
  pageSize = 10,
  page = 1,
  orderBy,
  orderDirection
) {
  const res = await api.get(
    `/api/parshas/groups?page_size=${pageSize}&page=${page}&ordering=${
      orderDirection === "desc" ? "-" : ""
    }${orderBy}`
  );
  return res.data;
}

export async function getGroupDetails(groupID) {
  const res = await api.get(`/api/parshas/categories/${groupID}/`);
  return res.data;
}


export async function createSubgroup(payload) {
  const res = await api.post(`/api/parshas/sub_groups/`, payload);
  return res.data;
}

export async function updateSubgroup(videoGroupId, payload) {
  const res = await api.patch(
    `/api/parshas/sub_groups/${videoGroupId}/`,
    payload
  );
  return res.data;
}

export async function deleteSubgroup(videoGroupId) {
  const res = await api.delete(`/api/parshas/sub_groups/${videoGroupId}/`);
  return res.data;
}

export async function createVideo(payload) {
  const res = await api.post("/api/parshas/videos/", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
}

export async function updateVideo(videoId, payload) {
  const res = await api.patch(`/api/parshas/videos/${videoId}/`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
}

export async function getVideos(pageSize, page, orderBy, orderDirection) {
  const res = await api.get(
    `/api/parshas/videos/?page_size=${pageSize}&page=${page}&ordering=${
      orderDirection === "desc" ? "-" : ""
    }${orderBy}`
  );
  return res;
}

export async function createGroup(payload) {
  const res = await api.post(`/api/parshas/categories/`, payload);
  return res.data;
}

export async function updateGroup(groupId, payload) {
  const res = await api.patch(`/api/parshas/categories/${groupId}/`, payload);
  return res.data;
}

export async function deleteGroup(groupId) {
  const res = await api.delete(`/api/parshas/categories/${groupId}/`);
  return res.data;
}

export async function getSubgroups(pageSize, page, orderBy, orderDirection) {
  const res = await api.get(
    `/api/parshas/sub_groups/?page=${page}&page_size=${pageSize}&ordering=${
      orderDirection === "desc" ? "-" : ""
    }${orderBy}`
  );
  return res;
}
 */
