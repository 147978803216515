import { useEffect, useState, useRef, memo, useCallback } from "react";
import {
  Box,
  Stack,
  Typography,
  Tooltip,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import { ReactComponent as Check } from "../../../images/check.svg";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as Lock } from "../../../images/lock.svg";
import Chevron from "../../../images/chevron.svg";
import TextLink from "../../../components/TextLink";
import { handleResolve } from "../../../utils/Enrollment";
import { getLanguageClass } from "../../../utils/languageClass";

// const Sidebar = (props) => {
const Sidebar = memo((props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const contentRef = useRef(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [courseOverviewIsOpen, setCourseOverviewIsOpen] = useState(false);
  const [lastLessonId, setLastLessonId] = useState(null);
  const previousScrollPosition = useRef(null);
  const [openTooltipId, setOpenTooltipId] = useState(null);

  // const lessonLimit =
  // !props.isCollapsable && tablet ? (mobile ? 4 : 6) : props.lessons.length;

  // const lessonsToDisplay =
  //   showFullDescription || props.isCollapsable || !tablet
  //     ? props.lessons
  //     : props.lessons.slice(0, lessonLimit);

  ////

  const [lessonLimit, setLessonLimit] = useState(0);
  const [lessonsToDisplay, setLessonsToDisplay] = useState([]);

  const { isCollapsable, lessons } = props;

  useEffect(() => {
    const limit = !isCollapsable && tablet ? (mobile ? 4 : 6) : lessons.length;
    setLessonLimit(limit);

    const display =
      showFullDescription || isCollapsable || !tablet
        ? lessons
        : lessons.slice(0, limit);
    setLessonsToDisplay(display);
  }, [isCollapsable, lessons, showFullDescription, tablet, mobile]);

  ////

  const handleCourseOverviewClick = useCallback(() => {
    setCourseOverviewIsOpen((prevState) => !prevState);
  }, []);

  const handleItemClick = useCallback(
    (item) => {
      props?.setBlockAutoLectureId(true);
      props?.setForceJumpToLatestLecture(false);
      console.log("", item);

      console.log("handleItemClick was called", item);

      if (
        item.status !== "unwatched" &&
        item.status !== "locked" &&
        item.status !== "unsubscribed" &&
        item.status !== "anonymous" &&
        item.status !== "expired" &&
        item.status !== "paused"
      ) {
        const updatedPreviousStates = { ...props.previousLessonStates };

        if (props.playingLessonId !== null) {
          const previousPlayingLesson = props.lessons.find(
            (lesson) => lesson.id === props.playingLessonId
          );
          if (previousPlayingLesson) {
            updatedPreviousStates[props.playingLessonId] =
              previousPlayingLesson.status;
          }
        }
        updatedPreviousStates[item.id] = item.status;
        props.setPreviousLessonStates(updatedPreviousStates);
        props.onLessonClick(item);

        // Update the status of the clicked lesson
        const updatedLessons = props.lessons.map((lesson) => {
          if (lesson.id === item.id) {
            return { ...lesson, status: "playing" };
          } else if (lesson.id === props.playingLessonId) {
            return {
              ...lesson,
              status: props.previousLessonStates[lesson.id] || "available",
            };
          } else {
            return lesson;
          }
        });

        props.setPlayingLessonId(item.id);
        props.updateLessons(updatedLessons);
      }
    },
    [
      props.lessons,
      props.onLessonClick,
      props.playingLessonId,
      props.previousLessonStates,
      props.setBlockAutoLectureId,
      props.setForceJumpToLatestLecture,
      props.setPlayingLessonId,
      props.setPreviousLessonStates,
      props.updateLessons,
    ]
  );

  const handleReadMoreClick = useCallback(
    (event) => {
      event.preventDefault();
      previousScrollPosition.current = window.scrollY;
      setShowFullDescription((prevState) => !prevState);
    },
    [previousScrollPosition]
  );

  useEffect(() => {
    if (showFullDescription) {
      window.scrollBy(0, -(window.scrollY - previousScrollPosition.current));
      previousScrollPosition.current = null;
    }
  }, [showFullDescription]);

  useEffect(() => {
    if (props?.lessons && props?.lessons.length > 0) {
      const lastLesson = props?.lessons[props?.lessons.length - 1];
      setLastLessonId(lastLesson.id);
    }
  }, [props?.lessons]);

  //
  useEffect(() => {
    console.log("Sidebar re-rendered");
  }, [props?.lessons]);

  // analyze performance






  return (
    <Box
      width={{ xs: "100%", md: pxToRem(300) }}
      backgroundColor={{
        xs: !props.isCollapsable ? "#EDECE8" : "",
        ssm: "#EDECE8",
      }}
      pt={{
        xs: !props.isCollapsable ? pxToRem(40) : "",
        ssm: pxToRem(40),
        md: pxToRem(150),
      }}
      mt={{ md: pxToRem(-150) }}
      pl={{ xs: !props.isCollapsable ? pxToRem(40) : "", ssm: pxToRem(40) }}
      pb={{ xs: !props.isCollapsable ? pxToRem(40) : "", ssm: pxToRem(40) }}
      pr={{
        xs: !props.isCollapsable ? pxToRem(40) : "",
        ssm: pxToRem(40),
        md: pxToRem(20),
        lg: 0,
      }}
      borderRadius={{
        xs: !props.isCollapsable ? pxToRem(20) : "",
        ssm: pxToRem(40),
      }}
      flexShrink={0}
      sx={{
        ...props.sx,
        "&::before": {
          content: { xs: "none", md: "''" },
          position: "absolute",
          bottom: 0,
          left: 0,
          right: `calc(54% + ${pxToRem(300)})`,
          height: "100%",
          backgroundColor: "#EDECE8",
          borderRadius: pxToRem(40),
          zIndex: -1,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        {/* // paused or expired enrollement */}
        {(props.subscriptionStatus === "Expired" ||
          props.subscriptionStatus === "Paused") && (
          <>
            {props.subscriptionStatus === "Expired" &&
            !props?.isProlongAllowed ? (
              <></>
            ) : (
              <Box
                sx={{
                  display: tablet ? "none" : "flex",
                  alignItems: "center",
                  backgroundColor: "#FFEDED",
                  minWidth: pxToRem(260),
                  position: "absolute",
                  top: "-65px",
                  // right: "36px",
                  borderRadius: "10px",
                  gap: 0,
                  padding: "5px 10px",
                  border: "1px solid #FF4D4D",
                }}
                right={{ md: "16px", lg: "36px" }}
              >
                <Typography
                  sx={{
                    color: "#FF4D4D",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: 1.2,
                  }}
                >
                  {props.subscriptionStatus === "Expired"
                    ? "Your subscription has expired"
                    : "Your subscription is paused"}
                </Typography>

                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "14px",
                    fontWeight: 600,
                    cursor: "pointer",
                    "&:hover": {
                      color: "#026670",
                    },
                  }}
                  onClick={
                    props.subscriptionStatus === "Expired"
                      ? () =>
                          props?.showSubscriptionModal(
                            props?.course?.subscription_price,
                            "normal"
                          )
                      : () => handleResolve(props?.subscriptionId)
                  }
                >
                  {props.subscriptionStatus === "Expired"
                    ? "Resubscribe"
                    : "Resolve"}
                </Typography>
              </Box>
            )}
          </>
        )}
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ cursor: tablet ? "pointer" : "default" }}
          onClick={props.isCollapsable ? handleCourseOverviewClick : undefined}
        >
          <Box width={{ xs: "auto", md: "100%" }} mr={{ lg: 5, md: 2.5 }}>
            <Typography
              component="p"
              fontSize={pxToRem(20)}
              fontWeight="bold"
              lineHeight={pxToRem(30)}
              textAlign="right"
            >
              Course Overview
            </Typography>
            {!props?.isPresubscribed && (
              <>
                {props?.completedStatus ? (
                  <Typography
                    component="p"
                    fontSize="0.85rem"
                    fontWeight="700"
                    lineHeight={pxToRem(18)}
                    color="#026670"
                    textAlign={{ xs: "left", md: "right" }}
                  >
                    {props?.completedStatus}
                  </Typography>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Skeleton variant="text" width={80} height={18} />
                  </Box>
                )}
              </>
            )}
          </Box>
          {props.isCollapsable && (
            <Box
              sx={{
                display: { xs: "none", ssm: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
                paddingRight: pxToRem(13),
              }}
            >
              <img
                src={Chevron}
                style={{
                  transform: courseOverviewIsOpen
                    ? "rotate(-90deg)"
                    : "rotate(90deg)",
                  transition: "all 0.3s ease-in-out",
                  height: pxToRem(23.5),
                }}
                alt="Chevron"
              />
            </Box>
          )}
        </Stack>

        <Box
          ref={contentRef}
          maxHeight={
            props.isCollapsable
              ? {
                  ssm: courseOverviewIsOpen
                    ? `${contentRef.current?.scrollHeight}px`
                    : "0px",
                  md: "100%",
                }
              : "none"
          }
          overflow={{
            ssm: props.isCollapsable ? "hidden" : "visible",
            md: "visible",
          }}
          style={{ transition: "max-height 0.3s ease-in-out" }}
        >
          <Stack
            rowGap={pxToRem(10)}
            pt={pxToRem(30)}
            display={{ ssm: "grid", md: "flex" }}
            columnGap={pxToRem(48)}
            gridTemplateColumns={{ sm: "1fr 1fr" }}
          >
            {props.lessons && props.lessons.length > 0 ? (
              lessonsToDisplay.map((lesson, index) => {
                const isLocked =
                  index > 0 && !props.lessons[index - 1].can_watch_next_lesson;
                return (
                  <ListItem
                    key={lesson.id}
                    id={lesson.id}
                    name={lesson.name}
                    short_description={lesson.short_description}
                    duration={lesson.duration}
                    status={lesson.status}
                    hours_remaining={lesson.hours_remaining}
                    onClick={handleItemClick.bind(null, lesson)}
                    // onClick={() => handleItemClick(lesson)}

                    isTooltip={isLocked}
                    isSubscribed={props.isSubscribed}
                    subscriptionStatus={props.subscriptionStatus}
                    playingLessonId={props.playingLessonId}
                    playIcon={props.playIcon}
                    isFailed={props?.isFailed}
                    isCollapsable={props.isCollapsable}
                    lessonLockedAndSelected={props.lessonLockedAndSelected}
                    lastLessonId={lastLessonId}
                    isPresubscribed={props?.isPresubscribed}
                    openTooltipId={openTooltipId}
                    setOpenTooltipId={setOpenTooltipId}
                  />
                );
              })
            ) : (
              <Stack
                flexDirection="row"
                columnGap={{ xs: pxToRem(24), md: pxToRem(10) }}
                p={`0 0 ${pxToRem(30)} 0`}
                sx={{
                  position: "relative",
                  borderBottom: "1px solid rgba(191, 190, 187, 0.5)",
                  backgroundColor: "transparent",
                }}
                mr={{ md: "20px", lg: "40px" }}
              >
                <Typography marginLeft="auto">
                  <Skeleton
                    variant="text"
                    width={pxToRem(50)}
                    height={pxToRem(24)}
                    // marginLeft="auto"
                  />
                </Typography>

                <Box component="span" textAlign="right" dir="rtl">
                  <Skeleton
                    variant="text"
                    width={pxToRem(100)}
                    height={pxToRem(24)}
                  />

                  <Skeleton
                    variant="text"
                    width={pxToRem(160)}
                    height={pxToRem(16)}
                  />

                  <Skeleton
                    variant="text"
                    width={pxToRem(150)}
                    height={pxToRem(16)}
                  />
                </Box>

                <Box
                  position={{ md: "absolute" }}
                  right={pxToRem(-58)}
                  top={pxToRem(13)}
                  width={pxToRem(32)}
                  height={pxToRem(32)}
                  borderRadius="100%"
                  flexShrink={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginTop={{ xs: "10px", md: "0px" }}
                >
                  <Skeleton
                    variant="circular"
                    width={pxToRem(44)}
                    height={pxToRem(33)}
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Box>
        {tablet &&
          !props.isCollapsable &&
          props.lessons.length > lessonLimit && (
            <TextLink
              href=""
              onClick={handleReadMoreClick}
              showFullDescription={showFullDescription}
              sx={{
                "& span": { fontSize: pxToRem(16), marginRight: pxToRem(5) },
                width: "100%",
                mt: pxToRem(33),
              }}
            >
              {showFullDescription
                ? "View Less Lectures"
                : "View More Lectures"}
            </TextLink>
          )}
      </Box>
    </Box>
  );
  // };
});

const ListItem = (props) => {
  const [open, setOpen] = useState(false);

  const isOpen = props.openTooltipId === props.id;

  const handleOpen = () => {

    if (
      props.status === "locked" ||
      props.status === "unwatched" ||
      props.status === "unsubscribed" ||
      props.status === "anonymous" ||
      props.status === "expired" ||
      props.status === "paused" ||
      props?.subscriptionStatus === "Deleted" ||
      props?.subscriptionStatus === "Inactive" ||
      props?.subscriptionStatus === "Failed"
    ) {
      setOpen(true);

      props.setOpenTooltipId(props.id);
    }
  };

  const handleClose = () => {

    setOpen(false);
    if (isOpen) {
      props.setOpenTooltipId(null);
    }
  };

  const getStatusClass = () => {
    if (
      props.status === "playing" &&
      props.id === props.playingLessonId &&
      !props.lessonLockedAndSelected
    ) {
      return "active";
    }

    switch (props.status) {
      case "completed":
        return "completed";
      case "locked":
        return "locked";
      case "unwatched":
        return "unwatched";
      case "available":
        return "available";
      default:
        return "";
    }
  };

  const getTooltipTitle = () => {
    if (props.status === "locked") {
      const { hours, minutes } = props.hours_remaining;
      let timeString = "";

      if (hours > 0) {
        timeString += `${hours} hour${hours === 1 ? "" : "s"}`;
      }

      if (minutes > 0) {
        if (hours > 0) {
          timeString += " and ";
        }
        timeString += `${minutes} minute${minutes === 1 ? "" : "s"}`;
      }

      return `The lecture will be available in ${timeString}`;
    } else if (props.status === "unwatched") {
      return "Complete previous lectures to unlock";
    } else if (props.subscriptionStatus === "Unsubscribed") {
      return "Subscribe to access this lecture";
    } else if (props.status === "anonymous") {
      return "Log in to access this lecture";
    } else if (props.status === "paused" || props.status === "expired") {
      return "Your enrollment is not active";
    } else if (props?.subscriptionStatus === "Failed") {
      return "Your payment method has failed";
    } else if (props?.subscriptionStatus === "Deleted") {
      return "Subscribe to access this lecture";
    } else if (props?.subscriptionStatus === "Inactive") {
      return "Subscribe to access this lecture";
    } else {
      return "";
    }
  };

  return (
    <Tooltip
      title={getTooltipTitle()}
      placement="top"
      open={open}
      // onClose={handleClose}
      onOpen={handleOpen}
      enterDelay={0}
      enterTouchDelay={0}
      leaveTouchDelay={0}
      PopperProps={{ disablePortal: true }}
      componentsProps={{
        tooltip: {
          sx: {
            border: "1px solid #EDECE8",
          },
        },
      }}
    >
      <Stack
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onClick={(e) => {
          handleOpen();

          if (
            props.status !== "locked" &&
            props.status !== "unwatched" &&
            props.status !== "unsubscribed" &&
            props.status !== "anonymous" &&
            props.subscriptionStatus !== "Deleted" &&
            props.subscriptionStatus !== "Inactive"
          ) {
            props.onClick();
          }
        }}
        className={getStatusClass()}
        component="button"
        flexDirection="row"
        columnGap={{ xs: pxToRem(24), md: pxToRem(10) }}
        position="relative"
        left={{ xs: "0", md: "-40px" }}
        width={{ xs: "100%", md: "295px" }}
        sx={{
          border: "none",
          backgroundColor:
            props?.id === props?.playingLessonId ? "#026670" : "transparent",

          padding: "10px",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",

          outline: "none",

          "-webkit-tap-highlight-color": "transparent",

          "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
            mb: `3px !important`,
          },
          "&:hover .status-ic": {
            display: "flex",
          },
          "&.active, &.completed:hover": {
            borderColor: "#333",
            cursor: "pointer",
            "& span": {
              color: "#333",
            },
          },
          "&.locked svg:last-of-type": {
            display: "block",
          },
          "&.unwatched, &.unsubscribed, &.anonymous, &.expired, &.paused": {
            cursor: "not-allowed",
          },
          "&:hover": {
            "& .sb_short_description": {
              color: props.id !== props.playingLessonId ? "#333" : "#fff",
              // color: "#fff",
            },
            "& .sb_name": {
              color: props.id !== props.playingLessonId ? "#333" : "#fff",
              // color: "#fff",
            },
            "& .sb_duration": {
              color: props.id !== props.playingLessonId ? "#333" : "#fff",
              // color: "#fff",
            },

            cursor: "pointer",
          },
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height="100%"
          width="100%"
          gap={2.5}
        >
          <Box
            component="span"
            textAlign="left"
            cursor="pointer"
            width="100%"
            sx={{
              borderBottom: props?.isPresubscribed
                ? "1px solid rgba(191, 190, 187, 0.5)"
                : "none",
              paddingBottom: props?.isPresubscribed ? "10px" : "0px",
            }}
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              marginBottom={pxToRem(6)}
              gap={pxToRem(15)}
            >
              <Typography
                // component="span"
                fontSize={pxToRem(16)}
                fontWeight={600}
                color={props.id === props.playingLessonId ? "#fff" : "primary"}
                marginLeft="auto"
                className="sb_duration"
              >
                {props.duration}
              </Typography>

              <Typography
                // component="span"
                display="block"
                fontSize={pxToRem(16)}
                fontWeight={600}
                color={
                  props.id === props.playingLessonId ? "#fff" : "secondary"
                }
                sx={{
                  cursor: "pointer",
                  width: "80%",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  textAlign: "right",
                }}
                className={`sb_name ${getLanguageClass(props?.name)}`}
              >
                {props.name}
              </Typography>
            </Stack>
            <Typography
              fontSize={pxToRem(14)}
              fontWeight={500}
              lineHeight={pxToRem(18)}
              sx={{
                cursor: "pointer",
                wordBreak: "break-word",
                textAlign: "right",
              }}
              color={props.id === props.playingLessonId ? "#fff" : "#BFBEBB"}
              className={`sb_short_description  ${getLanguageClass(
                props?.short_description
              )}`}
            >
              {props.short_description}
            </Typography>
          </Box>

          <Box>
            <Box
              className="status-ic"
              width={pxToRem(32)}
              height={pxToRem(32)}
              borderRadius="100%"
              bgcolor={
                props.id === props.playingLessonId &&
                !props.lessonLockedAndSelected
                  ? props.playIcon
                    ? "#FCE181"
                    : "#fff"
                  : "#fff"
              }
              flexShrink={0}
              sx={{
                display: "flex",
                cursor: "pointer",
              }}
              alignItems="center"
              justifyContent="center"
            >
              {props.id === props.playingLessonId &&
              !props.lessonLockedAndSelected ? (
                <Arrow color="#026670" style={{ marginLeft: pxToRem(3) }} />
              ) : props.status === "unwatched" ||
                props.status === "locked" ||
                props.status === "unsubscribed" ||
                props.status === "expired" ||
                props.status === "anonymous" ||
                props.status === "paused" ||
                props?.isFailed ||
                props?.subscriptionStatus === "Deleted" ||
                props?.subscriptionStatus === "Inactive" ? (
                <Lock />
              ) : props.status === "completed" ? (
                <Check color="#026670" />
              ) : props.status === "available" ? (
                <Check color="#EDECE8" />
              ) : null}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Tooltip>
  );
};

export default Sidebar;
