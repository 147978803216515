import { Link } from "react-router-dom";
import {
  Link as MuiLink,
  Tooltip,
  tooltipClasses,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import { useRef, useEffect, useState } from "react";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#026670",
    padding: pxToRem(10),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#026670",
  },
});

const MenuDropdownLink = (props) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef(null);

  const theme = useTheme();
  const mobile__ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const getTooltipPlacement = () => {
    if (mobile__ssm) return "bottom";
    return "right";
  };

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflow(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const linkContent = (
    <span ref={textRef} className={props?.noOverflow?"":"overflow-ellipsis"}>
      {props.children}
    </span>
  );

  return isOverflow ? (
    <CustomTooltip title={props.children} placement={getTooltipPlacement()}>
      <MuiLink
        {...props}
        onClick={() => {
          if (props.newTab && props.to) {
            window.open(props.to, "_blank"); // Open in a new tab
          }
        }}
        underline="none"
        component={Link}
        sx={{
          fontSize: pxToRem(15),
          fontWeight: 600,
          color: "#333",
          "&:hover": { color: "secondary.main" },
       
        }}

      >
        {linkContent}
      </MuiLink>
    </CustomTooltip>
  ) : (
    <MuiLink
      {...props}
      onClick={() => {
        if (props.newTab && props.to) {
          window.open(props.to, "_blank"); // Open in a new tab
        }
      }}
      underline="none"
      component={Link}
      sx={{
        fontSize: pxToRem(15),
        fontWeight: 600,
        color: "#333",
        "&:hover": { color: "secondary.main" },
      }}
    >
      {linkContent}
    </MuiLink>
  );
};

export default MenuDropdownLink;
