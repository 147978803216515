import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "../IconBtnCircular";
import { ReactComponent as Bookmark } from "../../images/bookmark.svg";
import { ReactComponent as Share } from "../../images/share.svg";
import { ReactComponent as Volume } from "../../images/volume.svg";
import { ReactComponent as CamcorderIcon } from "../../images/Camcorder_Icon.svg";

const TopPanel = ({
  selectedLesson,
  currentTime,
  setToastOpen,
  handleListenClicked,
  isListening,
  setIsListening,
  handleWatchClicked,
  setBookmarkToastOpen,
  setBookmarkMessage,
  setBookmarkListChanged,
  setNoteListChanged,
  setNoteToastOpen,
  setListenWatchToggle,
  setReservedTime,
  fullscreen,
  useParshaPlayer
}) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();

  // switch between listening and watching
  const handleToggleListening = () => {
    setListenWatchToggle(true);
    setReservedTime(currentTime?.current);
    console.log("Reserved time: ", currentTime);
    if (!isListening) {
      handleListenClicked();

    } else {
      handleWatchClicked();

    }
  };

  // share the current page for nonw
  const handleShareClick = () => {
    const fullUrl = `${window.location.origin}${location.pathname}`;
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        setToastOpen(true);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };
  // add bookmark

  const handleAddBookmark = async () => {
    console.log("Adding bookmark");
  };

  const handleRemoveBookmark = async (bookmarkId) => {
   console.log("Removing bookmark:", bookmarkId);
  };

  return (
    <>
      <Stack
        flexDirection="row"
        gap="10px"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
          top:fullscreen? pxToRem(20): { xs: pxToRem(-20), ssm: pxToRem(-24) },
        }}
      >
        <Tooltip
          title="Switch to Audio"
          enterDelay={0}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              textWrap: "nowrap",
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
            },
          }}
          placement="top"
          sx={{
            "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
              whiteSpace: "nowrap",
              backgroundColor: "#fce181",
              color: "#026670",
            },
          }}
        >
          <Box>
            <IconBtnCircular
              sx={{
                width: { xs: useParshaPlayer ? pxToRem(48) : pxToRem(40) , ssm: pxToRem(48) },
                height: { xs: useParshaPlayer ? pxToRem(48) : pxToRem(40), ssm: pxToRem(48) },
              }}
              className="yellow-ic"
            >
              <Box
                sx={{
                  flexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleToggleListening}
              >
                {isListening ? <CamcorderIcon /> : <Volume color="#026670" />}
              </Box>
            </IconBtnCircular>
          </Box>
        </Tooltip>
        <Tooltip
          title="Share Now"
          enterDelay={0}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              textWrap: "nowrap",
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
            },
          }}
          placement="top"
          sx={{
            "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
              whiteSpace: "nowrap",
            },
          }}

        
        >
          <Box
            onClick={handleShareClick}
          >
            <IconBtnCircular
              className="hover-green"
              sx={{
                width: pxToRem(48),
                height: pxToRem(48),
                borderRadius: "50%",
                padding: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              <Share color="#026670" style={{ marginRight: pxToRem(1.5) }} />
            </IconBtnCircular>
          </Box>
        </Tooltip>
        <Tooltip
          title="Add Bookmark"
          enterDelay={0}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              whiteSpace: "nowrap",
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
            },
          }}
          placement="top"
        >
          <Box>
            <IconBtnCircular
              sx={{
                width: pxToRem(48),
                height: pxToRem(48),
                borderRadius: "50%",
                padding: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
              className="hover-green"
            >
              <Box sx={{ flexShrink: 0, display: "flex" }}>
                <Bookmark color="#026670" />
              </Box>
            </IconBtnCircular>
          </Box>
        </Tooltip>
      </Stack>
    </>
  );
};

export default TopPanel;
