import React from "react";
import { Grid, Box, Stack, Typography } from "@mui/material";
import BlockBordered from "../../../components/BlockBordered";
import TextLink from "../../../components/TextLink";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/system";
import { getLanguageClass } from "../../../utils/languageClass";
const Label = styled(Typography)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "20px",
    fontSize: "12px",
    fontWeight: 600,
    color: "#026670",
    backgroundColor: "#FFFFFF",
    // border: "2px solid #f5e093",
    borderRadius: "calc(infinity * 1px)",
    padding: "0 15px",
  };
});
export default function FrontPost({ post }) {
  return (
    <Grid item xs={12} lg={7} mt={{ xs: "25px", sm: "30px", md: pxToRem(40) }}>
      <Box
        position="relative"
        width="100%"
        display="flex"
        borderRadius={pxToRem(20)}
        boxShadow={{
          xs: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)} rgba(0,0,0,0.2)`,
          lg: `${pxToRem(30)} ${pxToRem(30)} ${pxToRem(50)} rgba(0,0,0,0.2)`,
        }}
        flexShrink={0}
        sx={{
          "&::before": {
            content: "''",
            position: "absolute",
            left: { xs: "-20px", lg: pxToRem(-40) },
            top: { xs: "-20px", lg: pxToRem(-40) },
            width: "76.38%",
            height: "93.35%",
            bgcolor: "#FCE181",
            borderRadius: pxToRem(20),
            zIndex: -1,
          },
        }}
      >
        <img
          src={post?.image ? post?.image : post?.thumbnail}
          alt="Presentation"
          style={{
            width: "100%",
            height: "400px",
            objectFit: "cover",
            borderRadius: pxToRem(20),
          }}
        />
      </Box>
      <Stack
        flexDirection={{ xs: "column", ssm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", ssm: "center" }}
        mt={pxToRem(40)}
        gap={pxToRem(17)}
      >
        <Typography
          component="p"
          fontSize={pxToRem(16)}
          lineHeight={pxToRem(25)}
          fontWeight={600}
          color="#BFBEBB"
          order={{ xs: 2, ssm: 1 }}
        >
          {post?.author
            ? `${post.author.first_name} ${post.author.last_name}`
            : "Unknown Author"}{" "}
          •{" "}
          {post?.published_at
            ? new Date(post.published_at).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : "N/A"}
        </Typography>
        <Label
          order={{ xs: 1, ssm: 2 }}
          sx={{
            height: "24px",
            border: "2px solid #f5e093",
            backgroundColor: "#F9EBB9",
            fontSize: pxToRem(14),
          }}
        >
          Update
        </Label>
      </Stack>
      <Typography
        className="slideTitle"
        fontSize={pxToRem(25)}
        fontWeight={700}
        lineHeight={pxToRem(35)}
        color="secondary.main"
        my={{ xs: pxToRem(15), ssm: pxToRem(18) }}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "4",
          WebkitBoxOrient: "vertical",
        }}
      >
        {post.title}
      </Typography>
      <BlockBordered>
        <Box
          className={`slideTitle ${getLanguageClass(post?.content)}`}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "4",
            WebkitBoxOrient: "vertical",
            fontSize: pxToRem(18),
            fontWeight: 500,
            lineHeight: pxToRem(30),
          }}
          dangerouslySetInnerHTML={{
            __html: post.content,
          }}
        />
      </BlockBordered>
      <TextLink
        href={`/blog/${post.id}`}
        sx={{
          "& span": { fontSize: pxToRem(16) },
          marginTop: pxToRem(35),
        }}
      >
        Read More
      </TextLink>
    </Grid>
  );
}
