import React, { forwardRef, useEffect, useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Dialog,
  Stack,
  Typography,
  Box,
  Button,
  TextField,
  Avatar,
  Rating,
  Slide,
  DialogContent,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "../IconBtnCircular";
import Switcher from "../Switcher";
import { ReactComponent as Close } from "../../images/close.svg";
import { ReactComponent as AnonymousAvatar } from "../../images/userAnonymous.svg";
import { postCourseRatings, updateRating } from "../../api/course";
import { getStudent } from "../../api/student";
import Toast from "../Toast";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReviewModal = ({
  open,
  setOpen,
  course,
  isEditing = false,
  currentReview = {},
  updateRatings,
  ...props
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [student, setStudent] = useState({
    avatar: "",
    user: "",
    nickname: "",
  });
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (isEditing) {
      setReview(currentReview?.description || "");
      setRating(currentReview?.rating || 0);
      setIsAnonymous(currentReview?.is_annonymous || false);
    }
  }, [isEditing, currentReview]);

  const fetchStudent = () => {
    getStudent()
      .then((res) => {
        const formatName = (name) =>
          name ? `${name.charAt(0).toUpperCase()}${name.slice(1)}` : "";
        setStudent({
          avatar: res.data.avatar,
          user: `${formatName(res.data.user.first_name)} ${formatName(
            res.data.user.last_name
          )}`,
          nickname: formatName(res.data.nickname),
        });
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    fetchStudent();
  }, []);
  const handleSave = () => {
    setIsSaving(true);
    if (isEditing) {
      updateRating(currentReview.id, {
        rating,
        description: review.trim(),
        is_annonymous: isAnonymous,
      })
        .then((res) => {
          setIsSaving(false);
          setOpen(false);
          setToastOpen(true);
          setToastMessage("Rating updated successfully!");
          updateRatings();
        })
        .catch((err) => {
          setIsSaving(false);
          setToastOpen(true);
          setToastMessage("Failed to update rating.");
        });
      return;
    }

    const payload = { rating, description: review, is_annonymous: isAnonymous };
    postCourseRatings(course.id, payload)
      .then((res) => {
        setIsSaving(false);
        setOpen(false);
        setToastOpen(true);
        setToastMessage("Rating saved successfully!");
        updateRatings();
      })
      .catch((err) => {
        setIsSaving(false);
        setToastOpen(true);
        setToastMessage("Failed to save rating.");
      });
  };

  const handleClose = () => {
    setOpen(false);
    setReview("");
    setRating(0);
  };

  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <>
      <Dialog
        {...props}
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        maxWidth="ssm"
        keepMounted
        scroll={mobile_ssm ? "paper": "body"}
        TransitionComponent={mobile_ssm ? Transition : undefined}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialog-container": {
          alignItems: {xs: "flex-end", ssm: "center"},
        },
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: {
              xs: "100%",
              ssm: "calc(100% - 64px) !important",
              md: `${pxToRem(920)} !important`,
            },
            borderRadius: {xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`, ssm: pxToRem(20)},
            height: "auto",
            margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
            backgroundColor: "#F7F6F2",
            maxHeight: {xs: "85vh", ssm: "none"},
          },
          zIndex: 1500,
        }}
      >
        {/* Header Section */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          bgcolor="#EDECE8"
          p={{
            xs: `${pxToRem(18)}`,
            ssm: `${pxToRem(25)}`,
            md: `${pxToRem(40)} ${pxToRem(60)}`,
          }}
        >
          <Typography
            fontSize={{ xs: pxToRem(23), ssm: pxToRem(35) }}
            fontWeight={700}
          >
            Rate & Review
          </Typography>
          <IconBtnCircular
            onClick={() => setOpen(false)}
            sx={{
              width: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
              height: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
            }}
          >
            <Close />
          </IconBtnCircular>
        </Stack>

        {/* Body Section */}
        <DialogContent sx={{p: 0}}>
          <Box
            bgcolor="#F7F6F2"
            p={{
              xs: `${pxToRem(18)}`,
              ssm: `${pxToRem(25)}`,
              md: `${pxToRem(40)} ${pxToRem(60)} ${pxToRem(50)}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                mb: "15px",
              }}
            >
              {isAnonymous ? (
                <AnonymousAvatar width={pxToRem(70)} height={pxToRem(70)} />
              ) : student.avatar ? (
                <Avatar sx={{ width: pxToRem(70), height: pxToRem(70) }}>
                  <img src={student?.avatar} alt="" />
                </Avatar>
              ) : (
                <AnonymousAvatar width={pxToRem(70)} height={pxToRem(70)} />
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>
                  Posting publicly as
                </Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                  {isAnonymous ? "User" : student.user}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Rating
                value={rating}
                onChange={(e, newValue) => setRating(newValue)}
                sx={{
                  fontSize: "50px",
                  mb: "25px",
                  "& .MuiRating-iconFilled": { color: "#fde181" },
                  "& .MuiRating-iconHover": { color: "#fde181" },
                }}
              />
            </Box>

            <Box mb={pxToRem(40)}>
              <Box
                bgcolor="#EDECE8"
                borderRadius={pxToRem(10)}
                p={{ xs: pxToRem(10), ssm: `${pxToRem(22)} ${pxToRem(26)}` }}
                mb={pxToRem(40)}
              >
                <TextField
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                  placeholder="Write your review..."
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  sx={{
                    fontSize: "16px",
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "transparent",
                      borderRadius: pxToRem(10),
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack
                  flexDirection="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent={{ xs: "center", ssm: "flex-end" }}
                  gap={pxToRem(30)}
                  mr={{ ssm: pxToRem(30) }}
                >
                  <Switcher
                    name="anonymous"
                    label="Post Anonymously"
                    checked={isAnonymous}
                    onChange={(e) => setIsAnonymous(e.target.checked)}
                  />

                  <Stack flexDirection="row" gap={pxToRem(10)} flexWrap={{xs:"wrap", sssm: "nowrap"}}>
                    <Button
                      sx={{ width: { xs: "100%", ssm: "auto" } }}
                      variant="outlined"
                      size="small"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{ width: { xs: "100%", ssm: "auto" } }}
                      variant="yellow"
                      size="small"
                      onClick={handleSave}
                    >
                      {isEditing ? "Edit Rating & Review" : "Rate & Review"}
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
      />
    </>
  );
};

export default ReviewModal;
