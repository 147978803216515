import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Divider,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import TreeImg from "../../images/footer-tree.svg";
import PhilosophyImg from "../../images/philosophy-img.png";
import PhilosophyImg2 from "../../images/PhilosophyImg2.png";
import CustomerService from "../../images/customer-service.svg";
import GradientAnimation from "../../components/GradientAnimation";
import Help from "../../images/help.svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { getLanguageClass } from "../../utils/languageClass";
import { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
import { getBookings } from "../../api/cmsApi";

const aboutUsCardsDetails = [
  {
    icon: Help,
    title: "Have a Questions?",
    description:
      "Find answers to common questions in our FAQ section. Explore topics on account management, website navigation, technical…",
    btnName: "FAQ",
  },
  {
    icon: CustomerService,
    title: "Technical Support",
    description:
      "For  <span style='font-weight: 700;'> website or account support, </span> contact our technical support team for quick and efficient assistance with any issues you encounter.",
    btnName: "Contact Support",
  },
];

const Booking = () => {
  useEffect(() => {
    document.title = "Booking - Talk to the Rabbi!";
  }, []);

  const [bookings, setBookings] = useState({
    title: "",
    body: "",
    bookings_image: null,
    duration: null,
    price: null,
    location: "",
  });

  const fetchBookings = () => {
    getBookings()
      .then((res) => {
        setBookings(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  const theme = useTheme();
  const sm_down = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box position="relative" overflow="hidden">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Box
        position="relative"
        pt={pxToRem(30)}
        pb={pxToRem(40)}
        minHeight={pxToRem(540)}
        sx={{
          display: "flex",
          flexDirection: "column",
          // background: "linear-gradient(45deg, #026670, #fce181, #026670, #fce181)",
          // backgroundSize: "600% 600%",
          // animation: `${gradientAnimation} 30s ease infinite`,
          borderRadius: { xs: 0, md: pxToRem(40) },
        }}
      >
        <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflowX: "clip",
          "&::before": {
            content: "''",
            position: "absolute",
            bottom: pxToRem(30),
            left: "50%",
            transform: "translateX(-125%)",
            background: `url(${TreeImg})`,
            zIndex: 1,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            width: pxToRem(520),
            height: pxToRem(702),
            opacity: 0.4,
            "@media(max-width: 1300px)": {
              left: "30px",
              transform: "none",
            },
            "@media(max-width: 899px)": {
              left: "50%",
              transform: "translateX(-50%)",
            },
          },
        }}
      ></Box>
        <GradientAnimation />
        <Header menuVisible />

        <Box
          className="header-padding"
          sx={{
            flexGrow: 1,
            zIndex: 2,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {bookings.title && (
            <Box
              maxWidth={pxToRem(600)}
              sx={{
                fontSize: { xs: pxToRem(15), sm: pxToRem(22) },
                //fontWeight: "500",
                marginBottom: { xs: "12px", sm: "16px" },
                textAlign: "center",
              }}
              dangerouslySetInnerHTML={{ __html: bookings.title }}
              className={getLanguageClass(bookings?.title)}
            />
          )}
          {/*  <Typography
            component="h1"
            fontFamily="PloniBold"
            fontWeight="700"
            textAlign="center"
            px={pxToRem(30)}
            fontSize={{ xs: pxToRem(40), sm: pxToRem(70) }}
            lineHeight={{ xs: "40px", sm: pxToRem(60) }}
            mb="20px"  
          >
            Talk to the Rabbi!
          </Typography>
          <Typography
            component="h2"
            sx={{
              fontSize: pxToRem(25),
              letterSpacing: "-0.5px",
              lineHeight: pxToRem(32),
              fontWeight: 700,
              opacity: "75%",
              px: pxToRem(20),
              textAlign: "center",
              display: { xs: "none", ssm: "block" },
            }}
          >
            50-minute Session with Reb Yakov Moshe
          </Typography> */}
        </Box>
        <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
      </Box>
      <Container
        sx={{
          px: pxToRem(30),
          py: { xs: pxToRem(70), ssm: pxToRem(90), md: pxToRem(120) },
        }}
        disableGutters
      >
        <Grid container spacing={{ md: pxToRem(50), lg: pxToRem(80) }}>
          <Grid item xs={12} md={6}>
            <Box
              display={{ xs: "flex", ssm: "block" }}
              flexDirection={{ xs: "column" }}
              sx={{ alignItems: "center" }}
              gap={{ xs: pxToRem(60) }}
            >
              <Box
                position="relative"
                order={{ xs: 2, ssm: 1 }}
                maxWidth={{
                  xs: "250px",
                  sm: pxToRem(250),
                  md: "100%",
                }}
                width="100%"
                height={{ xs: "auto", md: pxToRem(420) }}
                display="flex"
                borderRadius={pxToRem(20)}
                flexShrink={0}
                sx={{
                  marginLeft: {
                    xs: "auto",
                    ssm: pxToRem(25),
                    sm: pxToRem(36),
                    md: 0,
                  },
                  marginBottom: {
                    xs: pxToRem(20),
                    ssm: pxToRem(48),
                    md: pxToRem(50),
                  },
                  marginRight: { xs: "auto", ssm: pxToRem(24), lg: 0 },
                  float: { ssm: "right", md: "none" },
                  "&::before": {
                    content: "''",
                    position: "absolute",
                    right: { xs: pxToRem(-20), md: "auto" },
                    left: { xs: "auto", md: pxToRem(-40) },
                    bottom: { xs: pxToRem(-20), md: pxToRem(-40) },
                    width: "80%",
                    height: "95%",
                    maxWidth: "100%",
                    bgcolor: "#FCE181",
                    borderRadius: pxToRem(20),
                    zIndex: -1,
                  },
                }}
              >
                <picture>
                  <source media="(min-width: 900px)" srcSet={PhilosophyImg2} />
                  <img
                    src={PhilosophyImg}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "top",
                      borderRadius: pxToRem(20),
                      transform: tablet ? "scale(-1, 1)" : "",
                    }}
                  />
                </picture>
              </Box>
              {/*  <Typography
                order={{ xs: 1, ssm: 2 }}
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: pxToRem(16), ssm: pxToRem(18) },
                  lineHeight: { xs: pxToRem(26), ssm: pxToRem(30) },
                }}
                mt={{ md: pxToRem(100) }}
              >
                Book a personalized appointment with Reb Yakov Moshe, a trusted
                life coach dedicated to helping individuals navigate life's
                challenges. With a special focus on marriage counseling, Reb
                Yakov Moshe offers compassionate guidance and practical
                strategies to strengthen relationships and resolve conflicts.
                Whether you're seeking clarity, support, or direction in any
                area of life, these 50-minute sessions provide a safe and
                uplifting environment to explore your concerns and work toward
                meaningful solutions. Secure your spot today and take the next
                step toward a brighter, more fulfilling future.
                <br style={{ display: tablet ? "none" : "" }} />
                <br style={{ display: tablet ? "none" : "" }} />
                Book a personalized appointment with Reb Yakov Moshe, a trusted
                life coach dedicated to helping individuals navigate life's
                challenges. With a special focus on marriage counseling, Reb
                Yakov Moshe offers compassionate guidance and practical
                strategies to strengthen relationships and resolve conflicts.
                Whether you're seeking clarity, support, or direction in any
                area of life.
              </Typography> */}
              {bookings.body && (
                <Box
                  maxWidth={pxToRem(600)}
                  sx={{
                    fontSize: { xs: pxToRem(15), sm: pxToRem(22) },
                    fontWeight: "500",
                    marginBottom: { xs: "12px", sm: "16px" },
                    mt: { md: pxToRem(100) },
                  }}
                  dangerouslySetInnerHTML={{ __html: bookings.body }}
                  className={getLanguageClass(bookings?.body)}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Divider sx={{ my: pxToRem(55), display: { md: "none" } }} />
            <Typography
              fontSize={{ xs: pxToRem(18), ssm: pxToRem(25) }}
              lineHeight={pxToRem(35)}
              fontWeight={700}
              marginBottom={{ xs: pxToRem(40), ssm: pxToRem(45) }}
            >
              Select your preferred date & time from the available slots to
              secure your appointment.
            </Typography>
            <Box
              sx={{
                px: pxToRem(32),
                py: pxToRem(40),
                borderRadius: pxToRem(20),
                backgroundColor: "#FFFFFF",
                marginBottom: { xs: pxToRem(40), ssm: pxToRem(60) },
              }}
            >
              <Typography
                mb={pxToRem(22)}
                fontSize={pxToRem(30)}
                fontWeight={700}
                lineHeight={pxToRem(32)}
              >
                Session Details
              </Typography>
              <Stack
                flexDirection={{ ssm: "row", md: "column", lg: "row" }}
                alignItems="flex-start"
                gap={{
                  xs: pxToRem(11),
                  ssm: pxToRem(40),
                  md: pxToRem(20),
                  lg: pxToRem(40),
                }}
                justifyContent={{ md: "space-between", lg: "flex-start" }}
              >
                <Stack
                  flexDirection="row"
                  alignItems="flex-start"
                  gap={pxToRem(15)}
                >
                  <AccessTimeIcon sx={{ color: "#026670" }} />
                  <Typography
                    fontSize={{ xs: pxToRem(16), ssm: pxToRem(18) }}
                    fontWeight="600"
                  >
                    <Typography
                      fontSize={{ xs: pxToRem(16), ssm: pxToRem(18) }}
                      component="span"
                      sx={{ fontWeight: 700, display: { ssm: "block" } }}
                    >
                      Duration:
                    </Typography>{" "}
                    {bookings.duration} minutes
                  </Typography>
                </Stack>
                <Stack
                  flexDirection="row"
                  alignItems="flex-start"
                  gap={pxToRem(15)}
                >
                  <MonetizationOnOutlinedIcon sx={{ color: "#026670" }} />
                  <Typography
                    fontSize={{ xs: pxToRem(16), ssm: pxToRem(18) }}
                    fontWeight="600"
                  >
                    <Typography
                      fontSize={{ xs: pxToRem(16), ssm: pxToRem(18) }}
                      component="span"
                      sx={{ fontWeight: 700, display: { ssm: "block" } }}
                    >
                      Price:
                    </Typography>{" "}
                    ${bookings.price}
                  </Typography>
                </Stack>
                <Stack
                  flexDirection="row"
                  alignItems="flex-start"
                  gap={pxToRem(15)}
                >
                  <FmdGoodOutlinedIcon sx={{ color: "#026670" }} />
                  <Typography
                    fontSize={{ xs: pxToRem(16), ssm: pxToRem(18) }}
                    fontWeight="600"
                  >
                    <Typography
                      fontSize={{ xs: pxToRem(16), ssm: pxToRem(18) }}
                      component="span"
                      sx={{ fontWeight: 700, display: { ssm: "block" } }}
                    >
                      Location:
                    </Typography>{" "}
                    {bookings.location}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            <InlineWidget
              url="https://calendly.com/rebyakovmoshe/book?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=026670"
              styles={{
                minWidth: pxToRem(290),
                height: sm_down
                  ? pxToRem(670)
                  : tablet
                  ? pxToRem(775)
                  : pxToRem(670),
                borderRadius: pxToRem(20),
                overflow: "hidden",
              }}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{ my: { xs: pxToRem(58), ssm: pxToRem(72), md: pxToRem(60) } }}
        />
        <Grid container spacing={{ xs: 2.5, lg: pxToRem(20) }}>
          {aboutUsCardsDetails.map((cardDetails, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <Stack
                sx={{
                  backgroundColor: "#EDECE8",
                  borderRadius: pxToRem(20),
                  flexDirection: { md: "row" },
                  gap: pxToRem(23),
                  padding: {
                    xs: `${pxToRem(20)} ${pxToRem(30)}`,
                    ssm: pxToRem(40),
                  },
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: pxToRem(64),
                    borderRadius: cardDetails.rounded ? pxToRem(100) : "none",
                    overflow: "hidden",
                    flexShrink: "0",
                  }}
                >
                  <img src={cardDetails.icon} alt={cardDetails.title} />
                </Box>
                <Stack sx={{ justifyContent: "space-between" }}>
                  <Box>
                    <Typography
                      variant="blockTitle"
                      component="h3"
                      fontSize={pxToRem(25)}
                      pb={pxToRem(15)}
                    >
                      {cardDetails.title}
                    </Typography>
                    <Typography
                      variant="medium"
                      component="p"
                      fontSize={{ xs: pxToRem(16), md: pxToRem(18) }}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: cardDetails.description,
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        mt: {
                          xs: pxToRem(20),
                          ssm: pxToRem(25),
                          minWidth: pxToRem(175),
                        },
                      }}
                    >
                      {cardDetails.btnName}
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
export default Booking;
