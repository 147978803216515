import React from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px"; // Import the pxToRem function

export default function TopPost({ post }) {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={12}
      sx={{ cursor: "pointer" }}
      onClick={() => (window.location.href = `/blog/${post.id}`)}
    >
      <Stack
        flexDirection="row"
        sx={{
          gap: pxToRem(15),
          borderRadius: pxToRem(20),
          backgroundColor: "#EDECE8",
          overflow: "hidden",
          cursor: "pointer",
          height: "100px",
        }}
      >
        <Box width={pxToRem(100)} sx={{ flexShrink: "0" }}>
          <img
            src={post?.image ? post?.image : post?.thumbnail} // Fallback image
            alt="Post"
            style={{
              width: "200px",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Box
          sx={{
            padding: `${pxToRem(14)} ${pxToRem(36)} ${pxToRem(14)} ${pxToRem(
              15
            )}`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: pxToRem(16),
              lineHeight: pxToRem(20),
              color: "#333333",
              fontWeight: "600",
              marginBottom: pxToRem(10),
            }}
          >
            {post?.title} {/* Fallback title */}
          </Typography>
          <Typography
            component="p"
            fontSize={pxToRem(14)}
            fontWeight={600}
            color="#BFBEBB"
          >
            {post?.author
              ? `${post.author.first_name} ${post.author.last_name}`
              : "Unknown Author"}{" "}
            •{" "}
            {post?.published_at
              ? new Date(post.published_at).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : "N/A"}
          </Typography>
        </Box>
      </Stack>
    </Grid>
  );
}
