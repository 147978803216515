import "react-international-phone/style.css";

import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/material/styles";
// const TextFieldStyled = styled(TextField)({
//   width: "100%",
//   "& .MuiInputBase-root": {
//     borderRadius: pxToRem(30),
//     backgroundColor: "#EDECE8",
//     height: pxToRem(54),
//   },
//   "& input": {
//     borderRadius: `${pxToRem(30)} !important`,
//     padding: `${pxToRem(15)} ${pxToRem(40)} ${pxToRem(15)} ${pxToRem(18)}`,
//     "&:focus": {
//       backgroundColor: "#fff",
//     },
//   },
// });

const TextFieldStyled = styled(TextField)(({ userSettings }) => ({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: pxToRem(30),
    backgroundColor: "#EDECE8",
    height: pxToRem(54),
  },
  "& input": {
    borderRadius: `${pxToRem(30)} !important`,
    padding: `${pxToRem(15)} ${pxToRem(40)} ${pxToRem(15)} ${pxToRem(18)}`,
    fontWeight: userSettings ? 600 : 400,
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
}));

const FormInputPhone = ({
  userSettings,
  value,
  onChange,
  disabled,
  error,
  ...restProps
}) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "us",
      value,
      countries: defaultCountries,
      onChange: onChange,
    });

  return (
    <TextFieldStyled
      error={error}
      variant="outlined"
      color="primary"
      placeholder="Phone number"
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      disabled={disabled}
      inputRef={inputRef}
      autoComplete="off"
      userSettings={userSettings}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: "2px", marginLeft: "-8px" }}
          >
            <Select
              disabled={disabled}
              MenuProps={{
                style: {
                  height: "300px",
                  width: "360px",
                  top: "10px",
                  left: "-34px",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              sx={{
                width: "max-content",

                fieldset: {
                  display: "none",
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: "block",
                  },
                },

                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(value) => (
                <FlagImage
                  iso2={value}
                  style={{ display: "flex", marginLeft: "22px" }}
                />
              )}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage
                      iso2={country.iso2}
                      style={{ marginRight: "8px" }}
                    />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};
export default FormInputPhone;
