import { forwardRef, useState } from "react";
import { Button, Dialog, DialogContent, Slide, Typography, useMediaQuery, useTheme } from "@mui/material";
import ModalHeader from "../../components/ModalHeader";
import { pxToRem } from "px2rem2px";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoginSubscribeModal = (props) => {
  const { openLoginModal, hideModal, loginMessage ,onRedirectOpenModal } = props;
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    hideModal();
  };

  console.log("onRedirectOpenModal",onRedirectOpenModal)
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="ssm"
        fullWidth
        keepMounted
        scroll={mobile_ssm ? "paper": "body"}
        TransitionComponent={mobile_ssm ? Transition : undefined}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
          alignItems: {xs: "flex-end", ssm: "center"},
        },
          "& .MuiDialog-paper": {
            maxWidth: { xs: "100%", ssm: "550px" },
            width: { xs: "100%", ssm: "550px" },
            borderRadius: {xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`, ssm: pxToRem(20)},
            backgroundColor: "#F7F6F2",
            margin: {xs: "0px !important" , ssm: `${pxToRem(32)} !important`},
            maxHeight: {xs: "85vh", ssm: "none"},
          },
          zIndex: 1500,
        }}
      >
        <ModalHeader
          title="Authentication Required"
          inModal={true}
          onClose={handleClose}
          isFeedBack
        />

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: {xs: `${pxToRem(30)} ${pxToRem(25)}`, ssm: `${pxToRem(40)} ${pxToRem(40)}`, sm: `${pxToRem(40)} ${pxToRem(60)}`},
            backgroundColor: "#f7f6f2",
          }}
        >
          <Typography
            variant="medium"
            component="p"
            fontWeight={600}
            fontSize={pxToRem(16)}
            textAlign="center"
          >
            {loginMessage}
          </Typography>
          <Button
            variant="yellow"
            onClick={() => {
              handleClose();

              openLoginModal();
            }}
            sx={{ width: {xs: "100%", ssm: "300px"}, maxWidth: {xs: "300px", ssm: "none"}, mt: pxToRem(40), height: pxToRem(54) }}
          >
            {loginMessage === "You need to log in first to unsubscribe." ? "Log In" : "Sign In or Create Account"}
          
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginSubscribeModal;