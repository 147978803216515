import { Box, Button, Typography } from "@mui/material";
import ModalLayout from "../preSubscribe/ModalLayout";
import { pxToRem } from "px2rem2px";
import RichTextDisplay from "../../components/displayRichText";

const CheckModal = (props) => {
  const { checkInfo, onClose } = props;

  return (
    <ModalLayout
      {...props}
      headerTitle="Pay by Check"
      isCheckModal={true}
    >
      <Box textAlign="center">
        <RichTextDisplay 
          str={checkInfo} showFull={true} 
          sx={{
              padding: 0,
              margin: 0,
            }}/>
        <Button
          variant="yellow"
          onClick={onClose}
          sx={{
            width: { xs: "100%", ssm: "300px" },
            maxWidth: { xs: "1000px", ssm: "none" },
            mt: pxToRem(40),
            height: pxToRem(54),
          }}
        >
          Close
        </Button>
      </Box>
    </ModalLayout>
  );
};

export default CheckModal;
