import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Button,
  TextField,
  Typography,
  FormHelperText,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import ChangeSuccess from "../../../components/ChangeSuccess";
import ModalHeader from "../../../components/ModalHeader";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/material/styles";
import getErrorsArray from "../../../utils/getErrorsArray";
import { changePassword } from "../../../api/student";
import Toast from "../../../components/Toast";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TextFieldStyled = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: pxToRem(30),
    backgroundColor: "#EDECE8",
    height: pxToRem(54),
  },
  "& input": {
    borderRadius: `${pxToRem(30)} !important`,
    padding: `${pxToRem(15)} ${pxToRem(40)}`,
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
});

export default function ChangePasswordModal({ open, setOpen, update }) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [touched, setTouched] = useState({
    old_password: false,
    new_password: false,
    confirm_new_password: false,
  });
  const [fieldErrors, setFieldErrors] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const initialForm = {
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  };
  const [changePasswordForm, setChangePasswordForm] = useState(initialForm);

  function resetForm() {
    setChangePasswordForm(initialForm);
    setTouched({
      old_password: false,
      new_password: false,
      confirm_new_password: false,
    });
    setFieldErrors({
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    });
    setErrors([]);
    setErrorMessage("");
  }

  function handleSubmit() {
    if (
      !changePasswordForm.old_password ||
      !changePasswordForm.new_password ||
      !changePasswordForm.confirm_new_password
    ) {
      setTouched({
        old_password: true,
        new_password: true,
        confirm_new_password: true,
      });
      setFieldErrors({
        old_password: !changePasswordForm.old_password
          ? "This field is required"
          : "",
        new_password: !changePasswordForm.new_password
          ? "This field is required"
          : "",
        confirm_new_password: !changePasswordForm.confirm_new_password
          ? "This field is required"
          : "",
      });
      return;
    }

    if (
      changePasswordForm.new_password !==
      changePasswordForm.confirm_new_password
    ) {
      setFieldErrors({
        new_password: "Passwords do not match",
        confirm_new_password: "Passwords do not match",
      });
      return;
    }

    const payload = changePasswordForm;
    setLoading(true);
    changePassword(payload)
      .then((res) => {
        setLoading(false);
        setOpenSuccess(true);
        setTimeout(() => {
          setOpen(false);
          resetForm(); // Reset form after closing the dialog
        }, 1200);
        setErrors([]);
      })
      .catch((error) => {
        setLoading(false);
        const responseErrors = error.response.data;
        if (responseErrors.old_password) {
          setFieldErrors((prev) => ({
            ...prev,
            old_password: responseErrors.old_password[0],
          }));
        }
        if (responseErrors.new_password) {
          setFieldErrors((prev) => ({
            ...prev,
            new_password: responseErrors.new_password[0],
          }));
        } else {
          setErrorMessage(getErrorsArray(error.response.data).join(", "));
        }
      });
  }

  const handleBlur = (field) => {
    setTouched((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  const handleChange = (field, value) => {
    setChangePasswordForm((prev) => ({
      ...prev,
      [field]: value,
    }));
    setFieldErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  useEffect(() => {
    console.log("change password", changePasswordForm);
  }, [changePasswordForm]);

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <>
      <Toast
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        message={"Your password has been successfully updated!"}
      />
      <Dialog
        fullWidth
        open={open}
        maxWidth="ssm"
        keepMounted
        scroll={mobile_ssm ? "paper": "body"}
        TransitionComponent={mobile_ssm ? Transition : undefined}
        sx={{
          "& .MuiDialog-container": {
          alignItems: {xs: "flex-end", ssm: "center"},
        },
          "& .MuiPaper-root": {
            borderRadius: {xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`, ssm: pxToRem(20)},
            margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
            maxWidth: { xs: "100%", ssm: "500px" },
            width: { xs: "100%", ssm: "500px" },
            maxHeight: {xs: "85vh", ssm: "none"},
          },
        }}
        onClose={handleClose}
      >
        <ModalHeader title="Change Password" inModal onClose={handleClose} />
        <DialogContent sx={{ backgroundColor: "#f7f6f2" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
              flexDirection: "column",
              marginBottom: "1rem",
            }}
          >
            <Typography color="gray" sx={{ textAlign: "center" }}>
              Please Enter Your Current Password and Choose a New Password
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "18px",
            }}
          >
            <TextFieldStyled
              placeholder={"Current Password"}
              value={changePasswordForm.old_password}
              onBlur={() => handleBlur("old_password")}
              onChange={(e) => handleChange("old_password", e.target.value)}
              type="password"
              error={
                (changePasswordForm.old_password === "" &&
                  touched.old_password) ||
                !!fieldErrors.old_password
              }
              helperText={
                (changePasswordForm.old_password === "" && touched.old_password
                  ? "This field is required"
                  : "") || fieldErrors.old_password
              }
            />

            <TextFieldStyled
              placeholder={"New Password"}
              value={changePasswordForm.new_password}
              onBlur={() => handleBlur("new_password")}
              onChange={(e) => handleChange("new_password", e.target.value)}
              type="password"
              error={
                (changePasswordForm.new_password === "" &&
                  touched.new_password) || 
                (changePasswordForm.new_password !==
                  changePasswordForm.confirm_new_password &&
                  touched.confirm_new_password &&
                  touched.new_password) || 
                (changePasswordForm.new_password.length > 0 &&
                  changePasswordForm.new_password.length < 6) || 
                !!fieldErrors.new_password
              }
              helperText={
                (changePasswordForm.new_password === "" && touched.new_password
                  ? "This field is required"
                  : "") ||
                (changePasswordForm.new_password !==
                  changePasswordForm.confirm_new_password &&
                touched.confirm_new_password &&
                touched.new_password
                  ? "Passwords do not match"
                  : "") ||
                (changePasswordForm.new_password.length > 0 &&
                changePasswordForm.new_password.length < 6
                  ? "Password must be at least 6 characters"
                  : "") ||
                fieldErrors.new_password
              }
            />

            <TextFieldStyled
              placeholder={"Confirm New Password"}
              value={changePasswordForm.confirm_new_password}
              onBlur={() => handleBlur("confirm_new_password")}
              onChange={(e) =>
                handleChange("confirm_new_password", e.target.value)
              }
              type="password"
              error={
                (changePasswordForm.confirm_new_password === "" &&
                  touched.confirm_new_password) || 
                (changePasswordForm.new_password !==
                  changePasswordForm.confirm_new_password &&
                  touched.confirm_new_password &&
                  touched.new_password) || 
                !!fieldErrors.confirm_new_password
              }
              helperText={
                (changePasswordForm.confirm_new_password === "" &&
                touched.confirm_new_password
                  ? "This field is required"
                  : "") ||
                (changePasswordForm.new_password !==
                  changePasswordForm.confirm_new_password &&
                touched.confirm_new_password &&
                touched.new_password
                  ? "Passwords do not match"
                  : "") ||
                fieldErrors.confirm_new_password
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pb: "30px",
              backgroundColor: "#f7f6f2",
            }}
          >
            <Button disabled={loading} sx={{mt: "18px"}} onClick={handleSubmit} variant="yellow">
              Update Password
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
