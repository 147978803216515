import React from 'react';
import { Box } from '@mui/material';

function pxToRem(value) {
  return `${value / 16}rem`;
}

const ReusableHeaderBox = ({
  backgroundImg,
  treeImg,
  showTree = true,
  children,
  heightXs = "100px",
  heightMd = pxToRem(130),
  borderRadiusMd = "40px 40px 0 0",
  gradientComponent: Gradient = null,
  headerComponent: HeaderComponent = null,
  guidesComponent: GuidesComponent = null,
  ...props
}) => {
  return (
    <Box
      position="relative"
      display="flex"
      overflow="hidden"
      height={{ xs: heightXs, md: heightMd }}
      borderRadius={{ xs: 0, md: borderRadiusMd }}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
      {...props}
    >
      {showTree && <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflowX: "clip",
          "&::before": {
            content: "''",
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-125%)",
            background: `url(${treeImg}) no-repeat`,
            zIndex: 2,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            width: pxToRem(520),
            height: pxToRem(600),
            opacity: 0.4,
            "@media(max-width: 1300px)": {
              left: "30px",
              transform: "none",
            },
            "@media(max-width: 899px)": {
              left: "50%",
              transform: "translateX(-50%)",
            },
          },
        }}
      ></Box>}

      {Gradient && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          borderRadius={{ xs: 0, md: borderRadiusMd }}
          overflow="hidden"
          zIndex={1}
        >
          <Gradient topOnly />
        </Box>
      )}


      {HeaderComponent && <HeaderComponent />}


      {GuidesComponent && <GuidesComponent color="rgba(0,0,0,0.03)" zIndex="0" />}


      {children}
    </Box>
  );
};

export default ReusableHeaderBox;
