import React, { useState, useCallback, useContext, useRef } from "react";
import { Box, Skeleton } from "@mui/material";
import BlockBordered from "../../../components/BlockBordered";
import RichTextDisplay from "../../../components/displayRichText";
import TextLink from "../../../components/TextLink";
import { pxToRem } from "px2rem2px";
import { PreSubscribeContext } from "..";
import { getLanguageClass } from "../../../utils/languageClass";
import InfoBlock from "../../../components/InfoBlock";

const PreSubscribeDescription = () => {
  const { course } = useContext(PreSubscribeContext);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const descriptionRef = useRef(null);

 
  const handleReadMoreClick = (e) => {
    e.preventDefault();
    if (showFullDescription) {
      descriptionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setTimeout(() => {
    setShowFullDescription((prev) => !prev);
  },500);
   
  };

  const handleContentExceedsMaxHeight = useCallback((exceedsMaxHeight) => {
    setShowReadMore(exceedsMaxHeight);
  }, []);

  return (
    <Box sx={{ mb: "18px" }}  ref={descriptionRef}>
      <InfoBlock title="Course Description">
        <BlockBordered
          str={course?.description}
          style={{ position: "relative" }}
          sx={{
            width: "2px !important",
            display: { xs: "none", ssm: "block" },
          }}
          isAlignedRight={
            getLanguageClass(course?.description) === "is-hebrew-text"
          }
        >
          {course?.description ? (
            <>
              <Box sx={{ "& p": { margin: 0 } }}>
                <RichTextDisplay
                  str={course?.description}
                  showFull={showFullDescription}
                  onContentExceedsMaxHeight={handleContentExceedsMaxHeight}
                  isPresubscribe
                />
              </Box>
              {showReadMore && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent:
                      getLanguageClass(course?.description) === "is-hebrew-text"
                        ? "flex-end"
                        : "flex-start",
                    mt: 1,
                  }}
                >
                  <TextLink
                    href=""
                    onClick={handleReadMoreClick}
                    showFullDescription={showFullDescription}
                    sx={{ "& span": { fontSize: pxToRem(14) } }}
                  >
                    {showFullDescription ? "Show Less" : "Read More"}
                  </TextLink>
                </Box>
              )}
            </>
          ) : (
            <Box>
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="90%" />
            </Box>
          )}
        </BlockBordered>
      </InfoBlock>
    </Box>
  );
};

export default PreSubscribeDescription;
