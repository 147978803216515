import axios from "axios";

import api from "./api";
import { url } from "./config";

export async function getStudent() {
  const res = await api.get(`/api/student/my_profile/`);
  return res;
}

export async function logIn(
  username,
  password,
  captchaValue,
  keepMeLogged,
  visitorId
) {
  const res = await axios.post(`${url}/api/authentication/login/`, {
    username,
    password,
    captcha_value: captchaValue,
    keep_me_signed_in: keepMeLogged,

    device_fingerprint: visitorId,
  });
  return res;
}

export async function signUp(payload) {
  const res = await axios.post(`${url}/api/authentication/sign-up/`, payload, {
    headers: null,
  });
  return res;
}

export async function logOut(visitorId) {
  try {
    const res = await api.post(`/api/authentication/logout/`, {
      device_fingerprint: visitorId,
    });
    return res;
  } catch (error) {
    console.error("Error logging out:", error);
    throw error;
  }
}

export async function editProfile(payload) {
  const res = await api.patch(`/api/student/my_profile/`, payload);
  return res;
}

export async function editProfilePicture(payload) {
  const res = await api.patch(`/api/student/my_profile/`, payload, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  return res;
}
export async function changePassword(payload) {
  const res = await api.patch(`/api/student/change_password/`, payload);
  return res;
}

export async function changeTemporaryPassword(payload) {
  const res = await axios.patch(
    `${url}/api/authentication/change_temporary_password/`,
    payload
  );
  return res;
}

export async function requestResetPassword(payload) {
  const res = await axios.post(
    `${url}/api/authentication/password_reset_request/`,
    payload
  );
  return res;
}
export async function requestResendPassword(payload) {
  const res = await axios.post(
    `${url}/api/authentication/password_resend_code/`,
    payload
  );
  return res;
}

export async function resetPassword(payload) {
  const res = await axios.post(
    `${url}/api/authentication/password_reset_confirm/`,
    payload
  );
  return res;
}

export async function verifyemail(payload) {
  const res = await axios.post(
    `${url}/api/authentication/confirm_email/`,
    payload
  );
  return res;
}

export async function requestResendVerifyEmail(email) {
  const payload = { email: email };
  const res = await axios.post(
    `${url}/api/authentication/verify_email_request/`,
    payload
  );
  return res;
}

/**
 * POST request to confirm the new email address for a user.
 */
export async function verifyResetEmailCode(payload) {
  const res = await api.post(`${url}/api/student/confirm_new_email/`, payload);
  return res;
}

export async function verifyphone(payload) {
  const res = await axios.post(
    `${url}/api/authentication/confirm_phone/`,
    payload
  );
  return res;
}

export const getUserInfo = () => {
  let data = localStorage.getItem("studentData");
  return JSON.parse(data);
};

export async function completeGoogleSignUp(payload) {
  // const res = await api.post(
  //   `api/authentication/complete_google_signup/`,
  //   payload
  // );
  // return res;

  try {
    const res = await api.patch(
      `api/authentication/complete_google_signup/`,
      payload
    );
    return res;
  } catch (error) {
    console.log("Error completing google signup:", error);
    throw error;
  }
}

export const inviteAssociate = async (payload) => {
  try {
    const response = await api.post("/api/student/invitations/send/", payload);
    return {
      success: true,
      message: "Invitation sent successfully.",
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response.data.detail || "Failed to send invitation.",
    };
  }
};

export const acceptInvitation = async (invitationId) => {
  try {
    const response = await api.post(
      `/api/student/invitations/${invitationId}/accept/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const rejectInvitation = async (invitationId) => {
  try {
    const response = await api.post(
      `/api/student/invitations/${invitationId}/reject/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSentInvitations = async () => {
  try {
    const response = await api.get("/api/student/invitations/sent/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export async function getInvitations() {
  const res = await api.get(`/api/student/invitations/received/`);
  return res;
}
export async function getDashboardNotifications(page, pageSize) {
  const res = await api.get(
    `/api/student/notifications/received/?page=${page}&page_size=${pageSize}`
  );
  return res;
}

export const getAssociates = async () => {
  try {
    const response = await api.get("/api/student/associates/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

// settings
export async function getMySetting() {
  const res = await api.get(`/api/student/my_settings/`);
  return res;
}

export const getPlayback = async (id) => {
  try {
    // const response = await api.get(`/api/student/my_settings/${id}`);
    const response = await api.get(`/api/student/my_settings/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const editPlayback = async (payload) => {
  try {
    // const response = await api.patch(`/api/student/my_settings/${id}`);

    const response = await api.patch(`/api/student/my_settings/`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPaymentHistory = async () => {
  try {
    const response = await api.get("/api/subscription/payment-history/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchStudents = (searchTerm) => {
  return api.get(`/api/student/students/?search=${searchTerm}`);
};

export const getGiftCheckoutLink = (
  courseId,
  email,
  priceType,
  message = ""
) => {
  const priceClass = "Gift";
  return api.get("/api/subscription/get-gift-checkout-link/", {
    params: {
      course_id: courseId,
      gifted_email: email,
      price_type: priceType,
      price_class: priceClass,
      optional_message: message,
    },
  });
};

export async function getNotifications(page = 1, pageSize = 4) {
  const res = await api.get(
    `/api/student/notifications/received/?page=1&page_size=4`
  );
  return res;
}

export const hideNotification = async (notificationId) => {
  try {
    const response = await api.post(
      `/api/student/notifications/${notificationId}/hide/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export async function getFavoriteCourses() {
  const res = await api.get(`/api/courses/my_favorite_courses/`);
  return res;
}

// settings

// notifications
export async function getNotificationsSettings() {
  const res = await api.get(`/api/student/notifications/settings/`);
  return res;
}

export async function updateNotificationSettings(payload) {
  const res = await api.patch(`/api/student/notifications/settings/`, payload);
  return res;
}

// unsubscribe from email
export const unsubscribeFromEmail = async (token) => {
  try {
    const response = await api.get(`/api/student/unsubscribe/${token}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// renew subscription
export const renewSubscription = async (token) => {
  try {
    const response = await api.get(`/api/student/renew_subscription/${token}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// prolong subscription
export const prolongSubscription = async (token) => {
  try {
    const response = await api.get(`/api/student/prolong_subscription/${token}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


// subscribe to newsletter

export const subscribeToNewsletter = async (email) => {
  const payload = {
    email,
  };
  try {
    const response = await axios.post(`${url}/api/notifications/newsletter_subscribers/`,payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
