import { useState, useEffect } from "react";
import confetti from "canvas-confetti";
import {
  Box,
  Button,
  Container,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { SwiperSlide } from "swiper/react";
import { pxToRem } from "px2rem2px";
import Slider from "../../components/Slider";
import TextLink from "../../components/TextLink";
import Slide from "../../components/Slide";

import SlideBlog1 from "../../images/slide-blog11.jpg";
import SlideBlog2 from "../../images/slide-blog21.jpeg";
import SlideBlog3 from "../../images/slide-blog31.jpg";
import Guides from "../../components/Guides";
import NewsletterSignUpDialog from "./NewsletterSignUpDialog";
import { editProfile, subscribeToNewsletter } from "../../api/student";
import Toast from "../../components/Toast";
import { getBlogs } from "../../api/blogs";
import { getLanguageClass } from "../../utils/languageClass";
const BlogNews = ({ student }) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [isSubscribedToNewsletter, setIsSubscribedToNewsletter] =
    useState(false);

  const fetchBlogs = () => {
    getBlogs()
      .then((res) => {
        setBlogs(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleSubscribe = async () => {
    setLoading(true);

    if (student) {
      try {
        const response = await subscribeToNewsletter(student?.user?.email);
        if (response) {
          const res = await editProfile({ is_newsletter_subscribed: true });
          if (res.status === 200 || res.status === 202) {
            triggerConfetti();
            setIsSubscribedToNewsletter(true);
          } else {
            throw new Error(res.data);
          }
        }
      } catch (error) {
        console.log("Subscription failed:", error);
      }
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(emailAddress)) {
        setEmailError(true);
      } else {
        setEmailError(false);

        try {
          const response = await subscribeToNewsletter(emailAddress);
          if (response) {
            triggerConfetti();
            setIsSubscribedToNewsletter(true);
          }
        } catch (error) {
          console.log("Subscription failed:", error);
          if (
            error.response?.data?.email &&
            error.response?.data?.email[0] ===
              "news letter subscribers with this email already exists."
          ) {
            setToastOpen(true);
            setToastMessage(
              "This Email is alraedy subscribed to our newsletter"
            );
          }
        }
      }
    }
    setLoading(false);
  };

  const triggerConfetti = () => {
    if (isConfettiActive) return;

    setIsConfettiActive(true);

    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      colors: ["#FFC700", "#FF0000", "#2E3192", "#41BBC7"],
    });

    confetti({
      particleCount: 50,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: ["#FFC700", "#FF0000", "#2E3192", "#41BBC7"],
    });

    confetti({
      particleCount: 50,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: ["#FFC700", "#FF0000", "#2E3192", "#41BBC7"],
    });

    // Reset cooldown after 5 seconds
    setTimeout(() => {
      setIsConfettiActive(false);
    }, 5000);
  };

  return (
    <>
      <Toast
        open={toastOpen}
        message={toastMessage}
        onClose={() => {
          setToastOpen(false);
        }}
      />
      <Box mb={{ xs: "80px", sm: pxToRem(150) }}>
        <Container disableGutters>
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent="space-between"
            gap="15px"
            mb={{ xs: 0, md: pxToRem(40) }}
          >
            <Typography variant="sectionTitle" component="h2">
              Our <span className="highlighted">blog</span> &{" "}
              <span className="highlighted">news</span>
            </Typography>
            <TextLink href="/blogs">Visit Blog & News</TextLink>
          </Stack>
        </Container>

        <Slider
          slidesPerView={1}
          spaceBetween={20}
          arrows
          breakpoints={{
            600: {
              slidesPerView: 2,
            },
            900: {
              slidesPerView: 3,
            },
          }}
          style={{
            paddingTop: pxToRem(40),
            paddingBottom: pxToRem(50),
            paddingLeft: pxToRem(20),
            paddingRight: pxToRem(20),
            marginTop: 0,
            marginBottom: 0,
            marginLeft: pxToRem(-20),
            marginRight: pxToRem(-20),
          }}
        >
          {blogs &&
            blogs.length > 0 &&
            blogs.slice(0, 7).map((blog, index) => (
              <SwiperSlide key={index}>
                <Box display="flex" flexDirection="column" height="100%">
                  <Slide
                    image={blog?.image ? blog?.image : blog?.thumbnail}
                    title={blog?.title}
                    text={blog?.content}
                    blogLink={`/blog/${blog.id}`}
                    date={
                      blog?.published_at
                        ? new Date(blog.published_at).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "N/A"
                    }
                    label="New Post"
                    isBlog
                    style={{ flexGrow: 1 }}
                  />
                </Box>
              </SwiperSlide>
            ))}
        </Slider>

        {student && student?.is_newsletter_subscribed ? null : (
          <Container
            disableGutters
            sx={{ position: "relative", px: { xs: 0, md: "30px" } }}
          >
            <Box
              bgcolor="#026670"
              borderRadius={{ xs: 0, md: pxToRem(30) }}
              textAlign="center"
              py={pxToRem(60)}
              px={{ xs: "25px", sm: pxToRem(30) }}
              mt={pxToRem(40)}
              sx={{
                "@media (max-width: 1330px)": { mt: "120px" },
                "@media (max-width: 767px)": { mt: "80px" },
                height: "290px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box position="relative" zIndex={1}>
                <Typography
                  component="h3"
                  fontSize={
                    isSubscribedToNewsletter ? pxToRem(28) : pxToRem(22)
                  }
                  fontWeight={700}
                  color="#fff"
                  mb={isSubscribedToNewsletter ? 0 : pxToRem(20)}
                >
                  {isSubscribedToNewsletter
                    ? "You're in! Stay tuned for exciting updates!"
                    : "Subscribe to our newsletter:"}
                </Typography>
                {!isSubscribedToNewsletter && (
                  <Box maxWidth={pxToRem(380)} m="auto">
                    {!student && (
                      <OutlinedInput
                        fullWidth
                        placeholder="Your Email Address"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        onFocus={() => setEmailError(false)}
                        sx={{
                          height: pxToRem(54),
                          border: emailError
                            ? "2px solid red"
                            : "2px solid #BFBEBB",
                          borderRadius: pxToRem(32),
                          outline: 0,
                          backgroundColor: "#167078",
                          mb: pxToRem(20),
                          "& input": {
                            textAlign: "center",
                            color: "#fff",
                            outline: 0,
                            "&::placeholder": {
                              fontSize: pxToRem(16),
                              color: "#BFBEBB",
                              fontWeight: 500,
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    )}
                    {emailError && (
                      <Typography
                        color="red"
                        fontSize={pxToRem(12)}
                        mb={pxToRem(5)}
                        textAlign="center"
                      >
                        Please enter a valid email address.
                      </Typography>
                    )}
                    <Button
                      variant="yellow"
                      onClick={handleSubscribe}
                      sx={{
                        height: pxToRem(54),
                        position: "relative",
                        zIndex: 1,
                      }}
                      disabled={loading}
                    >
                      Subscribe
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>

            <Guides color="rgba(0,0,0,0.06)" zIndex="0" />
          </Container>
        )}
      </Box>
    </>
  );
};

export default BlogNews;
