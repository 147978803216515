import { Box, Typography } from "@mui/material";
import { getLanguageClass } from "../../utils/languageClass";

const Category = (props) => {
  return (
    <Box>
      <Box
        position={{xs: "sticky", ssm: "relative"}}
        mb="20px"
        display={{xs: "inline-flex", ssm: "block"}}
        justifyContent="center"
        width="100%"
        top={{xs: "0", ssm: "auto"}}
        backgroundColor="#f7f6f2"
        py="25px"
        zIndex={{xs: "2", ssm: "0"}}
        sx={{
          "&::before": {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            width: "100%",
            height: "2px",
            backgroundColor: props?.isActive?"#026670":"#FCE181",
            zIndex: -1,
          },
        }}
      >
        <Typography
          component="span"
          fontSize="14px"
          fontWeight={600}
          color={props?.isActive?"#FCE181":"#026670"}
          bgcolor={props?.isActive?"#026670":"#FCE181"}
          borderRadius="calc(infinity * 1px)"
          p="2px 30px"
          className={getLanguageClass(props?.title,true)}
        >
          {props?.title}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {xs: "1fr", ssm: "1fr 1fr"},
          gap: "40px 20px",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default Category;
