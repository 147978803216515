import { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  useTheme,
  useMediaQuery,
  Avatar,
  Typography,
  MenuItem,
  Grow,
  Portal,
} from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useModal } from "mui-modal-provider";
import { pxToRem, remToPx } from "px2rem2px";
import IconBtnCircular from "./IconBtnCircular";
import LinkBtn from "./LinkBtn";
import NestedMenu from "./NestedMenu";
import MenuDropdownLink from "./menuDropdown/MenuDropdownLink";
import MenuDropdown from "./menuDropdown/MenuDropdown";
import Toast from "./Toast";
import ToastCookie from "./ToastCookie";
import ProfileMenu from "./ProfileMenu";
import { saveStudentLocal, useAuth } from "../utils/AuthContext";
import { getNotifications, getStudent, getUserInfo } from "../api/student";
import { ReactComponent as Menu } from "../images/menu.svg";
import { ReactComponent as Search } from "../images/search.svg";
import { ReactComponent as User } from "../images/user.svg";
import { ReactComponent as Close } from "../images/close.svg";
import { ReactComponent as Car } from "../images/car.svg";
import AvatarImg from "../images/avatar.png";
import LoginModal from "../pages/auth/LoginModal";
import NotificationsDropdown from "./NotificationsDropdown";
import LinkWithIcon from "./LinkWithIcon";
import TestimonialMenu from "./TestimonialMenu";
import SearchTabs from "./SearchTabs";
import { getNavigationMenu, getGeneralSettings } from "../api/cmsApi";
import { url } from "../api/config";


const Hr = styled(Divider)({
  width: "100%",
  borderColor: "rgba(191,190,187,0.5)",
});

const Header = (props) => {
  const [navigationMenu, setNavigationMenu] = useState([]);
  const [logo, setLogo] = useState("");

  const fetchNavigationMenu = () => {
    getNavigationMenu()
      .then((res) => {
        setNavigationMenu(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchGeneralSettings = () => {
    getGeneralSettings()
      .then((res) => {
        setLogo(`${url}${res.logo}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchNavigationMenu();
    fetchGeneralSettings();
  }, []);

  const { showModal } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [toast1, setToast1] = useState(false);
  const [toast2, setToast2] = useState(false);
  const [toast3, setToast3] = useState(false);
  const header = useRef(null);
  const { pathname } = useLocation();
  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();
  const userInfo = getUserInfo();

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const location = useLocation();

  const presubscribeAndSubscribed = location.pathname.startsWith("/course/");

  const presubscribeOnly =
    location.pathname.startsWith("/course/") &&
    location.pathname.includes("presubscribe");

  const subscribedOnly =
    location.pathname.startsWith("/course/") &&
    !location.pathname.includes("presubscribe");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast1(false);
    setToast2(false);
    setToast3(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  const openPopup = () => {
    toggleDrawer(false)({ type: "click" });
    showModal(LoginModal);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sticky = header.current.offsetTop;

      if (window.scrollY > sticky) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // notifications start

  const [nextPage, setNextPage] = useState(1);

  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState([]);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchNotifications = useCallback(async (page) => {
    setLoading(true);
    try {
      const response = await getNotifications({ page });
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...response.data.results.filter(
          (newNotification) =>
            !prevNotifications.some(
              (prevNotification) => prevNotification.id === newNotification.id
            )
        ),
      ]);
      setHasMore(response.data.next !== null);
      setNotificationsCount(response.data.count);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNotifications(nextPage);
  }, [nextPage, fetchNotifications]);

  // notifications end

  // full name
  const capitalizeFirstLetter = (str) => {
    if (!str || typeof str !== "string") return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const nickName = userInfo?.nickname
    ? capitalizeFirstLetter(userInfo.nickname)
    : "";
  const firstName = userInfo?.user?.first_name
    ? capitalizeFirstLetter(userInfo.user.first_name)
    : "";

  useEffect(() => {
    if (userInfo && userInfo.timestamp) {
      const currentTime = Date.now();
      const diff = currentTime - userInfo.timestamp;
      const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
      const fiftyMinutesInMs = 50 * 60 * 1000;

      if (diff < fiftyMinutesInMs) {
      } else {
        // console.log("UserInfo timestamp is more than 30 days old.");

        saveStudentLocal()
          .then(() => {
            // console.log("Student data saved locally successfully.");
          })
          .catch((error) => {
            console.error("Failed to save student data locally:", error);
          });
      }
    } else {
      // console.log("userInfo or timestamp is missing.");
    }
  }, [userInfo]);

  const MenuInner = () => (
    <Box sx={{ height: "100%", overflowY: "auto" }}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="#EDECE8"
        p={`${pxToRem(40)} ${pxToRem(80)}`}
        sx={{
          "& button": { boxShadow: "none" },
          "@media(max-width: 400px)": {
            paddingX: "30px",
          },
        }}
      >
        {isAuthenticated ? (
          <Box>
            <Link to="/user/dashboard" component={NavLink} underline="none">
              <Avatar sx={{ width: pxToRem(48), height: pxToRem(48) }}>
                <img src={userInfo?.avatar || AvatarImg} alt="" />
              </Avatar>{" "}
              {userInfo && userInfo.user && (
                <Typography
                  fontSize="15px"
                  fontWeight={700}
                  mt="10px"
                  whiteSpace="no-wrap"
                  sx={{ color: "#333333" }}
                >
                  Hi,{" "}
                  {(nickName || firstName)?.length > 15
                    ? `${(nickName || firstName)?.substring(0, 15)}...`
                    : nickName || firstName}
                </Typography>
              )}
            </Link>
          </Box>
        ) : (
          <Button
            onClick={openPopup}
            variant="yellow"
            sx={{
              height: `${pxToRem(40)} !important`,
              fontSize: pxToRem(14),
              p: `${pxToRem(20)} ${pxToRem(47)} !important`,
            }}
          >
            Sign In
          </Button>
        )}
        <IconBtnCircular onClick={toggleDrawer(false)}>
          <Close color="#026670" />
        </IconBtnCircular>
      </Stack>
      <Stack
        alignItems="flex-start"
        gap={pxToRem(15)}
        p={`${pxToRem(30)} ${pxToRem(80)} ${pxToRem(30)}`}
        sx={{
          "& > a, & > .MuiBox-root > a": {
            fontSize: pxToRem(25),
            fontWeight: 700,
          },
          "@media(max-width: 400px)": {
            paddingX: "30px",
          },
        }}
      >
        {/* //here  */}
        {navigationMenu &&
          navigationMenu.map((item, index) => {
            if (!item.submenus || item.submenus.length === 0) {
              // Render a simple LinkBtn for items without submenus
              return (
                <>
                  <LinkBtn
                    //to={item.url}
                    url={item.url}
                    title={item.label}
                    useFirstStyle={true}
                    key={index}
                    newTab={item.target_new_tab}
                    isSideBar={true}
                  />
                  <Hr key={`hr-${index}`} />
                </>
              );
            } else if (item.submenus && item.submenus.length > 0) {
              // Render NestedMenu for items with submenus
              return (
                <>
                  <NestedMenu
                    title={item.label}
                    key={index}
                    url={item.url}
                    newTab={item.target_new_tab}
                    isSideBar={true}
                  >
                    {item.submenus.map((subitem, subIndex) => (
                      <MenuDropdownLink
                        to={subitem.url}
                        key={`submenu-${subIndex}`}
                        newTab={subitem.target_new_tab}
                        isSideBar={true}
                      >
                        {subitem.label}
                      </MenuDropdownLink>
                    ))}
                  </NestedMenu>
                  <Hr key={`hr-${index}`} />
                </>
              );
            }
            return null; // Ensure the map function always returns a value
          })}
        <Stack
          width="100%"
          py={pxToRem(30)}
          gap={pxToRem(20)}
          borderBottom={
            isAuthenticated ? "1px solid rgba(191,190,187,0.5)" : "none"
          }
          sx={{ "& a": { fontSize: pxToRem(16) } }}
        >
          <MenuDropdownLink
            to="/user/dashboard"
            // state={{ referer: pathname }}
            state={{ referer: pathname, showMenu: true }}
          >
            Account
          </MenuDropdownLink>

          {notificationsCount > 0 && (
            <MenuDropdownLink to="/user/dashboard" noOverflow>
              <NotificationsDropdown
                insideUserProfile
                handleCloseParentMenu={handleClose}
                notifications={notifications}
                setNotifications={setNotifications}
                nextPage={setNextPage}
                hasMore={hasMore}
                setHasMore={setHasMore}
                loading={loading}
                count={notificationsCount}
              />
            </MenuDropdownLink>
          )}

          <MenuDropdownLink to="/user/courses">My Courses</MenuDropdownLink>
          <MenuDropdownLink to="/user/favorites">My Favorites</MenuDropdownLink>
          <MenuDropdownLink to="/support">Help & Support</MenuDropdownLink>
        </Stack>

        {isAuthenticated && (
          <Button
            variant="outlined"
            onClick={() => logout()}
            sx={{
              height: `${pxToRem(40)} !important`,
              fontSize: pxToRem(14),
              p: `${pxToRem(18)} ${pxToRem(40)} !important`,
              borderWidth: pxToRem(2),
              mt: pxToRem(25),
              "&:hover": {
                border: "2px solid #026670",
              },
            }}
          >
            Sign Out
          </Button>
        )}
      </Stack>
    </Box>
  );

  const [showSearchTabs, setShowSearchTabs] = useState(false);

  const toggleSearchTabs = () => {
    setShowSearchTabs((prev) => !prev);
  };

  useEffect(() => {
    if (!mobile_ssm) {
      return;
    }
    if (showSearchTabs) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      if (showSearchTabs) {
        document.body.style.overflow = "auto";
      }
    };
  }, [mobile_ssm, showSearchTabs]);

  const toggleDrivingMode = () => {
    props?.setDrivingMode(!props?.drivingMode);
  };

  return (
    <>
    
      <Box
        ref={header}
        // className={
        //   props?.nonSticky && tablet
        //     ? "non-sticky"
        //     : isSticky && !mobile_ssm
        //     ? "sticky"
        //     : "non-sticky"
        // }
        className={tablet ? "non-sticky" : "sticky"}
        width="100%"
        zIndex={100}
      >
        <Stack
          maxWidth={pxToRem(1380)}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          py={{ xs: pxToRem(30), md: "10px" }}
          m="auto"
        >
          <Stack
            flex={1}
            flexDirection="row"
            alignItems="center"
            columnGap={{ xs: 0, lg: pxToRem(15) }}
          >
            {!props.menuVisible || tablet ? (
              <Box
                mr={`${
                  (presubscribeAndSubscribed ? 0 : 20) +
                  (subscribedOnly && !mobile_ssm ? 20 : 0)
                }px`}
              >
                <IconBtnCircular onClick={toggleDrawer(true)}>
                  <Menu />
                </IconBtnCircular>
              </Box>
            ) : null}

            {/* /back button  */}
            {subscribedOnly && mobile_ssm && (
              <LinkWithIcon to="/courses" sx={{ marginTop: pxToRem(14) }} />
            )}
            {presubscribeOnly && tablet && (
              <LinkWithIcon to="/courses" sx={{ marginTop: pxToRem(14) }} />
            )}

            {((subscribedOnly && !mobile_ssm) || !subscribedOnly) && (
              <Box
                position="relative"
                sx={{ zIndex: 1, width: "100px", height: "100%" }}
              >
                {logo && (
                  <Link to="/" style={{ display: "flex" }}>
                    <img
                      src={logo}
                      alt="Logo"
                      style={{
                        width: mobile_ssm ? "85px" : "100px",
                        height: "auto",
                      }}
                    />
                  </Link>
                )}
              </Box>
            )}

            {props.menuVisible && !tablet ? (
              <Stack
                flex={1}
                flexDirection="row"
                alignItems="center"
                justifyContent={{ xs: "space-between", lg: "flex-start" }}
                columnGap={{ xs: pxToRem(15), lg: pxToRem(56) }}
                mx={pxToRem(40)}
              >
                {navigationMenu &&
                  navigationMenu.map((item, index) => {
                    if (!item.submenus.length) {
                      return (
                        <LinkBtn
                          url={item.url}
                          title={item.label}
                          key={index}
                          newTab={item.target_new_tab}
                        />
                      );
                    } else if (item.submenus.length > 0) {
                      return (
                        <MenuDropdown
                          title={item.label}
                          key={index}
                          submenus={item.submenus}
                          url={item.url}
                          newTab={item.target_new_tab}
                        >
                          {item.submenus.map((subitem, subIndex) => (
                            <MenuDropdownLink
                              to={subitem.url}
                              key={subIndex}
                              newTab={subitem.target_new_tab}
                            >
                              {subitem.label}
                            </MenuDropdownLink>
                          ))}
                        </MenuDropdown>
                      );
                    }
                    return null;
                  })}
              </Stack>
            ) : null}
          </Stack>

          <Stack flexDirection="row" alignItems="center" gap="10px">
            {subscribedOnly && tablet && (
              <>
                {!props?.pausedOrExpired && (
                  <IconBtnCircular onClick={toggleDrivingMode}>
                    <Car />
                  </IconBtnCircular>
                )}

                {mobile_ssm && (
                  <TestimonialMenu>
                    <MenuItem onClick={() => {}}>Submenu link 01</MenuItem>
                    <MenuItem onClick={() => {}}>Another link 02</MenuItem>
                  </TestimonialMenu>
                )}
              </>
            )}
            {(!subscribedOnly || (subscribedOnly && !mobile_ssm)) && (
              <Box sx={{ position: { ssm: "relative" } }}>
                <IconBtnCircular
                  className="search-icon"
                  onClick={toggleSearchTabs}
                >
                  <Search color="#026670" />
                </IconBtnCircular>
                <Grow
                  in={showSearchTabs}
                  timeout={mobile_ssm ? 0 : 300}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box sx={{ position: "absolute", zIndex: 3, width: "100%" }}>
                    <SearchTabs toggleSearchTabs={toggleSearchTabs} />
                  </Box>
                </Grow>
              </Box>
            )}

            {!mobile_ssm &&
              (isAuthenticated ? (
                <>
                  {mobile ? null : notifications.length > 0 ? (
                    <IconBtnCircular>
                      <NotificationsDropdown
                        notifications={notifications}
                        setNotifications={setNotifications}
                        nextPage={setNextPage}
                        hasMore={hasMore}
                        setHasMore={setHasMore}
                        loading={loading}
                        count={notificationsCount}
                      />
                    </IconBtnCircular>
                  ) : null}

                  <ProfileMenu
                    notifications={notifications}
                    setNotifications={setNotifications}
                    nextPage={setNextPage}
                    hasMore={hasMore}
                    setHasMore={setHasMore}
                    loading={loading}
                    count={notificationsCount}
                  />
                </>
              ) : (
                <Box onClick={() => openPopup()}>
                  <IconBtnCircular>
                    <User height="19.99" width="19.99" />
                  </IconBtnCircular>
                </Box>
              ))}
          </Stack>
          <Drawer
            anchor="left"
            open={isOpen}
            onClose={toggleDrawer(false)}
            sx={{
              zIndex: 1500,
              "& .MuiPaper-root": {
                maxWidth: pxToRem(420),
                width: "100%",
                bgcolor: "#F7F6F2",
                borderRadius: `0 ${pxToRem(40)} ${pxToRem(40)} 0`,
                overflowY: "initial",
                overflow: "hidden",
                "@media(max-width: 400px)": {
                  borderRadius: 0,
                },
              },
            }}
          >
            <MenuInner />
          </Drawer>
        </Stack>
      </Box>
      <Toast
        open={toast1}
        onClose={handleClose}
        message="Toast notification text here"
        button
      />
      <Toast
        open={toast2}
        onClose={handleClose}
        message="Toast notification text here"
      />
      <ToastCookie
        open={toast3}
        onClose={handleClose}
        message="Nemo enim ipsam voluptatem
        quia voluptas sit aspernatur."
      />
    </>
  );
};

export default Header;
