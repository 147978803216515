import React, { useCallback, useEffect, useState } from "react";
import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import ResourcesTab from "./ResourcesTab";
import NotesBookmarksTab from "./NotesBookmarksTab";
import ConnectTab from "./ConnectTab";
import SubscriptionTab from "./SubscriptionTab";
import OverviewTab from "./OverviewTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ pt: { xs: 0, ssm: pxToRem(50) } }}>{children}</Box>
      )}
    </div>
  );
}

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-flexContainer": {
    alignItems: "flex-end",
    gap: pxToRem(2),
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: pxToRem(40),
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    flex: 1,
    height: "57px",
    minHeight: pxToRem(64),
    textTransform: "none",
    fontWeight: 600,
    fontSize: pxToRem(14),
    color: "primary",
    backgroundColor: "#E6E5E1",
    borderRadius: `${pxToRem(10)} ${pxToRem(10)} 0 0`,
    "&:hover": {
      backgroundColor: "#D9D8D4",
    },
    "&.Mui-selected": {
      color: "#FCE181",
      fontSize: pxToRem(16),
      backgroundColor: "#026670",
    },
  })
);

const CourseTabs = ({
  course,
  activeTab,
  setActiveTab,
  expandedPanel,
  setExpandedPanel,
  summaryRef,
  bookmarks,
  setBookmarks,
  notes,
  setNotes,
  playingLessonId,
  setPlayingLessonId,
  previousLessonStates,
  setPreviousLessonStates,
  isSubscribed,
  subscriptionStatus,
  setFetchCourseAgain,
  jumpToLatestProgress,
  jumpToBookMark,
  isCourseCompleted,
  hasContent,
  currentPlayingLesson,
  bookmarkListChanged,
  setBookmarkListChanged,
  noteListChanged,
  setNoteListChanged,
  selectedLesson,
  currentTime,
  courseProgress,
  subscriptionId,
  lessons,
  hideJumpToLatestProgress,
}) => {
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const my_subscription = course?.user_subscription;


  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <Box
      position="relative"
      width={{ xs: "100%", md: `calc(93.5% - ${pxToRem(300)})` }}
      mt={{ ssm: pxToRem(45), sm: pxToRem(100), md: 0 }}
      sx={{
        "&::before": {
          content: { xs: "none", ssm: "''" },
          position: "absolute",
          top: pxToRem(62),
          left: 0,
          height: pxToRem(2),
          width: "100%",
          backgroundColor: "#026670",
          zIndex: 1,
        },
      }}
    >
      <StyledTabs
        sx={{ display: { xs: "none", ssm: "flex" } }}
        value={activeTab}
        onChange={handleChange}
      >
        <StyledTab label="Course Overview" />
        <StyledTab label="Subscription" />
        <StyledTab label="Notes & Bookmarks" />
        {/* <StyledTab label="Connect" /> */}
        {/* {hasContent && <StyledTab label="Resources" />} */}

        {/* {subscriptionStatus !== "Paused" &&
          subscriptionStatus !== "Expired" && <StyledTab label="Connect" />} */}
        {hasContent &&
          subscriptionStatus !== "Paused" &&
          subscriptionStatus !== "Expired" && <StyledTab label="Resources" />}
      </StyledTabs>

      <TabPanel value={activeTab} index={mobile_ssm ? null : 0}>
        <OverviewTab course={course} progress={courseProgress} />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <SubscriptionTab
          my_subscription={my_subscription}
          course={course}
          progress={courseProgress}
          isSubscribed={isSubscribed}
          subscriptionStatus={subscriptionStatus}
          setFetchCourseAgain={setFetchCourseAgain}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <NotesBookmarksTab
          course={course}
          bookmarks={bookmarks}
          setBookmarks={setBookmarks}
          notes={notes}
          setNotes={setNotes}
          jumpToLatestProgress={jumpToLatestProgress}
          jumpToBookMark={jumpToBookMark}
          isCourseCompleted={isCourseCompleted}
          bookmarkListChanged={bookmarkListChanged}
          setBookmarkListChanged={setBookmarkListChanged}
          noteListChanged={noteListChanged}
          setNoteListChanged={setNoteListChanged}
          selectedLesson={selectedLesson}
          currentTime={currentTime}
          subscriptionStatus={subscriptionStatus}
          lessons={lessons}
          hideJumpToLatestProgress={hideJumpToLatestProgress}
        />
      </TabPanel>

      {/* <TabPanel value={activeTab} index={3}>
        <ConnectTab />
      </TabPanel> */}

      {hasContent &&
      subscriptionStatus !== "Paused" &&
      subscriptionStatus !== "Expired" ? (
        // <TabPanel value={activeTab} index={4}>
        <TabPanel value={activeTab} index={3}>
          <ResourcesTab
            expandedPanel={expandedPanel}
            setExpandedPanel={setExpandedPanel}
            summaryRef={summaryRef}
            lesson={currentPlayingLesson}
          />
        </TabPanel>
      ) : null}
    </Box>
  );
};

export default CourseTabs;
