import { Box } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { useEffect, useState } from "react";

const BlockBordered = (props) => {
  const [isAlignRight, setIsAlignRight] = useState(false);

  useEffect(() => {
    if (props?.isAlignedRight !== undefined) {
      setIsAlignRight(props?.isAlignedRight);
    } else {
      const div = document.createElement("div");
      div.innerHTML = props.str;
      const hasClass = div.querySelector(".ql-align-right") !== null;
      setIsAlignRight(hasClass);
    }
  }, [props.str, props?.isAlignedRight]);

  return (
    <Box
      sx={{
        display: "flex",
        columnGap: { xs: pxToRem(10), sm: pxToRem(12) },
        order: "3",
      }}
      mb={props.mb}
    >
      {!isAlignRight && (
        <Box
          sx={{
            ...props.sx,
            width: pxToRem(2),
            backgroundColor: "#BFBEBB",
            flexShrink: 0,
          }}
        />
      )}
      <Box width="100%">{props.children}</Box>

      {isAlignRight && (
        <Box
          sx={{
            // width: { xs: 0, sm: pxToRem(3) },
            width: pxToRem(3),
            backgroundColor: "#BFBEBB",
            ...(props.mr && { marginRight: "20px" }),
            ...props.sx,
          }}
        />
      )}
    </Box>
  );
};

export default BlockBordered;
