import React, { useState, useEffect} from "react";
import { Controller, useForm } from "react-hook-form";
import { pxToRem } from "px2rem2px";
import { Box, Stack, Typography, TextField } from "@mui/material";
import FormInputPhone from "../../components/FormInputPhone";
import FormInputText from "../../components/FormInputText";
import { width } from "@mui/system";

const InputGroup = (props) => {
  const inputStyles = {
    "& .MuiInputBase-root": {
      borderRadius: pxToRem(30),
      height: pxToRem(54),
      backgroundColor: "#EDECE8",
      "&.Mui-error": {
        outlineColor: "#d32f2f",
      },
    },
    "& input": {
      borderRadius: `${pxToRem(30)} !important`,
      padding: `${pxToRem(15)} ${pxToRem(40)}`,
      color: "#333",
      fontWeight: 600,
      "&.Mui-disabled": {
        color: "#BFBEBB",
      },
      "&:focus": {
        backgroundColor: "#fff",
      },
    },
  };

  const [temPhoneNumber, setTempPhoneNumber] = useState(
    props?.phoneNumber || ""
  );

  useEffect(() => {
      const storedData = localStorage.getItem("studentData");
      if (storedData) {
        try {
          const parsed = JSON.parse(storedData);
          const phoneNumber = parsed?.phone_number || "";
          setTempPhoneNumber(phoneNumber);
        } catch (e) {
          console.error("Error parsing studentData from localStorage:", e);
        }
      }
    }, []);

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    values: {
      value: props.value || "",
    },
  });

  return (
    <Stack
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={{ xs: "flex-start", md: "center" }}
      gap={{ xs: "10px", md: "30px" }}
    >
      <Typography width={{ xs: "auto", md: "200px" }} variant="medium">
        {props.label}
      </Typography>
      <Box width="100%">
        {props?.phone ? (
          <FormInputPhone
            userSettings
            placeholder="Phone"
            rules={{
              required: "Field can't be empty",
            }}
            value={temPhoneNumber}
            onChange={(data) => {
              setTempPhoneNumber(data.phone); 
              props.onChangePhoneNumber?.(data.phone);
              clearErrors("phoneNumber");
            }}
            muiProps={{
              sx: inputStyles,
            }}
            error={!!props?.serverError}
            helperText={props?.serverError ? props?.serverError : ""}
          />
        ) : (
          <Controller
            control={control}
            name="value"
            rules={props.rules}
            render={({ field, fieldState }) => {

              const showError = fieldState.error || props.serverError;
              const errorMessage = fieldState.error
                ? fieldState.error.message
                : props.serverError || "";

              return (
                <TextField
                  {...field}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e);
                    props.onChange?.(e.target.value);
                  }}
                  error={!!showError}
                  helperText={errorMessage}
                  type={props.type}
                  sx={inputStyles}
                />
              );
            }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default InputGroup;
