import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Skeleton,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import { styled } from "@mui/system";
import {
  MediaFullscreenButton,
  MediaGesture,
  MediaMenu,
  MediaMenuButton,
  MediaMenuItems,
  MediaOutlet,
  MediaPlayButton,
  MediaPlaybackRateMenuButton,
  MediaPlaybackRateMenuItems,
  MediaPlayer,
  MediaPoster,
  MediaQualityMenuButton,
  MediaQualityMenuItems,
  MediaSeekButton,
  MediaSliderValue,
  MediaTime,
  MediaTimeSlider,
  MediaTooltip,
  MediaVolumeSlider,
  useMediaStore,
} from "@vidstack/react";
import { useDraggable } from "@dnd-kit/core";

import { pxToRem } from "px2rem2px";
import { CSS } from "@dnd-kit/utilities";
import EndView from "./EndView";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import AudioImg from "../../images/Audio.svg";
import { ReactComponent as Play } from "../../images/play.svg";
import { ReactComponent as Pause } from "../../images/pause.svg";
import { ReactComponent as Volume } from "../../images/volume.svg";
import { ReactComponent as Fullscreen } from "../../images/fullscreen.svg";
import { ReactComponent as FullscreenExit } from "../../images/full-screen-exit.svg";
import { ReactComponent as Settings } from "../../images/settings.svg";
import { ReactComponent as Close } from "../../images/close.svg";
import { ReactComponent as NewWindow } from "../../images/open-in-new-window.svg";
import { ReactComponent as Setting } from "../../images/setting.svg";
import { ReactComponent as Reverse } from "../../images/reverse.svg";
import { ReactComponent as Forward } from "../../images/forward.svg";
import SidePanel from "./SidePanel";
import { updateProgress } from "../../api/course";
import Toast from "../Toast";

import { remindMeAtApi } from "../../api/course";

import { ReactComponent as CamcorderIcon } from "../../images/Camcorder_Icon.svg";
import { Replay } from "@mui/icons-material";

import NotificationsIcon from "@mui/icons-material/Notifications";
import DrivingModeSettings from "./DrivingModeSettings";
import PlayBtn from "../PlayBtn";
import TopPanel from "./TopPanel";
import CustomTimeSlider from "./CustomSlider";

const IconButtonStyled = styled(IconButton)(() => {
  return {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      "& svg": {
        color: "#FCE181",
      },
    },
  };
});

export default function VideoPlayerDraggable({
  resetPosition,
  x,
  y,
  preview,
  selectedLesson,
  openResourcesTab,
  startTime,

  setPlayIcon = () => {},
  playIcon,
  setVideoEnded = () => {},
  videoEnded,
  noListen,

  setNextLecture,
  nextLecture,
  lastLesson,
  isCourseLoading,
  id,
  bookmarkStartTime,
  setBookmarkStartTime = () => {},
  handleNextLectureClick = () => {},
  lessons,
  mediaControlsHeight = 64,
  modalScreen = false,
  setBookmarkListChanged,
  setNoteListChanged,
  setBlockAutoLectureId = () => {},

  currentTime,

  drivingMode,
  lessonLockedAndSelected,
  handlePlayPreviousLecture,

  useCustomPlayer,
  videoUrlCustom,
  thumbnailCustom,
  customPosition,

  startsPlaying,

  isPlaying,
  setIsPlaying,

  useParshaPlayer,
  pausedOrExpired,
  isPreview,
  progressRestriction,
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const isTabletOnly = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [productionMode, setProductionMode] = useState(true);

  const [firstTimeLoaded, setFirstTimeLoaded] = useState(true);

  // share icon state
  const [toastOpen, setToastOpen] = useState(false);
  const playerWrapper = useRef(null);
  const player = useRef(null);

  const { fullscreen } = useMediaStore(player);


  const currentTimeRef = useRef();

  const [isListening, setIsListening] = useState(false);
  const [isWatching, setIsWatching] = useState(false);

  const [showButtons, setShowButtons] = useState(true);

  const [isMediaReady, setIsMediaReady] = useState(false);

  const [listenWatchToggle, setListenWatchToggle] = useState(false);

  const [reservedTime, setReservedTime] = useState(null);

  const [isFixed, setIsFixed] = useState(false);
  const [playTriggered, setPlayTriggered] = useState(false);
  const [pauseTriggered, setPauseTriggered] = useState(false);

  const [scrollTargetPosition, setScrollTargetPosition] = useState(0);

  //start: loading delay rendering the component until image is ready
  const [shouldRenderContent, setShouldRenderContent] = useState(false);
  const [renderedUrl, setRenderedUrl] = useState("");
  // popup
  const [references, setReferences] = useState([]);

  const [popupData, setPopupData] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const popupEndTimeRef = useRef(null);

  //for selecting a bookmark inside the same lecture
  const [bookmarkToastOpen, setBookmarkToastOpen] = useState(false);
  const [bookmarkMessage, setBookmarkMessage] = useState("");
  const [bookmarkBgColor, setBookmarkBgColor] = useState(null);

  //remind me at
  const [remindMeAt, setRemindMeAt] = useState(false);

  // notes
  const [noteToastOpen, setNoteToastOpen] = useState(false);

  // next lesson not available but selected
  const [timeLeft, setTimeLeft] = useState("");
  const [nextLessonTime, setNextLessonTime] = useState(0);
  const [disableReminder, setDisableReminder] = useState(false);

  // driving mode

  const [drivingModeSpeed, setDrivingModeSpeed] = useState(1);

  // sticky player
  const [stickyPlayer, setStickyPlayer] = useState(false);

  const currentLessonId = selectedLesson?.id;
  // progress
  const prevProgressWatchTimeRef = useRef(null);

  // progress restriction
  const [maxWatchedTime, setMaxWatchedTime] = useState(null);

  const effectiveMax =
    selectedLesson?.watch_time && selectedLesson.watch_time > maxWatchedTime
      ? selectedLesson.watch_time
      : maxWatchedTime;

  const canForward10 =
    !selectedLesson?.id || !progressRestriction
      ? true
      : currentTime?.current + 10 <= effectiveMax;

  // poster
  const thumbnailImg = useCustomPlayer
    ? thumbnailCustom
    : selectedLesson?.thumb_nail_img || preview?.thumb_nail;

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: "draggable",
    disabled: !isFixed,
  });

  const style = {
    transform: stickyPlayer
      ? "translateX(50%)"
      : CSS.Translate.toString(transform),
  };

  const handleListenClicked = () => {
    setFirstTimeLoaded(false);
    setIsListening(true);
    setIsWatching(false);
    setPlayTriggered(true);
    setShowButtons(false); // Hide buttons
    if (player.current) {
      if (isMediaReady) {
        player.current.play();
        if (setPlayIcon) setPlayIcon(true);
      } else {
        player.current.addEventListener(
          "canplay",
          () => player.current.play(),
          { once: true }
        );
      }
    }
  };

  const handleWatchClicked = () => {
    setFirstTimeLoaded(false);
    setIsWatching(true);
    setIsListening(false);
    setIsListening(false);
    setPlayTriggered(true);
    setShowButtons(false);
    if (player.current) {
      if (isMediaReady) {
        player.current.play();
        if (setPlayIcon) setPlayIcon(true);
      } else {
        player.current.addEventListener(
          "canplay",
          () => player.current.play(),
          { once: true }
        );
      }
    }
  };

  // Pause
  function onPause(event) {
    if (event.request?.trigger.MEDIA_GESTURE) {
      setPauseTriggered(true);
    }
    if (!useCustomPlayer) {
      setIsPlaying(false);
    }
    setPlayTriggered(false);

    if (setPlayIcon) setPlayIcon(false);
  }

  // Play

  // non pip
  function onPlay(event) {
    if (event && event.request && event.request.trigger) {
    }
    if (!useCustomPlayer) {
      setIsPlaying(true);
    }
    setPlayTriggered(true);
    setPauseTriggered(false);
    if (setPlayIcon) setPlayIcon(true);
  }

  // pip
  // function onPlay(event) {
  //   //
  //   setFirstTimeLoaded(false);
  //   setShowButtons(false);

  //   //
  //   if (event && event.request && event.request.trigger) {
  //     console.log("PIPTESTING: onPlay triggered by request.");
  //   }

  //   if (!useCustomPlayer) {
  //     setIsPlaying(true);
  //   }
  //   setPlayTriggered(true);
  //   setPauseTriggered(false);
  //   if (setPlayIcon) setPlayIcon(true);

  //   if (tablet && !useCustomPlayer && document.pictureInPictureEnabled) {
  //     console.log("PIPTESTING: Picture-in-Picture is supported.");
  //     const videoElement = player?.current?.querySelector("video");
  //     if (videoElement) {
  //       console.log(
  //         "PIPTESTING: Correct video element found for PiP preparation:",
  //         videoElement
  //       );
  //     } else {
  //       console.warn("PIPTESTING: No video element found for PiP preparation.");
  //     }
  //   } else {
  //     console.warn(
  //       "PIPTESTING: Picture-in-Picture is not supported by this browser."
  //     );
  //   }
  //   console.log("PIPTESTING: Play state updated, but PiP not triggered.");
  // }

  const seekHandler = (offset) => {
    const currentTimeBefore = player.current.currentTime;

    const offsetAsNumber = Number(offset);
    if (player) {
      player.current.currentTime += offsetAsNumber;
    }
  };

  // Post Progress
  const postProgress = async () => {
    let payload = {
      watch_time: currentTimeRef.current,
      lesson: selectedLesson?.id || preview?.id,
    };

    prevProgressWatchTimeRef.current = currentTimeRef.current;

    try {
      const res = await updateProgress(payload);
    } catch (err) {
      console.error("debugprogress Error updating progress:", err);
    }
  };

  function onProviderSetup(event) {
    const provider = event.detail;
    if (provider.video) {
      provider.video.setAttribute("disablePictureInPicture", "");
    }
  }

  // PIP fix

  // function onProviderSetup(event) {
  //   const provider = event.detail;
  //   if (provider.video) {
  //     provider.video.removeAttribute("disablePictureInPicture");
  //     console.log("PIPTESTING: Removed disablePictureInPicture attribute.");
  //   }
  // }

  // remind me at
  const handleRemindMeClick = async (time) => {
    const now = new Date();

    const futureTime = new Date(now.getTime() + time * 1000);
    setRemindMeAt(true);
    try {
      const res = await remindMeAtApi(selectedLesson.id, futureTime);
      if (res) {
        setDisableReminder(true);
      }
    } catch (error) {
      console.error("Error reminding me:", error);
      if (
        error?.response?.data?.error ===
        "A reminder for this lesson already exists."
      ) {
        setDisableReminder(true);
      }
    }
  };

  // Set URL
  useEffect(() => {
    if (productionMode) {
      if (useCustomPlayer) {
        if (!useParshaPlayer) {
          if (videoUrlCustom && thumbnailCustom) {
            setRenderedUrl(videoUrlCustom);
          }
        } else {
          if (isListening) {
            setRenderedUrl(
              selectedLesson?.audio_url ||
                "https://files.vidstack.io/agent-327/audio.mp3"
            );
          } else {
            setRenderedUrl(
              selectedLesson?.video_url ||
                "https://files.vidstack.io/sprite-fight/hls/stream.m3u8"
            );
          }
        }
      } else {
        if (id) {
          if (isListening || drivingMode) {
            setRenderedUrl(
              selectedLesson?.audio_url || preview?.audio_preview_url
            );
          } else {
            setRenderedUrl(
              selectedLesson?.video_url || preview?.video_preview_url
            );
          }
        }
      }
    } else {
      setRenderedUrl("https://files.vidstack.io/sprite-fight/hls/stream.m3u8");
    }
  }, [
    id,
    preview?.video_preview,
    selectedLesson?.video,
    isListening,
    videoUrlCustom,
    thumbnailCustom,
    drivingMode,
  ]);

  useEffect(() => {
    if (useCustomPlayer) {
      setScrollTargetPosition(
        customPosition?.current?.getBoundingClientRect().top + window.scrollY
      );
    } else {
      setScrollTargetPosition(
        playerWrapper?.current?.getBoundingClientRect().top + window.scrollY
      );
    }
  }, [scrollTargetPosition, playerWrapper]);

  useEffect(() => {
    const callbackFunction = (entries) => {
      const [entry] = entries;
      if (!mobile_ssm) {
        setIsFixed(!entry.isIntersecting);
      }
      resetPosition();
    };

    let observerRefValue = null;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(callbackFunction, options);

    if (playerWrapper.current) {
      observer.observe(playerWrapper.current);
      observerRefValue = playerWrapper.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, []);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     console.log(
  //       "PIPTESTING: Visibility change detected. Document hidden:",
  //       document.hidden
  //     );
  //     if (!tablet || useCustomPlayer) return;

  //     const videoElement = player?.current?.querySelector("video");

  //     if (!videoElement) {
  //       console.warn(
  //         "PIPTESTING: No video element found for visibility change."
  //       );
  //       return;
  //     }

  //     if (document.hidden && document.pictureInPictureEnabled) {
  //       console.log("PIPTESTING: Tab hidden, checking for PiP activation.");
  //       if (
  //         typeof videoElement.requestPictureInPicture === "function" &&
  //         !document.pictureInPictureElement
  //       ) {
  //         videoElement
  //           .requestPictureInPicture()
  //           .then(() => {
  //             console.log(
  //               "PIPTESTING: PiP mode triggered on visibility change.",
  //               "tablet",
  //               tablet
  //             );
  //           })
  //           .catch((error) => {
  //             console.error(
  //               "PIPTESTING: Failed to enable PiP on visibility change:",
  //               error
  //             );
  //           });
  //       } else {
  //         console.log("PIPTESTING: PiP already active or not supported.");
  //       }
  //     }

  //     if (!document.hidden && document.pictureInPictureElement) {
  //       console.log("PIPTESTING: Tab visible, exiting PiP mode.");
  //       document
  //         .exitPictureInPicture()
  //         .then(() => {
  //           console.log("PIPTESTING: Exited PiP mode successfully.");
  //         })
  //         .catch((error) => {
  //           console.error("PIPTESTING: Failed to exit PiP mode:", error);
  //         });
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  // pip end

  // Post Progress
  useEffect(() => {
    // console.log("debug progress useEffect called ");
    if (videoEnded && currentLessonId) {
      postProgress();
      return;
    }

    if (playTriggered && currentLessonId) {
      const intervalId = setInterval(postProgress, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [videoEnded, playTriggered, currentLessonId]);

  // render content
  useEffect(() => {
    if (renderedUrl && (!isListening ? thumbnailImg : AudioImg)) {
      const timer = setTimeout(() => {
        setShouldRenderContent(true);
      }, 100);

      return () => clearTimeout(timer);
    } else {
      if (useCustomPlayer && videoUrlCustom && thumbnailCustom) {
        setShouldRenderContent(true);
      } else {
        setShouldRenderContent(false);
      }
    }
  }, [
    renderedUrl,
    isListening,
    thumbnailImg,
    AudioImg,
    useCustomPlayer,
    videoUrlCustom,
    thumbnailCustom,
  ]);

  useEffect(() => {
    if (startsPlaying) {
      handleWatchClicked();
    }
  }, [startsPlaying]);

  useEffect(() => {
    setReferences(
      selectedLesson?.course_references || preview?.course_references || []
    );
    const matchingReference = references.filter((ref) => {
      const referenceTime = ref.timestamp;
      return currentTime >= referenceTime && currentTime < referenceTime + 1;
    });
    if (matchingReference) {
      setPopupData(matchingReference);
      setOpenPopup(true);
      popupEndTimeRef.current = Math.floor(currentTime) + 4;
    }
  }, [currentTime, selectedLesson, preview]);

  useEffect(() => {
    if (popupData && popupData.length > 0) {
      let shouldOpenPopup = false;

      popupData.forEach((popupData) => {
        const referenceTime = popupData.timestamp;

        if (
          currentTime >= referenceTime &&
          currentTime < popupEndTimeRef.current
        ) {
          shouldOpenPopup = true;
        }
      });

      if (shouldOpenPopup) {
        setOpenPopup(true);
      } else {
        setOpenPopup(false);
      }
    } else {
      setOpenPopup(false);
    }
  }, [currentTime, popupData]);

  useEffect(() => {
    setShowButtons(true);
  }, [selectedLesson?.id]);

  useEffect(() => {
    if (bookmarkStartTime !== null && player.current) {
      player.current.currentTime = bookmarkStartTime;
      setBookmarkStartTime(null);
    }
  }, [bookmarkStartTime]);

  //time
  useEffect(() => {
    if (!lessons || !selectedLesson || !lessonLockedAndSelected) return;
    if (
      selectedLesson &&
      selectedLesson.hours_remaining &&
      (selectedLesson.hours_remaining.hours > 0 ||
        selectedLesson.hours_remaining.minutes > 0)
    ) {
      const { hours, minutes } = selectedLesson.hours_remaining;
      setTimeLeft(
        `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
      );
      const totalSeconds = hours * 3600 + minutes * 60;
      setNextLessonTime(totalSeconds);
    }
  }, [lessons, selectedLesson]);

  useEffect(() => {
    if (drivingMode) {
      setListenWatchToggle(true);
      setReservedTime(currentTime?.current);

      if (isPlaying) {
        setFirstTimeLoaded(false);
        setIsListening(true);
        setIsWatching(false);
        setPlayTriggered(true);
        setShowButtons(false);

        if (player.current) {
          if (isMediaReady) {
            player.current.play();
            if (setPlayIcon) setPlayIcon(true);
          } else {
            player.current.addEventListener(
              "canplay",
              () => player.current.play(),
              { once: true }
            );
          }
        }
      }
    } else {
      setListenWatchToggle(true);
      setReservedTime(currentTime?.current);
      if (isPlaying) {
        setFirstTimeLoaded(false);
        setIsListening(false);
        setIsWatching(true);
        setPlayTriggered(true);
        setShowButtons(false);

        if (player.current) {
          if (isMediaReady) {
            player.current.play();
            if (setPlayIcon) setPlayIcon(true);
          } else {
            player.current.addEventListener(
              "canplay",
              () => player.current.play(),
              { once: true }
            );
          }
        }
      }
    }
  }, [drivingMode]);

  // sticky player on tablet

  useEffect(() => {
    const handleScroll = () => {
      if (
        (!isPlaying && firstTimeLoaded) ||
        !player.current ||
        !isTabletOnly ||
        useCustomPlayer ||
        drivingMode ||
        isListening
      ) {
        return;
      }

      const sticky = player.current.offsetTop;
      const offset = 200;

      if (window.scrollY > sticky + offset) {
        setStickyPlayer(true);
      } else {
        setStickyPlayer(false);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isPlaying, isTabletOnly, isListening, drivingMode]);

  useEffect(() => {
    // console.log("isPlaying", isPlaying);
  }, [isPlaying]);
  //

  // render popup
  const renderPopupComponent = (data) => (
    <Box
      width="275px"
      bgcolor="rgba(2, 102, 112, 0.8)"
      color="#fff"
      borderRadius={3}
      overflow="visible"
      position="relative"
      height="auto"
    >
      <Card
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "hidden",
          borderRadius: "3 3 0 0",
        }}
      >
        {data?.image && (
          <CardMedia
            component="img"
            height="140"
            image={data.image}
            alt={data.description}
            sx={{
              width: "100%",
              objectFit: "cover",
              borderRadius: "inherit",
            }}
          />
        )}
        <CardContent
          sx={{ padding: "8px 16px", textAlign: "left", overflow: "hidden" }}
        >
          <div>
            {data?.go_to_summary && (
              <Button
                size="small"
                target="_blank"
                sx={{
                  backgroundColor: "#fce181",
                  color: "#026670",
                  padding: "3px 10px",
                  position: "absolute",
                  top: data?.image ? 128 : -10,
                  right: 20,
                  overflow: "hidden",
                  "&:hover": {
                    backgroundColor: "#fce181",
                    color: "#026670",
                  },
                }}
              >
                Course Resources
              </Button>
            )}
            {data?.description && (
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 400,
                  marginTop: "15px",
                  padding: "0 10px",
                }}
              >
                {data.description}
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
    </Box>
  );
  // loading effect testing
  // const [isLoadingMedia, setIsLoadingMedia] = useState(false);
  // const lastTimeRef = useRef(0);
  // const loadingTimerRef = useRef(null);
  // useEffect(() => {
  //   if (isPlaying) {
  //     lastTimeRef.current = currentTime;
  //     loadingTimerRef.current = setInterval(() => {
  //       if (currentTime === lastTimeRef.current) {
  //         setIsLoadingMedia(true);
  //       } else {
  //         setIsLoadingMedia(false);
  //         lastTimeRef.current = currentTime;
  //       }
  //     }, 1000);
  //     return () => {
  //       if (loadingTimerRef.current) {
  //         clearInterval(loadingTimerRef.current);
  //         loadingTimerRef.current = null;
  //       }
  //     };
  //   } else {
  //     setIsLoadingMedia(false);
  //     if (loadingTimerRef.current) {
  //       clearInterval(loadingTimerRef.current);
  //       loadingTimerRef.current = null;
  //     }
  //   }
  // }, [isPlaying, currentTime]);
  // useEffect(() => {
  //   console.log('fullscreen changed', fullscreen);
  // }, [fullscreen]);

  return (
    <>
      <Box
        position="relative"
        sx={{
          ...(shouldRenderContent &&
            !useCustomPlayer && {
              "&::before": {
                content: { xs: "none", md: '""' },
                position: "absolute",
                bottom: !modalScreen ? pxToRem(-40) : "auto",
                right: !modalScreen ? pxToRem(-40) : "auto",
                top: modalScreen ? pxToRem(-25) : "auto",
                left: modalScreen ? pxToRem(-25) : "auto",
                width: "46.6%",
                height: "59.7%",
                bgcolor: "#FCE181",
                borderRadius: pxToRem(20),
                zIndex: modalScreen ? "0" : "-1",
              },
            }),
          ...(modalScreen && {}),
        }}
      >
        <Box
          ref={playerWrapper}
          className="player-bgr"
          // width="100%"
          width={stickyPlayer ? "100%" : "100%"}
          margin={stickyPlayer ? "0 auto" : "0"}
          height={mobile_ssm && noListen ? "100%" : "100%"}
          position="relative"
          display="flex"
          borderRadius={useCustomPlayer ? pxToRem(23) : { md: pxToRem(23) }}
          pb={
            useCustomPlayer
              ? undefined
              : stickyPlayer
              ? "56%"
              : drivingMode && !mobile_ssm
              ? "56.2%"
              : useCustomPlayer && mobile_ssm
              ? "41%"
              : !drivingMode && shouldRenderContent && !pausedOrExpired
              ? "56.2%"
              : pausedOrExpired && mobile_ssm
              ? "50.2%"
              : pausedOrExpired
              ? "56.2%"
              : undefined
          }
          sx={{
            backgroundColor: isPreview
              ? "#000000"
              : stickyPlayer
              ? "transparent"
              : useCustomPlayer
              ? "#000000"
              : shouldRenderContent && !noListen
              ? "#000000"
              : "transparent",
            "&:hover": {
              "& .toggle-tooltip": {
                opacity: 1,
              },
            },
          }}
        >
          {shouldRenderContent ? (
            <Box
              pt={!modalScreen ? { xs: "60px", sssm: "65px", ssm: 0 } : 0}
              sx={{ touchAction: "pan-y", WebkitOverflowScrolling: "touch" }}
            >
              <MediaPlayer
                playsinline
                style={{
                  ...style,
                  width: stickyPlayer ? "100%" : "100%",
                  position: stickyPlayer ? "fixed" : undefined,
                  top: stickyPlayer ? 0 : tablet ? 0 : "auto",
                  bottom: useCustomPlayer
                    ? "auto"
                    : tablet
                    ? "auto"
                    : isFixed && !useCustomPlayer
                    ? y * -1
                    : 0,
                  right: stickyPlayer
                    ? "50%"
                    : isFixed && !tablet && !useCustomPlayer
                    ? x * -1
                    : 0,
                }}
                {...listeners}
                {...attributes}
                ref={(node) => {
                  setNodeRef(node);
                  player.current = node;
                }}
                className={(
                  (drivingMode && mobile_ssm ? "driving-mode-player " : "") +
                  (isFixed && !tablet && !useCustomPlayer ? "pip " : "") +
                  (useParshaPlayer
                    ? "parsha-player"
                    : useCustomPlayer
                    ? "custom-player"
                    : "")
                ).trim()}
                src={renderedUrl}
                aspectRatio={16 / 9}
                crossorigin=""
                onPause={onPause}
                onPlay={onPlay}
                onProviderSetup={onProviderSetup}
                onEnded={() => {
                  setVideoEnded(true);
                  const overlayElement = document.querySelector(".end-overlay");
                  if (overlayElement) {
                    overlayElement.style.display = "flex";
                  }
                }}
                onTimeUpdate={(event) => {
                  const current = event.detail.currentTime;
                  currentTimeRef.current = current;
                  currentTime.current = current;


                  if (
                    progressRestriction &&
                    selectedLesson &&
                    current % 1 !== 0
                  ) {
                    if (
                      current > productionMode ? selectedLesson?.length : 629
                    ) {
                      return;
                    }

                    setMaxWatchedTime((prev) =>
                      current > prev ? current : prev
                    );
                  }
                }}
                onSeeking={(event) => {
                  const currentTime = player.current.currentTime;
                  const seekTime = event.detail;
                  currentTimeRef.current = currentTime;
                  currentTime.current = currentTime;


                  if (
                    progressRestriction &&
                    selectedLesson &&
                    seekTime > effectiveMax
                  ) {
                    event.preventDefault();
                    player.current.currentTime = effectiveMax;
                  }
                }}
                autoplay={firstTimeLoaded ? false : true}
                onCanPlay={() => {
                  if (bookmarkStartTime) {
                    player.current.currentTime = bookmarkStartTime;
                  } else if (startTime && !listenWatchToggle) {
                    player.current.currentTime = startTime;
                  } else if (reservedTime && listenWatchToggle) {
                    player.current.currentTime = reservedTime;
                    setListenWatchToggle(false);
                    setReservedTime(null);
                  }
                  setIsMediaReady(true);
                }}
              >
                {!drivingMode && tablet && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                      touchAction: "pan-y",
                      zIndex: "9000",
                    }}
                  ></Box>
                )}
                {stickyPlayer && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "110%",
                      backgroundColor: "#f7f6f2",
                    }}
                  ></Box>
                )}

                {/* // loading effect testing  */}
                {/* {isLoadingMedia && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 9999, 
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", 
                        borderRadius: "50%", 
                        width: "100px", 
                        height: "100px", 
                      }}
                    >
                      <CircularProgress
                        size={60} 
                        thickness={5} 
                        color="secondary" 
                      />
                    </Box>
                  )} */}
                {/* // loading effect testing */}

                {isListening && (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#00383E",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "inherit",
                    }}
                  >
                    <img
                      src={AudioImg}
                      alt="Audio thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    />
                  </Box>
                )}
                {/* thumbnail in homepage // course page // !driving mode */}

                {showButtons &&
                  firstTimeLoaded &&
                  !isListening &&
                  !useCustomPlayer && (
                    <Box
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: lessonLockedAndSelected
                          ? "#026670"
                          : "black",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: useCustomPlayer ? "18px" : "inherit",
                        zIndex: 11,
                      }}
                    >
                      {!lessonLockedAndSelected && (
                        <img
                          src={thumbnailImg}
                          alt="Audio thumbnail"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            borderRadius: "inherit",
                          }}
                        />
                      )}
                    </Box>
                  )}
                {/* disable click */}
                <MediaOutlet>
                  {!tablet && (
                    <MediaGesture event="pointerup" action="toggle:paused" />
                  )}
                  <MediaPoster alt="" />
                </MediaOutlet>

                {!useCustomPlayer && !drivingMode && (
                  <Box className="pip-overlay">
                    <IconButton
                      className="pip-close"
                      onClick={() => setIsFixed(false)}
                    >
                      <Close
                        color="#fff"
                        width={pxToRem(10)}
                        height={pxToRem(10)}
                      />
                    </IconButton>
                    <Button
                      className="pip-back-btn"
                      endIcon={
                        <NewWindow width={pxToRem(20)} height={pxToRem(20)} />
                      }
                      onClick={() => {
                        window.scrollTo({
                          top: scrollTargetPosition - 40,
                          behavior: "smooth",
                        });
                      }}
                      sx={{
                        backgroundColor: "#000",
                        color: "#fff",
                        fontSize: pxToRem(12),
                        fontWeight: 400,
                        p: `${pxToRem(2)} ${pxToRem(8)} ${pxToRem(2)} ${pxToRem(
                          12
                        )}`,
                        "&:hover": {
                          color: "#FCE181",
                          backgroundColor: "#000",
                        },
                      }}
                    >
                      Back to tab
                    </Button>
                    <MediaPlayButton />
                  </Box>
                )}

                {fullscreen ? (
                  <Box
                    className="media-controls-group"
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                  >
                    <Box
                      width="100%"
                      position="absolute"
                      top={0}
                      height={pxToRem(68)}
                      pointerEvents="none"
                      pb={pxToRem(160)}
                      zIndex={25}
                      sx={{
                        background:
                          "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEmCAYAAACjy/qzAAAAhklEQVQ4y52RUQ6AMAxCKd7/JJ7R6aeJAdr507TjQZqOAE4CWARwx7JE944rch/k6qOWS7bq5bh72zGF8+LTa6goJeRPgXDYNxWFfkS0QXCInLIN1GxTXA0dtS0otWJnuXJR1Y9WYP9GF1UCYRQYHRSlXMARbVO4th3sd7Y3OP5dY3Bn+SkPsGJ1+HGGVtcAAAAASUVORK5CYII=)",
                        backgroundRepeat: "repeat-x",
                        backgroundPosition: "top",
                      }}
                    ></Box>
                    <Box
                      width="100%"
                      position="absolute"
                      left={pxToRem(18)}
                      top={pxToRem(18)}
                      pr={pxToRem(145)}
                      zIndex={26}
                    >
                      <Typography
                        color="#fff"
                        fontSize={pxToRem(27)}
                        sx={{
                          display: "block",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          wordWrap: "normal",
                          cursor: "default",
                          textShadow: "0 0 2px rgba(0,0,0,.5)",
                        }}
                      >
                        {selectedLesson ? selectedLesson.name : preview.name}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}

                {/* /////???? */}
                <Box
                  position="absolute"
                  top="40%"
                  left="50%"
                  display="flex"
                  sx={{
                    transform: "translate(-50%)",
                    pointerEvents: "none",
                  }}
                >
                  {playTriggered ? (
                    <div className="animWrapper">
                      <div className="play" />
                    </div>
                  ) : null}
                  {pauseTriggered ? (
                    <div className="animWrapper">
                      <div className="pause" />
                    </div>
                  ) : null}
                </Box>
                {/* /////???? */}

                {drivingMode ? (
                  <>
                    <Stack
                      position="absolute"
                      top="50%"
                      left="50%"
                      sx={{
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <MediaPlayButton>
                        <Box
                          sx={{
                            position: "relative",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            ":hover svg": { color: "#026670" },
                            transition: "transform 0.3s ease-in",
                            "::before": {
                              content: '""',
                              position: "absolute",
                              backgroundColor: "#FFFFFF80",
                              boxShadow: "0px 10px 20px #0000001A",
                              borderRadius: "50%",

                              width: { xs: "50px", ssm: "90px" },
                              height: { xs: "50px", ssm: "90px" },
                              backdropFilter: "blur(10px)",
                              filter: "brightness(2.5)",
                              zIndex: 0,
                            },
                            "::after": {
                              content: '""',
                              zIndex: -1,
                              position: "absolute",
                              borderRadius: "50%",
                              animation: "pulse 3s ease-out infinite",

                              width: { xs: "70px", ssm: "130px" },
                              height: { xs: "70px", ssm: "130px" },
                              backgroundColor: "#FFFFFF80",
                              backdropFilter: "blur(10px)",
                              "@keyframes pulse": {
                                "0%": {
                                  transform: "scaleX(1)",
                                  WebkitTransform: "scaleX(1)",
                                },
                                "50%": {
                                  transform: "scale3d(1.05, 1.05, 1.05)",
                                  WebkitTransform: "scale3d(1.05, 1.05, 1.05)",
                                },
                                "100%": {
                                  transform: "scaleX(1)",
                                  WebkitTransform: "scaleX(1)",
                                },
                              },
                            },
                          }}
                        >
                          <Box
                            component={Play}
                            sx={{
                              position: "relative",
                              right: { xs: "-2px", ssm: "-2px" },
                              color: "#026670",
                              display: { ssm: "none" },
                              width: {
                                xs: pxToRem(24),
                                ssm: pxToRem(32),
                                md: pxToRem(32),
                              },
                              height: {
                                xs: pxToRem(29),
                                ssm: pxToRem(38),
                                md: pxToRem(38),
                              },
                              zIndex: 1,
                            }}
                            slot="play"
                          />
                          <Box
                            component={Pause}
                            sx={{
                              position: "relative",
                              right: { xs: "-2px", ssm: "-2px" },
                              color: "#026670",
                              display: { ssm: "none" },
                              width: { xs: pxToRem(24), ssm: pxToRem(32) },
                              height: { xs: pxToRem(29), ssm: pxToRem(38) },
                              zIndex: 1,
                            }}
                            slot="pause"
                          />
                        </Box>
                      </MediaPlayButton>
                    </Stack>
                  </>
                ) : (
                  <Stack
                    className="media-controls-group"
                    flexDirection="row"
                    columnGap="47px"
                    alignItems="center"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                      display: { ssm: "none" },
                      zIndex: 9999,
                    }}
                  >
                    <MediaSeekButton
                      seconds={-10}
                      style={{ width: "50px", height: "50px" }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "all 0.3s ease-in",
                          ":hover": {
                            "::before": {
                              filter: "brightness(50)",
                            },
                          },
                          "::before": {
                            content: '""',
                            position: "absolute",
                            backgroundColor: "#FFFFFF80",
                            boxShadow: "0px 10px 20px #0000001A",
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            backdropFilter: "blur(10px)",
                            filter: "brightness(2.5)",
                            zIndex: 0,
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            color: "#026670 !important",
                            fontSize: "9px",
                            fontWeight: 600,
                            zIndex: 1,
                          }}
                        >
                          10
                        </Typography>

                        <Box
                          component={Reverse}
                          sx={{
                            position: "relative",
                            left: "-1.5px",
                            display: {
                              xs: "block !important",
                              ssm: "none !important",
                            },
                            width: "32px",
                            height: "31px",
                            zIndex: 1,
                          }}
                          slot="reverse"
                        />
                      </Box>
                    </MediaSeekButton>
                    <MediaPlayButton>
                      <Box
                        sx={{
                          position: "relative",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          ":hover svg": { color: "#026670" },
                          transform: "all 0.3s ease-in",
                          "::before": {
                            content: '""',
                            position: "absolute",
                            backgroundColor: "#FFFFFF80",
                            boxShadow: "0px 10px 20px #0000001A",
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            backdropFilter: "blur(10px)",
                            filter: "brightness(2.5)",
                            zIndex: 0,
                          },
                          "::after": {
                            content: '""',
                            zIndex: -1,
                            position: "absolute",
                            borderRadius: "50%",
                            animation: "pulse 3s ease-out infinite",
                            width: "70px",
                            height: "70px",
                            backgroundColor: "#FFFFFF80",
                            backdropFilter: "blur(10px)",
                            "@keyframes pulse": {
                              "0%": {
                                transform: "scaleX(1)",
                                WebkitTransform: "scaleX(1)",
                              },
                              "50%": {
                                transform: "scale3d(1.05, 1.05, 1.05)",
                                WebkitTransform: "scale3d(1.05, 1.05, 1.05)",
                              },
                              "100%": {
                                transform: "scaleX(1)",
                                WebkitTransform: "scaleX(1)",
                              },
                            },
                          },
                        }}
                      >
                        <Box
                          component={Play}
                          sx={{
                            position: "relative",
                            right: "-1.5px",
                            color: "#026670",
                            display: { ssm: "none" },
                            width: "16px",
                            height: "19px",
                            zIndex: 1,
                          }}
                          slot="play"
                        />
                        <Box
                          component={Pause}
                          sx={{
                            position: "relative",
                            right: "-1.5px",
                            color: "#026670",
                            display: { ssm: "none" },
                            width: "16px",
                            height: "19px",
                            zIndex: 1,
                          }}
                          slot="pause"
                        />
                      </Box>
                    </MediaPlayButton>
                    <MediaSeekButton
                      seconds={10}
                      style={{ width: "50px", height: "50px" }}
                    >
                      <Box
                        sx={{
                          pointerEvents: canForward10 ? "auto" : "none",
                          cursor: canForward10 ? "pointer" : "not-allowed",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "all 0.3s ease-in",
                          ":hover": {
                            "::before": {
                              filter: "brightness(50)",
                            },
                          },
                          "::before": {
                            content: '""',
                            position: "absolute",
                            backgroundColor: "#FFFFFF80",
                            boxShadow: "0px 10px 20px #0000001A",
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            backdropFilter: "blur(10px)",
                            filter: "brightness(2.5)",
                            zIndex: 0,
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            color: "#026670 !important",
                            fontSize: "9px",
                            fontWeight: 600,
                            zIndex: 1,
                          }}
                        >
                          10
                        </Typography>

                        <Box
                          component={Forward}
                          sx={{
                            position: "relative",
                            right: "-1.5px",
                            display: {
                              xs: "block !important",
                              ssm: "none !important",
                            },
                            width: "32px",
                            height: "31px",
                            zIndex: 1,
                          }}
                          slot="forward"
                        />
                      </Box>
                    </MediaSeekButton>
                  </Stack>
                )}
                {lessonLockedAndSelected ? (
                  <Stack
                    className="play-btn"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap={tablet ? pxToRem(10) : pxToRem(20)}
                    width="100%"
                    height="100%"
                    position="absolute"
                    top="50%"
                    left="50%"
                    flexShrink={0}
                    sx={{
                      transform: "translate(-50%,-50%)",
                      "& media-play-button": { width: "auto" },
                      zIndex: 11,
                    }}
                  >
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: mobile_ssm ? "16px" : pxToRem(22),
                          color: "#fce181",
                          textAlign: "center",
                          fontWeight: 500,
                          padding: !mobile_ssm ? "0 20%" : "0",
                          // textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                          marginBottom: mobile_ssm ? pxToRem(22) : "0",
                        }}
                      >
                        <>
                          {mobile_ssm ? (
                            <>
                              Please wait{" "}
                              <Typography
                                component="span"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: pxToRem(20),
                                  // textShadow:
                                  //   "2px 2px 4px rgba(0, 0, 0, 0.4)",
                                }}
                              >
                                {preview?.next_lesson_availability}-hour
                              </Typography>
                              {preview?.next_lesson_availability === 1
                                ? ""
                                : "s"}{" "}
                              before the next lecture.
                            </>
                          ) : (
                            <>
                              To allow time for material to be fully absorbed,
                              there is a{" "}
                              <Typography
                                component="span"
                                sx={{
                                  fontWeight: 700,
                                  fontSize: pxToRem(20),
                                  // textShadow:
                                  //   "2px 2px 4px rgba(0, 0, 0, 0.4)",
                                }}
                              >
                                {preview?.next_lesson_availability}-hour
                                {preview?.next_lesson_availability === 1
                                  ? ""
                                  : "s"}{" "}
                                interval
                              </Typography>{" "}
                              before this lecture becomes available.
                            </>
                          )}
                        </>
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          marginTop: mobile_ssm ? "0" : "20px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            height: { xs: "44px", ssm: pxToRem(54) },
                            px: { xs: pxToRem(30), ssm: pxToRem(40) },
                            minWidth: "250px",
                            border: "1px solid #FCE181",
                            color: "#FCE181",
                            backgroundColor: "transparent",
                            ":hover": {
                              border: "1px solid #FCE181",
                              backgroundColor: "#FCE181",
                              color: "#026670",
                            },
                            "&.Mui-disabled": {
                              border: "1px solid #333333",
                              color: "#333333",
                              backgroundColor: "#edece8",
                            },
                          }}
                          onClick={() => {
                            handleRemindMeClick(nextLessonTime);
                          }}
                          disabled={disableReminder}
                        >
                          <NotificationsIcon sx={{ marginRight: "10px" }} />
                          Remind Me in {timeLeft}
                        </Button>
                      </Box>
                    </Stack>
                    <Stack flexDirection="row" gap={pxToRem(20)}>
                      <Button
                        variant="yellow"
                        startIcon={<Replay />}
                        sx={{
                          height: { xs: "44px", ssm: pxToRem(54) },
                          maxWidth: "250px",
                          px: { xs: pxToRem(30), ssm: pxToRem(40) },
                          "&:hover svg": { color: "#026670" },
                          "& svg": {
                            transform: "scale(1.2)",
                          },
                        }}
                        onClick={handlePlayPreviousLecture}
                      >
                        Replay Previous Lecture
                      </Button>
                    </Stack>
                  </Stack>
                ) : (
                  showButtons &&
                  firstTimeLoaded &&
                  !useCustomPlayer && (
                    <Stack
                      className="play-btn"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      gap={pxToRem(20)}
                      width="100%"
                      height="100%"
                      position="absolute"
                      top="50%"
                      left="50%"
                      flexShrink={0}
                      sx={{
                        transform: "translate(-50%,-50%)",
                        "& media-play-button": { width: "auto" },
                        zIndex: 9999,
                      }}
                    >
                      {!drivingMode && (
                        <MediaPlayButton>
                          {useCustomPlayer ? (
                            <PlayBtn
                              width={{ xs: "60px", sm: pxToRem(120) }}
                              height={{ xs: "60px", sm: pxToRem(120) }}
                              onClick={handleWatchClicked}
                              style={{ cursor: "pointer" }}
                            >
                              <Play
                                color="#026670"
                                width={pxToRem(20)}
                                height={pxToRem(24)}
                                style={{ marginLeft: "6%", cursor: "pointer" }}
                                onClick={handleWatchClicked}
                              />
                            </PlayBtn>
                          ) : (
                            <>
                              {!startsPlaying && (
                                <Button
                                  variant="yellow"
                                  startIcon={<CamcorderIcon />}
                                  sx={{
                                    height: { xs: "44px", ssm: pxToRem(54) },
                                    px: { xs: pxToRem(30), ssm: pxToRem(40) },
                                    "&:hover svg": { color: "#026670" },
                                    "& svg": {
                                      transform: "scale(0.9)",
                                    },
                                  }}
                                  onClick={handleWatchClicked}
                                >
                                  {!noListen ? "Watch" : "Watch Preview"}
                                </Button>
                              )}
                            </>
                          )}
                        </MediaPlayButton>
                      )}

                      {!noListen && !useCustomPlayer && (
                        <MediaPlayButton>
                          <Button
                            variant="yellow"
                            startIcon={<Volume />}
                            sx={{
                              height: { xs: "44px", ssm: pxToRem(54) },
                              px: { xs: pxToRem(30), ssm: pxToRem(40) },
                              "&:hover svg": { color: "#026670" },
                            }}
                            onClick={handleListenClicked}
                          >
                            Listen
                          </Button>
                        </MediaPlayButton>
                      )}
                    </Stack>
                  )
                )}

                {useParshaPlayer && (
                  <TopPanel
                    selectedLesson={selectedLesson}
                    currentTime={currentTime}
                    setToastOpen={setToastOpen}
                    isListening={isListening}
                    isWatching={isWatching}
                    handleListenClicked={handleListenClicked}
                    setIsListening={setIsListening}
                    handleWatchClicked={handleWatchClicked}
                    setBookmarkToastOpen={setBookmarkToastOpen}
                    setBookmarkMessage={setBookmarkMessage}
                    setBookmarkListChanged={setBookmarkListChanged}
                    setNoteListChanged={setNoteListChanged}
                    setNoteToastOpen={setNoteToastOpen}
                    setListenWatchToggle={setListenWatchToggle}
                    setReservedTime={setReservedTime}
                    fullscreen={fullscreen}
                  />
                )}

                {selectedLesson && !drivingMode && (
                  <SidePanel
                    selectedLesson={selectedLesson}
                    currentTime={currentTime}
                    setToastOpen={setToastOpen}
                    isListening={isListening}
                    isWatching={isWatching}
                    handleListenClicked={handleListenClicked}
                    setIsListening={setIsListening}
                    handleWatchClicked={handleWatchClicked}
                    setBookmarkToastOpen={setBookmarkToastOpen}
                    setBookmarkMessage={setBookmarkMessage}
                    setBookmarkListChanged={setBookmarkListChanged}
                    setNoteListChanged={setNoteListChanged}
                    setNoteToastOpen={setNoteToastOpen}
                    setListenWatchToggle={setListenWatchToggle}
                    setReservedTime={setReservedTime}
                    setBookmarkBgColor={setBookmarkBgColor}
                  />
                )}

                <Stack
                  className="media-controls-group"
                  height={
                    useParshaPlayer ? pxToRem(40) : pxToRem(mediaControlsHeight)
                  }
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={
                    useParshaPlayer
                      ? pxToRem(10)
                      : { xs: pxToRem(13), ssm: pxToRem(15), sm: pxToRem(40) }
                  }
                  position="absolute"
                  bottom={
                    useParshaPlayer
                      ? pxToRem(10)
                      : {
                          xs: 0,
                          sssm: pxToRem(10),
                          ssm: pxToRem(30),
                          sm: pxToRem(40),
                        }
                  }
                  left={useParshaPlayer ? pxToRem(10) : pxToRem(40)}
                  right={useParshaPlayer ? pxToRem(10) : pxToRem(40)}
                  borderRadius={pxToRem(32)}
                  boxShadow={{
                    ssm: `0px ${pxToRem(10)} ${pxToRem(20)} rgba(0,0,0,0.16)`,
                  }}
                  backgroundColor={{ ssm: "rgba(255, 255, 255, 0.5)" }}
                  px={
                    useParshaPlayer
                      ? pxToRem(20)
                      : { xs: 0, ssm: pxToRem(20), sm: pxToRem(40) }
                  }
                  sx={{
                    backdropFilter: { xs: "unset", ssm: "blur(15px)" },
                    zIndex: 9999,
                  }}
                >
                  {drivingMode ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: pxToRem(12),
                          fontWeight: "bold",
                          color: { xs: "#FCE181", ssm: "#026670" },
                          display: { xs: "none", ssm: "flex" },
                          gap: pxToRem(4),
                          "& span": {
                            fontFamily: "Roboto Mono",
                          },
                        }}
                      >
                        <MediaTime type="current" />
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          marginTop: mobile_ssm ? "200px" : "0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          marginBottom: !mobile_ssm ? "0px" : "20px",
                        }}
                      >
                        {selectedLesson && progressRestriction ? (
                          <Box sx={{ width: "100%" }}>
                            <CustomTimeSlider
                              maxWatchedTime={effectiveMax}
                              duration={
                                productionMode ? selectedLesson?.length : 629
                              }
                            />
                          </Box>
                        ) : (
                          <MediaTimeSlider>
                            <div slot="preview">
                              <MediaSliderValue
                                focused={true}
                                type="pointer"
                                format="time"
                              />
                            </div>
                          </MediaTimeSlider>
                        )}

                        <Box
                          sx={{
                            display: !mobile_ssm ? "none" : "flex",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: pxToRem(20),
                              fontWeight: "bold",
                              color: { xs: "#026670", ssm: "#026670" },
                              display: { xs: "flex", ssm: "flex" },
                              gap: pxToRem(4),
                              "& span": {
                                fontFamily: "Roboto Mono",
                                fontSize: pxToRem(20),
                                display: "flex",
                                alignItems: "center",
                                letterSpacing: "-0.36px",
                              },
                            }}
                          >
                            <span>
                              <MediaTime type="current" />
                            </span>

                            <span>/</span>

                            <span>
                              <MediaTime type="duration" />
                            </span>
                          </Typography>
                        </Box>
                      </Box>

                      {/* forward */}

                      <Typography
                        sx={{
                          fontSize: pxToRem(12),
                          fontWeight: "bold",
                          color: { xs: "#FCE181", ssm: "#026670" },
                          display: { xs: "none", ssm: "flex" },
                          gap: pxToRem(4),
                          "& span": {
                            fontFamily: "Roboto Mono",
                          },
                        }}
                      >
                        <MediaTime type="duration" />
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          fontSize: pxToRem(12),
                          fontWeight: "bold",
                          color: { xs: "#FCE181", ssm: "#026670" },
                          display: { xs: "flex", ssm: "none" },
                          gap: pxToRem(4),
                          "& span": {
                            fontFamily: "Roboto Mono",
                          },
                        }}
                      >
                        <MediaTime type="current" /> <span>/</span>{" "}
                        <MediaTime type="duration" />
                      </Typography>

                      <Stack
                        className="hide-in-mobile"
                        flexDirection="row"
                        alignItems="center"
                        gap={{
                          xs: pxToRem(17),
                          ssm: pxToRem(10),
                          sm: pxToRem(17),
                        }}
                      >
                        {!useParshaPlayer && (
                          <MediaSeekButton seconds={-10}>
                            <MediaTooltip position="top center">
                              <span>Seek -10s</span>
                            </MediaTooltip>
                            <Typography
                              fontSize={pxToRem(12)}
                              fontWeight="bold"
                              color="secondary"
                            >
                              - 10 sec
                            </Typography>
                          </MediaSeekButton>
                        )}

                        <MediaPlayButton>
                          <MediaTooltip position="top center">
                            <span slot="play">Play</span>
                            <span slot="pause">Pause</span>
                            <span slot="replay">Replay</span>
                          </MediaTooltip>
                          <Play slot="play" color="#026670" />
                          <Pause slot="pause" color="#026670" />
                          <Play slot="replay" color="#026670" />
                        </MediaPlayButton>
                        {!useParshaPlayer && (
                          <MediaSeekButton
                            seconds={+10}
                            style={{
                              pointerEvents: canForward10 ? "auto" : "none",
                              opacity: canForward10 ? 1 : 0.5,
                              cursor: canForward10 ? "pointer" : "not-allowed",
                            }}
                          >
                            <MediaTooltip position="top center">
                              <span>Seek +10s</span>
                            </MediaTooltip>
                            <Typography
                              fontSize={pxToRem(12)}
                              fontWeight="bold"
                              color="secondary"
                            >
                              + 10 sec
                            </Typography>
                          </MediaSeekButton>
                        )}
                      </Stack>
                      {selectedLesson && progressRestriction ? (
                        <Box sx={{ width: "100%" }}>
                          <CustomTimeSlider
                            maxWatchedTime={effectiveMax}
                            duration={
                              productionMode ? selectedLesson?.length : 629
                            }
                          />
                        </Box>
                      ) : (
                        <MediaTimeSlider>
                          <div slot="preview">
                            <MediaSliderValue type="pointer" format="time" />
                          </div>
                        </MediaTimeSlider>
                      )}
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        flexShrink={0}
                      >
                        <Typography
                          sx={{
                            fontSize: pxToRem(12),
                            fontWeight: "bold",
                            color: { xs: "#FCE181", ssm: "#026670" },
                            display: { xs: "none", ssm: "flex" },
                            gap: pxToRem(4),
                            marginRight: useParshaPlayer
                              ? pxToRem(10)
                              : { ssm: pxToRem(20), sm: pxToRem(30) },
                            "& span": {
                              fontFamily: "Roboto Mono",
                            },
                          }}
                        >
                          <MediaTime type="current" /> <span>/</span>{" "}
                          <MediaTime type="duration" />
                        </Typography>
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gap={{
                            xs: pxToRem(20),
                            ssm: pxToRem(17),
                            sm: pxToRem(20),
                          }}
                        >
                          {!useParshaPlayer && (
                            <IconButtonStyled
                              className="hide-in-mobile"
                              sx={{
                                "& svg": {
                                  width: pxToRem(22),
                                },
                                "&:hover": {
                                  "& svg": {
                                    display: "none",
                                  },
                                  "& media-volume-slider": {
                                    display: "block",
                                  },
                                },
                              }}
                            >
                              <Volume color="#026670" />
                              <MediaVolumeSlider>
                                <MediaSliderValue
                                  type="pointer"
                                  format="percent"
                                  slot="preview"
                                />
                              </MediaVolumeSlider>
                            </IconButtonStyled>
                          )}
                          <Box
                            component={Setting}
                            cursor="pointer"
                            sx={{
                              color: {
                                xs: "#FCE181",
                                ssm: "#026670",
                              },
                              display: { ssm: "none" },
                              width: pxToRem(18.5),
                              ":hover": { color: "#026670" },
                            }}
                            slot="exit"
                          />
                          <MediaFullscreenButton>
                            <MediaTooltip position="top center">
                              <span slot="enter">
                                {useParshaPlayer
                                  ? "Fullscreen"
                                  : "Enter Fullscreen"}
                              </span>
                              <span slot="exit">Exit Fullscreen</span>
                            </MediaTooltip>
                            <Box
                              component={Fullscreen}
                              sx={{
                                color: {
                                  xs: "#FCE181",
                                  ssm: "#026670",
                                },
                                ":hover": { color: "#026670" },
                              }}
                              slot="enter"
                            />
                            <Box
                              component={FullscreenExit}
                              sx={{
                                color: {
                                  xs: "#FCE181",
                                  ssm: "#026670",
                                },
                                ":hover": { color: "#026670" },
                              }}
                              slot="exit"
                            />
                          </MediaFullscreenButton>

                          {!useParshaPlayer && (
                            <MediaMenu className="hide-in-mobile">
                              <MediaMenuButton aria-label="Settings">
                                <Settings color="#026670" />
                                <MediaTooltip>
                                  <span slot="open">Open Settings</span>
                                  <span slot="close">Close Settings</span>
                                </MediaTooltip>
                              </MediaMenuButton>
                              <MediaMenuItems>
                                <MediaMenu>
                                  <MediaPlaybackRateMenuButton label="Speed"></MediaPlaybackRateMenuButton>
                                  <MediaPlaybackRateMenuItems
                                    rates={[
                                      0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2,
                                    ]}
                                    normalLabel="Normal"
                                  />
                                </MediaMenu>
                                <MediaMenu>
                                  <MediaQualityMenuButton label="Quality"></MediaQualityMenuButton>
                                  <MediaQualityMenuItems autoLabel="Auto" />
                                </MediaMenu>
                              </MediaMenuItems>
                            </MediaMenu>
                          )}
                        </Stack>
                      </Stack>
                    </>
                  )}
                </Stack>
                {!useCustomPlayer && (
                  <EndView
                    isPreview={selectedLesson == undefined}
                    isSubscribed={preview.subscription_status === "Active"}
                    content={selectedLesson ? selectedLesson : preview}
                    setPlayIcon={setPlayIcon || (() => {})}
                    setPlayTriggered={setPlayTriggered}
                    setNextLecture={setNextLecture}
                    nextLecture={nextLecture}
                    lastLesson={lastLesson}
                    setIsListening={setIsListening}
                    setIsFixed={setIsFixed}
                    isFixed={isFixed}
                    handleNextLectureClick={handleNextLectureClick}
                    lessons={lessons}
                    selectedLesson={selectedLesson}
                    videoEnded={videoEnded}
                    setVideoEnded={setVideoEnded}
                    hoursRestriction={preview?.next_lesson_availability}
                    setBlockAutoLectureId={setBlockAutoLectureId}
                    setRemindMeAt={setRemindMeAt}
                  />
                )}
              </MediaPlayer>
            </Box>
          ) : (
            <Skeleton
              variant="rectangular"
              width="100%"
              sx={{
                borderRadius: useParshaPlayer
                  ? "1.25rem 1.25rem 0 0"
                  : { md: pxToRem(20) },
                height: "0",
                paddingBottom: "50%",
              }}
            />
          )}

          {openPopup && (
            <div
              style={{
                width: "85%",
                position: "absolute",
                display: "flex",
                margin: "auto",
                top: "50%",
                left: "25%",
                transform: "translate(-25%,-50%)",
                padding: "20px 0",
              }}
            >
              {openPopup &&
                popupData.map((data, index) => (
                  <div
                    key={index}
                    style={{ padding: "10px 0", marginRight: "40px" }}
                  >
                    {data.go_to_summary ? (
                      <div
                        onClick={openResourcesTab}
                        style={{
                          cursor: "pointer",
                          zIndex: 10,
                        }}
                      >
                        {renderPopupComponent(data)}
                      </div>
                    ) : data.link ? (
                      <MuiLink
                        href={data.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: "white",
                          textDecoration: "none",
                          marginTop: "5px",
                          zIndex: 10,
                        }}
                      >
                        {renderPopupComponent(data)}
                      </MuiLink>
                    ) : (
                      <div
                        style={{
                          cursor: "pointer",
                          zIndex: 10,
                        }}
                      >
                        {renderPopupComponent(data)}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </Box>
      </Box>
      {drivingMode && (
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DrivingModeSettings
              drivingModeSpeed={drivingModeSpeed}
              setDrivingModeSpeed={setDrivingModeSpeed}
              seekHandler={seekHandler}
              canForward10={canForward10}
            />
          </Box>
        </Box>
      )}
      <Toast
        open={remindMeAt}
        onClose={() => setRemindMeAt(false)}
        message="You will receive an email notification when the lecture becomes available."
      />
      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message="Link copied to clipboard!"
      />
      <Toast
        open={bookmarkToastOpen}
        onClose={() => {
          setBookmarkToastOpen(false);
          setBookmarkMessage("");
          setTimeout(() => {
            setBookmarkBgColor(null);
          }, 1000);
        }}
        message={bookmarkMessage}
        // backgroundColor={bookmarkBgColor}
      />
      <Toast
        open={noteToastOpen}
        onClose={() => setNoteToastOpen(false)}
        message="Note saved successfully!"
      />
    </>
  );
}
