import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import PresentationImg from "../../images/presentation-img.jpg";
import LinkWithIcon from "../../components/LinkWithIcon";
import { Stack, styled } from "@mui/system";
import GradientAnimation from "../../components/GradientAnimation";
import TreeImg from "../../images/footer-tree.svg";
import ReusableHeaderBox from "../../components/ReausibleHeaderBox";
import { useParams } from "react-router-dom";
import { getBlog } from "../../api/blogs";
import { getLanguageClass } from "../../utils/languageClass";
import ReactPlayer from "react-player";
import VideoPlayer from "../course/components/VideoPlayer";
const SingleBlog = () => {
  const Label = styled(Typography)(({ theme }) => {
    return {
      display: "flex",
      alignItems: "center",
      height: "20px",
      fontSize: "12px",
      fontWeight: 600,
      color: "#026670",
      backgroundColor: "#FFFFFF",
      // border: "2px solid #f5e093",
      borderRadius: "calc(infinity * 1px)",
      padding: "0 15px",
    };
  });
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    document.title = "Blog";
  }, []);
  const { id } = useParams();
  const [post, setPost] = useState({
    title: "",
    content: "",
    image: "",
    video: "",
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const handlePlayVideo = () => {
    if (post?.video) {
      setIsPlaying(true);
    }
  };
  useEffect(() => {
    getBlog(id).then((res) => {
      setPost(res.data);
    });
  }, [id]);
  console.log("isPlaying", isPlaying);
  return (
    <Box position="relative" overflow="hidden">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />

      <ReusableHeaderBox
        treeImg={TreeImg}
        gradientComponent={GradientAnimation}
        headerComponent={() => <Header menuVisible hideUserProfile />}
        guidesComponent={Guides}
      />

      <Container
        maxWidth="md"
        sx={{ px: 0, py: { xs: pxToRem(77), md: pxToRem(80) } }}
        disableGutters
      >
        <LinkWithIcon
          to="/blogs"
          className="rtl-section"
          sx={{ marginTop: pxToRem(14) }}
          style={{ display: tablet ? "none" : "" }}
        >
          All Blogs
        </LinkWithIcon>
        <Box sx={{ marginTop: pxToRem(40), marginBottom: pxToRem(20) }}>
          <Label
            sx={{
              height: "24px",
              border: "2px solid #f5e093",
              backgroundColor: "#F9EBB9",
              display: "inline-block",
              minWidth: pxToRem(122),
              textAlign: "center",
              fontSize: pxToRem(14),
            }}
          >
            Blog
          </Label>
        </Box>
        <Typography
          component="h2"
          sx={{
            fontSize: pxToRem(30),
            fontWeight: 700,
            lineHeight: pxToRem(40),
            marginBottom: pxToRem(20),
          }}
        >
          {post.title}
        </Typography>
        <Typography
          component="p"
          fontSize={pxToRem(16)}
          lineHeight={pxToRem(25)}
          fontWeight={600}
          color="#BFBEBB"
          marginBottom={pxToRem(90)}
        >
          {post?.author
            ? `${post.author.first_name} ${post.author.last_name}`
            : "Unknown Author"}{" "}
          •{" "}
          {post?.published_at
            ? new Date(post.published_at).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : "N/A"}
        </Typography>
        <Box
          paddingLeft={{ xs: "25px", sm: "30px", md: pxToRem(40) }}
          paddingRight={{ xs: "25px", sm: "30px", md: pxToRem(40) }}
        >
          <Box
            position="relative"
            // maxWidth={{ xs: "300px", sm: "310px", md: pxToRem(500) }}
            width="100%"
            // height={{ xs: "310px", md: "auto" }}
            display="flex"
            borderRadius={pxToRem(20)}
            // boxShadow={`${pxToRem(30)} ${pxToRem(30)} ${pxToRem(
            //   50
            // )} rgba(0,0,0,0.2)`}
            flexShrink={0}
            sx={{
              "&::before": {
                content: "''",
                position: "absolute",
                left: { xs: "-25px", sm: "-30px", md: pxToRem(-40) },
                top: { xs: "-30px", md: pxToRem(-40) },
                width: { xs: "200px", sm: "310px", md: "88%" },
                height: { xs: "310px", md: "74.1%" },
                bgcolor: "#FCE181",
                borderRadius: pxToRem(20),
                zIndex: -1,
              },
            }}
          >
            {isPlaying && post?.video ? (
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  aspectRatio: "16/9",
                  backgroundColor: "black",
                  borderRadius: "1.25rem 1.25rem 0 0",
                }}
              >
                <VideoPlayer
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  selectedLesson={{ video_url: post?.video, }}
                  thumbnailCustom={post?.thumbnail}
                  // customPosition={customPosition}
                  currentTime={currentTime}
                  setCurrentTime={setCurrentTime}
                  useCustomPlayer
                  useParshaPlayer
                  startsPlaying
                />
              </Box>
            ) : (
              <img
                src={post?.image ? post.image : post?.thumbnail}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: pxToRem(20),
                  cursor: post?.video ? "pointer" : "default",
                }}
                onClick={() => {
                  handlePlayVideo();
                }}
              />
            )}
          </Box>
        </Box>
        <Stack
          gap={pxToRem(30)}
          sx={{
            marginTop: pxToRem(60),
            paddingRight: { xs: "25px", sm: "30px", md: pxToRem(40) },
          }}
        >
          <Box
            className={`slideTitle ${getLanguageClass(post?.content)}`}
            sx={{
              fontSize: pxToRem(18),
              fontWeight: 500,
              lineHeight: pxToRem(30),
            }}
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
          />
        </Stack>
      </Container>
    </Box>
  );
};
export default SingleBlog;
