import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Stack, TextField, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { styled } from "@mui/system";
import Visa from "../../images/credit-cards/visa.svg";
import Amex from "../../images/credit-cards/amex.svg";
import Discover from "../../images/credit-cards/discover.svg";
import Mastercard from "../../images/credit-cards/mastercard.svg";
import Paypal from "../../images/credit-cards/paypal.svg";
import { getDonationSettings } from "../../api/cmsApi";
import CheckModal from "./CheckModal";

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit,minmax(100px,1fr))",
  gap: "10px",
  borderRadius: 0,
  marginBottom: "40px",
  marginTop: "20px",
  "& button": {
    border: "2px solid #026670 !important",
    borderRadius: "10px",
    height: "40px",
    width: "115px",
    fontSize: "12px",
    fontWeight: 900,
    color: "#026670",
    textTransform: "none",
    padding: "0 5px",
    whiteSpace: "nowrap",
    transition: "0.2s ease",
    "&:hover, &.Mui-selected, &.Mui-selected:hover": {
      borderColor: "#026670 !important",
      backgroundColor: "#026670",
      color: "white",
    },
    [theme.breakpoints.down("sm")]: {
      height: "40px",
    },
  },
}));


const TextFieldStyled = styled(TextField)(({ theme }) => ({
  width: "400px",
  height: "72px",
  borderRadius: "calc(infinity * 1px)",
  backgroundColor: "#EDECE8",
  justifyContent: "center",
  "& input": {
    fontSize: "40px",
    fontWeight: 700,
    color: "#026670",
    padding: "0 20px",
    textAlign: "center",
    "&::placeholder": {
      fontSize: "25px",
      fontWeight: 400,
      color: "#026670",
      opacity: 1,
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "300px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "48px",
    "& input": {
      fontSize: "25px",
      "&::placeholder": {
        fontSize: "25px",
      },
    },
  },
}));



const formatCurrency = (value) => {
  const numericValue = parseFloat(value) || 0;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(numericValue);
};

const DonationAmount = (
  {selectedAmount,
  setSelectedAmount,
  customAmount,
  setCustomAmount,}
) => {
  const [amounts, setAmounts] = useState([]);
  const [taxId, setTaxId] = useState("");
  const [checkInfo, setCheckInfo] = useState("");
  const [openCheckModal, setOpenCheckModal] = useState(false);

  const fetchDonationSettings = () => {
    getDonationSettings()
      .then((res) => {
        setAmounts([...res.data.amounts, "Other"]);
        setTaxId(res.data.tax_id);
        setCheckInfo(res.data.check_informations);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDonationSettings();
  }, []);

  const inputRef = useRef(null);

  const handleButtonClick = (amount) => {
    setSelectedAmount(amount);
    if (amount === "Other") {
      inputRef.current?.focus();
    }
    else {
      setCustomAmount(amount);
    }
  };

  const handleCheckClick = (event) => {
    event.preventDefault();
    setOpenCheckModal(true);
  };


  
  const handleCustomAmountChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9.]/g, "");

    const cursorPosition = event.target.selectionStart;

    setCustomAmount(inputValue);
    setSelectedAmount(inputValue);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);
  };

  const formatValueForDisplay = () => {
    return customAmount ? formatCurrency(customAmount) : "";
  };
  
  

  return (
    <Box
      borderBottom="1px solid rgba(191,190,187,0.5)"
      pb={{ xs: "50px", ssm: "80px" }}
    >
      <Typography
        variant="sectionTitle"
        component="h2"
        fontSize="36px"
        mb={{ xs: "36px", sm: "50px" }}
      >
        Choose a <span className="highlighted">donation amount</span>
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(auto-fit,minmax(130px,1fr))",
            ssm: "repeat(auto-fit,minmax(150px,1fr))",
          },
          gap: "8px",
          mb: { xs: "30px", ssm: "60px" },
        }}
      >
        {amounts &&
          amounts.map((amount) => (
            <Button
              key={amount}
              onClick={() => handleButtonClick(amount)}
              sx={{
                height: { xs: "40px", sm: "64px" },
                borderRadius: "calc(infinity * 1px)",
                backgroundColor:
                  selectedAmount === amount ? "#FCE181" : "transparent",
                border: "2px solid",
                borderColor: selectedAmount === amount ? "#FCE181" : "#026670",
                fontSize: { xs: "18px", sm: "25px" },
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#FCE181",
                  color: "#026670",
                  border:
                    selectedAmount === amount
                      ? "2px solid #FCE181"
                      : "2px solid #026670",
                },
              }}
            >
              {amount === "Other" ? "Other" : `${formatCurrency(amount)}`}
            </Button>
          ))}
      </Box>
      <Stack
        flexDirection={{ xs: "column-reverse", ssm: "row-reverse", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
        gap="25px"
      >
        <Box textAlign={{ xs: "center", ssm: "left" }}>
          {taxId && (
            <Typography fontWeight="500" mb="8px">
              All Donations Are Tax Deductible. <strong>Tax ID: {taxId}</strong>
            </Typography>
          )}
          <Stack
            flexDirection="row"
            justifyContent={{ xs: "center", ssm: "flex-start" }}
            flexWrap="wrap"
            gap={{ xs: "5px", ssm: "11px" }}
          >
            <img src={Visa} alt="" />
            <img src={Amex} alt="" />
            <img src={Discover} alt="" />
            <img src={Mastercard} alt="" />
            <img src={Paypal} alt="" />
          </Stack>
          <ToggleButtonGroupStyled
            sx={{
              justifyContent: { xs: "center", sm: "flex-start" },
              flexWrap: "wrap",
              gap: { xs: "5px", sm: "10px" },
              mb: { xs: "20px", sm: "40px" },
            }}
          >
            <ToggleButton
              onClick={handleCheckClick}
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "16px" },
                marginLeft: { xs: "100px", sm: "0" },
              }}
            >
              Pay by Check
            </ToggleButton>
          </ToggleButtonGroupStyled>

        </Box>

        <TextFieldStyled
          inputRef={inputRef}
          placeholder="Enter amount"
          value={formatValueForDisplay()}
          onChange={handleCustomAmountChange}
          onFocus={() => setCustomAmount(String(customAmount || "").replace(/[^0-9.]/g, ""))}
        />

      </Stack>
      <CheckModal
        open={openCheckModal}
        setOpen={setOpenCheckModal}
        onClose={() => setOpenCheckModal(false)}
        checkInfo={checkInfo} />
    </Box>
  );
};

export default DonationAmount;
