import {
    Dialog,
    DialogActions,
    DialogContent,
    Box,
    Button,
    TextField,
    Typography,
    Slide,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  
  import { forwardRef, useEffect, useState } from "react";
  
  import ModalHeader from "../../../components/ModalHeader";
  import { pxToRem } from "px2rem2px";
  import { styled } from "@mui/material/styles";
  import getErrorsArray from "../../../utils/getErrorsArray";
  import { editProfile, verifyResetEmailCode } from "../../../api/student";
  import { isValidEmail } from "../../../utils/validation";
  import Toast from "../../../components/Toast";
  
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const TextFieldStyled = styled(TextField)({
    width: "100%",
    "& .MuiInputBase-root": {
      borderRadius: pxToRem(30),
      backgroundColor: "#EDECE8",
      height: pxToRem(54),
    },
    "& input": {
      borderRadius: `${pxToRem(30)} !important`,
      padding: `${pxToRem(15)} ${pxToRem(40)}`,
      "&:focus": {
        backgroundColor: "#fff",
      },
    },
  });
  
  export default function ChangeEmailModal({ open, setOpen, oldEmail,email ,handleEndEditMode}) {
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openResent, setOpenResent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [code, setCode] = useState("");
    const [resendTimer, setResendTimer] = useState(0);
  
    const [touched, setTouched] = useState(false);
  
    useEffect(() => {
      console.log("resendTimer", resendTimer);
    }, [resendTimer]);
  
    
  
    useEffect(() => {
      let timer;
      if (resendTimer > 0) {
        timer = setInterval(() => {
          setResendTimer((prev) => prev - 1);
        }, 1000);
      }
      return () => clearInterval(timer);
    }, [resendTimer]);
  
    function resetForm() {
      setOpen(false);
      setCode("");
      setErrors([]);
      setResendTimer(0);
      setTouched(false)
    }
  
    function sendVerificationCode() {
      setLoading(true);
    
  
  
      if (isValidEmail(email)) {
        if (email === oldEmail) {
          setErrors([
            "New email address cannot be the same as the old email address.",
          ]);
          setLoading(false);
          return;
        }
  
  
        editProfile({ user: { email } })
          .then((res) => {
            if (res.status !== 202 && res.status !== 200) {
              throw new Error(res.data);
            }
            setErrors([]);
            setLoading(false);
            setResendTimer(60);
          })
          .catch((error) => {
            setLoading(false);
            setErrors(getErrorsArray(error.response.data));
          });
      } else {
        setLoading(false);
        setErrors(["Please enter a valid email address."]);
      }
    }

    const [errorMessage,setErrorMessage]=useState("")
  
    function handleSubmit() {
      setLoading(true);
      if (code !== "") {
        verifyResetEmailCode({ reset_code: code })
          .then((res) => {
            setOpenSuccess(true);
            setTimeout(() => {
              setOpen(false);
            }, 1000);
            // resetForm();
            handleClose()
          })
          .catch((error) => {
            setLoading(false);
            if (error.response && error.response.data.error === "Invalid verification code") {
              setErrorMessage("Invalid verification code");
            } else {
              setErrorMessage("An error occurred. Please try again.");
            }
          });
      }
    }
  
    const handleBlur = () => {
      setTouched(true);
    };
  
    const handleClose = () => {
      resetForm();
      handleEndEditMode()
    };

    const theme = useTheme();
    const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  
    return (
      <>
        <Toast
          open={openSuccess}
          onClose={() => setOpenSuccess(false)}
          message={"Your Email has been updated successfully"}
        />
        <Toast
          open={openResent}
          onClose={() => setOpenResent(false)}
          message={"Verification code has been resent"}
        />
        <Dialog
          fullWidth
          open={open}
          maxWidth="ssm"
          keepMounted
          scroll={mobile_ssm ? "paper": "body"}
          TransitionComponent={mobile_ssm ? Transition : undefined}
          sx={{
            "& .MuiDialog-container": {
              alignItems: {xs: "flex-end", ssm: "center"},
            },
            "& .MuiPaper-root": {
              margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
              maxWidth: { xs: "100%", ssm: "500px", sm: "600px" },
              width: { xs: "100%", ssm: "500px", sm: "600px" },
              borderRadius: {xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`, ssm: pxToRem(20)},
              maxHeight: {xs: "85vh", ssm: "none"},
            },
          }}
        >
          <ModalHeader
            title="Change Email"
            inModal
            onClose={() => handleClose()}
          />
  
          <DialogContent sx={{backgroundColor:"#f7f6f2"}}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
                flexDirection: "column",
                marginBottom: "1rem",
               
              }}
            >
  
              <Typography color="gray" sx={{ textAlign: "center" }}>
                A verification code has been sent to the new email. Please
                verify to update the email address.
              </Typography>
              
             
            </Box>
  
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "18px",
              }}
            >
              <TextFieldStyled
                placeholder={"Verification Code"}
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                onBlur={handleBlur}
                type="text"
                required
                error={code === "" && touched || errorMessage !== ""}
                helperText={
                    code === "" && touched ? "This field is required" : errorMessage
                  }
                inputProps={{ maxLength: 6 }}
              />
              <Typography color="gray" sx={{ textAlign: "center" }}>
                Didn't receive the code?{" "}
                <span
                  style={{
                    color: resendTimer > 0 ? "grey" : "#026670",
                    cursor: resendTimer > 0 ? "not-allowed" : "pointer",
                  }}
                  onClick={() => {
                    if (resendTimer === 0) {
                      sendVerificationCode();
                      setOpenResent(true);
                    }
                  }}
                >
                  Resend {resendTimer > 0 && `(${resendTimer}s)`}
                </span>
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", pb: "30px" , mt: "18px", backgroundColor:"#f7f6f2"}}
            >
              <Button
                disabled={code === "" && touched}
                onClick={() => {
                  handleSubmit();
                }}
                variant="yellow"
              >
                Verify
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
  