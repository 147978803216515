import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Button,
  Typography,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import ModalHeader from "../../../components/ModalHeader";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/material/styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NumberContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: pxToRem(10),
  "& button": {
    minWidth: pxToRem(40),
    height: pxToRem(40),
    borderRadius: "50%",
    fontSize: pxToRem(20),
    lineHeight: 1,
    padding: 0,
  },
});

// function calculateExpirationDate(endDate, duration, prolongBy) {
//   const end = new Date(endDate);
//   end.setDate(end.getDate() + duration * prolongBy);

//   const month = String(end.getMonth() + 1).padStart(2, "0");
//   const day = String(end.getDate()).padStart(2, "0");
//   const year = end.getFullYear();
//   // Return formatted date as MM/DD/YYYY
//   return `${month}-${day}-${year}`;
// }

// const formatDate = (dateString) => {
//   if (!dateString) {
//     return "Never";
//   }

//   const [year, month, day] = dateString.split("-").map(Number);

//   const date = new Date(year, month - 1, day);

//   const options = { year: "numeric", month: "long", day: "numeric" };

//   return date.toLocaleDateString(undefined, options);
// };

// function calculateExpirationDate(endDate, duration, prolongBy) {
//   const end = new Date(endDate);
//   end.setDate(end.getDate() + duration * prolongBy);

//   // Use Intl.DateTimeFormat to format the date
//   const formatter = new Intl.DateTimeFormat("en-US", {
//     month: "long",
//     day: "numeric",
//     year: "numeric",
//   });

//   return formatter.format(end);
// }

function calculateExpirationDate(endDate, duration, prolongBy) {
  // Parse the input date
  const [year, month, day] = endDate.split("-").map(Number);

  // Create a new date without timezone issues
  const date = new Date(year, month - 1, day);
  date.setDate(date.getDate() + duration * prolongBy);

  // Format the resulting date
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

function daysUntilExpiration(expirationDate) {
  const today = new Date();
  const expiration = new Date(expirationDate);
  const diffTime = Math.abs(expiration - today);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export default function ProlongModal({
  open,
  setOpen,
  endDate,
  duration,
  prolongPrice,
  handleLifeLongPurchase,
}) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [touched, setTouched] = useState(false);
  const [prolongedBy, setProlongedBy] = useState(1);
  const [expirationDate, setExpirationDate] = useState("");
  const [daysUntilExpire, setDaysUntilExpire] = useState(0);

  useEffect(() => {
    console.log(
      "datatodebubghggnf",
      daysUntilExpire,
      expirationDate,
      prolongedBy,
      duration,

    );
  }, [endDate, duration, prolongedBy]);

  useEffect(() => {
    console.log("djnjdnjnhrfunisnjcndsc", endDate, duration, prolongedBy);
    if (endDate && duration && prolongedBy) {
      const newExpirationDate = calculateExpirationDate(
        endDate,
        duration,
        prolongedBy
      );
      setExpirationDate(newExpirationDate);
      setDaysUntilExpire(daysUntilExpiration(newExpirationDate));
    }
  }, [endDate, duration, prolongedBy, open]);

  const handleBlur = () => {
    setTouched(true);
  };

  const handleIncrement = () => {
    setProlongedBy((prev) => prev + 1);
  };

  const handleDecrement = () => {
    if (prolongedBy > 1) {
      setProlongedBy((prev) => prev - 1);
    }
  };

  const handleSubmit = () => {
    handleLifeLongPurchase("Normal", "Prolong", prolongedBy);
  };

  const handleClose = () => {
    setOpen(false);
    setTouched(false);
    setErrorMessage("");
    setLoading(false);
    setProlongedBy(1);
    setExpirationDate("");
    setDaysUntilExpire(0);
  };

  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="ssm"
      keepMounted
      scroll={mobile_ssm ? "paper": "body"}
      TransitionComponent={mobile_ssm ? Transition : undefined}
      sx={{
        "& .MuiDialog-container": {
          alignItems: {xs: "flex-end", ssm: "center"},
        },
        "& .MuiPaper-root": {
          maxWidth: { xs: "100%", ssm: "600px" },
          width: { xs: "100%", ssm: "600px" },
          minHeight: "300px",
          borderRadius: {xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`, ssm: pxToRem(20)},
          margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
          maxHeight: {xs: "85vh", ssm: "none"},
        },
      }}
    >
      <ModalHeader title="Prolong Subscription" inModal onClose={handleClose} />

      <DialogContent
        sx={{
          backgroundColor: "#f7f6f2",
          p: { xs: pxToRem(30), ssm: pxToRem(50) },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: "1rem",
          }}
        >


          <Typography color="gray" sx={{ textAlign: "center" }}>
            Extend your subscription by {duration *prolongedBy} day
            {duration * prolongedBy > 1 ? "s" : ""} for the low price of $
            {prolongPrice * prolongedBy}.<br /> Your subscription will expire in{" "}
            {daysUntilExpire} day{daysUntilExpire > 1 ? "s" : ""} (
            {expirationDate}).
          </Typography>
        </Box>

        <NumberContainer>
          <Button
            variant="outlined"
            onClick={handleDecrement}
            disabled={prolongedBy <= 1}
          >
            -
          </Button>
          {/* <Typography>{prolongedBy}</Typography> */}
          <Typography sx={{ width: "2ch", textAlign: "center" }}>
            {prolongedBy}
          </Typography>
          <Button variant="outlined" onClick={handleIncrement}>
            +
          </Button>
        </NumberContainer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#f7f6f2",
          p: { xs: `${pxToRem(30)} ${pxToRem(10)}`, sssm: pxToRem(30), ssm: pxToRem(50) },
          pb: "0px !important"
        }}
      >
        <Button
          disabled={loading}
          onClick={handleSubmit}
          variant="yellow"
          sx={{ height: pxToRem(54) }}
        >
          Prolong Subscription for ${prolongPrice * prolongedBy}
        </Button>
      </Box>
      </DialogContent>
    </Dialog>
  );
}
