import React from "react";
import { Box, keyframes, styled } from "@mui/material";

const fillAnimation = keyframes`
  0% {
    width: 0%;
    background: linear-gradient(to right, #FCE181, #FCE181);
  }

  100% {
    width: 100%;
    background: linear-gradient(to right, #FCE181, #026670);
  }
`;

const LoadingContainer = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "4px",
  backgroundColor: "#EEE",
  zIndex: 1300,
});

const AnimatedBar = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  width: "0%",
  animation: `${fillAnimation} 2s linear forwards`,
});

function CoursePageLoading() {
  return (
    <LoadingContainer>
      <AnimatedBar />
    </LoadingContainer>
  );
}

export default CoursePageLoading;
