import { Box, positions, Stack, styled } from "@mui/system";
import { pxToRem } from "px2rem2px";
import React, { useRef, useState } from "react";
import LikeBtn from "../../components/courses/LikeBtn";
import { useAuth } from "../../utils/AuthContext";
import { NavLink } from "react-router-dom";
import {
  Button,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

// import {LikeIcon} from "../../images/like.svg"
import Likes from "../../components/Likes";
import PlayBtn from "../../components/PlayBtn";
import { ReactComponent as Play } from "../../images/play.svg";
import { ReactComponent as Eye } from "../../images/eye.svg";
import IconBtnCircular from "../../components/IconBtnCircular";
import { ReactComponent as Bookmark } from "../../images/bookmark.svg";
import { ReactComponent as Share } from "../../images/share.svg";
import { ReactComponent as Volume } from "../../images/volume.svg";
import { ReactComponent as CamcorderIcon } from "../../images/Camcorder_Icon.svg";
import VideoPlayer from "../course/components/VideoPlayer";
import { postVideoLike } from "../../api/parsha";
const Duration = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #026670;
  border-radius: 0 5px 0 0;
  padding: 3px 11px;
  transition: 0.2s ease;
  opacity: 0;

  @media (max-width: 899px) {
    opacity: 1;
  }
`;

const Label = styled(Typography)(({ children }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "24px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#026670",
    backgroundColor: "#f8eab9",
    border: "2px solid #f5e093",
    borderRadius: "calc(infinity * 1px)",
    padding: "0 25px",
  };
});

export default function Parsha({
  subscribers,
  duration,
  labels,
  selectedParsha,
  isPlaying,
  setIsPlaying,
  video,
  currentlyPlayingId,
  setCurrentlyPlayingId,
}) {
  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const isMobileSSSMToSSM = useMediaQuery(
    theme.breakpoints.between("sssm", "ssm")
  );

  // const [isPlaying, setIsPlaying] = useState(false);

  const customPosition = useRef(null);

  const handlePlayClick = () => {
    // setCurrentlyPlayingId(selectedParsha.id);
    setCurrentlyPlayingId((prevId) => (prevId === video.id ? null : video.id));
    setIsPlaying(true);
  };

  const currentTime = useRef(null);


  return (
    <div>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          // ref={passRef}
          position="relative"
          bgcolor="#fff"
          borderRadius={pxToRem(20)}
          sx={{
            transition: "0.2s ease",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            "&::before": {
              content: "''",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: `calc(100% - ${pxToRem(40)})`,
              height: "100%",
              backgroundColor: "#FCE181",
              borderRadius: pxToRem(20),
              transform: "translate(-50%,-50%)",
              zIndex: -1,
              transition: "0.2s ease",
            },
            "& .play-btn, .share-btn": {
              position: "static",
              opacity: 0,
              transition: "0.2s ease",
              zIndex: 1,
            },
            "&:hover": {
              boxShadow: {
                xs: "none",
                sm: `0px ${pxToRem(20)} ${pxToRem(24)} rgba(0,0,0,0.1)`,
              },
              "&::before": {
                height: `calc(100% + ${pxToRem(40)})`,
              },
              "& .slideTitle": {
                color: "primary.main",
              },
              "& .play-btn, .duration, .share-btn": {
                opacity: 1,
              },
            },
          }}
        >
          <Box ref={customPosition}>
            {isPlaying ? (
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  aspectRatio: "16/9",
                  backgroundColor: "black",
                  borderRadius: "1.25rem 1.25rem 0 0",
                }}
              >
                <VideoPlayer
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  selectedLesson={video}
                  thumbnailCustom={video.thumb_nail}
                  customPosition={customPosition}
                  currentTime={currentTime}
                  useCustomPlayer
                  useParshaPlayer
                  startsPlaying
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  "& > *": { gridRow: "1 / -1", gridColumn: "1 / -1" },
                  position: "relative",
                }}
              >
                <PlayBtn
                  width={pxToRem(96)}
                  height={pxToRem(96)}
                  sx={{ backgroundColor: "#fff" }}
                  onClick={handlePlayClick}
                >
                  <Play
                    color="#026670"
                    width={pxToRem(20)}
                    height={pxToRem(24)}
                    style={{ marginLeft: "6%" }}
                    onClick={handlePlayClick}
                  />
                </PlayBtn>
                <Box
                  position="relative"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius={`${pxToRem(20)} ${pxToRem(20)} 0 0`}
                  width="100%"
                  sx={{ aspectRatio: "16/9", overflow: "hidden" }}
                >
                  {isAuthenticated ? (
                    <Stack position="absolute" top="20px" gap="5px" left="20px">
                      <LikeBtn
                        courseId={null}
                        isFavorite={false}
                        onFavoriteClick={() => {}}
                        sx={{
                          position: "static",
                          width: pxToRem(48),
                          height: pxToRem(48),
                        }}
                      />
                      <IconBtnCircular
                        className="hover-green share-btn"
                        sx={{
                          width: pxToRem(48),
                          height: pxToRem(48),
                          borderRadius: "50%",
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#026670",
                        }}
                      >
                        <Share
                          color="#FCE181"
                          style={{ marginRight: pxToRem(1.5) }}
                        />
                      </IconBtnCircular>
                    </Stack>
                  ) : (
                    <></>
                  )}

                  <Duration className="duration">{duration}</Duration>
                  <img
                    src={video.thumb_nail}
                    alt={video.title}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
            )}

            <Box
              p="26px 36px"
              bgcolor="#fff"
              borderRadius={`0 0 ${pxToRem(20)} ${pxToRem(20)}`}
              overflow="hidden"
              display="flex"
              flexDirection="column"
              flexGrow={1}
            >
              <Typography
                className="slideTitle"
                fontSize={pxToRem(22)}
                fontWeight={700}
                lineHeight={pxToRem(30)}
                color="secondary.main"
                mb={pxToRem(20)}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {video.title}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: pxToRem(24),
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",

                    flexGrow: 1,
                    marginTop: "2px",
                  }}
                >
                  <Stack flexDirection="row" flexWrap="wrap">
                    <Typography
                      component="p"
                      fontSize="12px"
                      fontWeight={600}
                      color="#BFBEBB"
                    >
                      Posted {new Date(video.uploaded_at).toLocaleDateString()}
                    </Typography>
                  </Stack>
                </Box>

                <Stack flexDirection="row" alignItems="center" gap="16px">
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      columnGap: pxToRem(8),
                    }}
                  >
                    <Typography
                      component="p"
                      fontSize="12px"
                      fontWeight={600}
                      color="#BFBEBB"
                      whiteSpace="nowrap"
                      // marginRight="2px"
                      marginTop="2px"
                    >
                      123
                    </Typography>
                    <Eye width={pxToRem(22)} height={pxToRem(22)} />
                  </Box>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="p"
                      fontSize="12px"
                      fontWeight={600}
                      color="#BFBEBB"
                      whiteSpace="nowrap"
                      // marginRight="2px"
                      marginTop="2px"
                    >
                      {video.likes}
                    </Typography>

                    <Box sx={{ marginTop: "-6px", marginLeft: "-3px" }}>
                      <Likes />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

// import React from 'react';
// import { Card, CardContent, CardMedia, Typography, Button, Stack } from '@mui/material';

// const Parsha = ({ image, title, text, linkUrl, date, subscribers, duration, labels }) => {
//   return (
//     <Card>
//       <CardMedia
//         component="img"
//         height="300"
//         image={image}
//         alt={title}
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//           {title}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {text}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Date: {date}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Subscribers: {subscribers}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Duration: {duration}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Labels: {labels.join(', ')}
//         </Typography>
//         <Button size="small" href={linkUrl}>
//           Learn More
//         </Button>
//       </CardContent>
//     </Card>
//   );
// };

// export default Parsha;
