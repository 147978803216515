import { Box, Button, Typography } from "@mui/material";
import ModalLayout from "./ModalLayout";

import { pxToRem } from "px2rem2px";

const ErrorModal = (props) => {
  const { openPurchaseModal, ...restProps } = props;

  return (
    <ModalLayout {...restProps} headerTitle="Your donation was canceled" showHeaderIcon closeIcon>
      <Box textAlign="center">
        <Typography
          component="p"
          fontSize={pxToRem(20)}
          fontWeight={700}
          sx={{ marginTop: pxToRem(10) }}
        >
          Thank you {props?.fullName}
          <br /> For your consideration
        </Typography>
        <Button
          variant="yellow"
          onClick={() => props.onClose()}
          sx={{
            width: { xs: "100%", ssm: "300px" },
            maxWidth: { xs: "300px", ssm: "none" },
            mt: pxToRem(40),
            height: pxToRem(54),
          }}
        >
          Close
        </Button>
      </Box>
    </ModalLayout>
  );
};

export default ErrorModal;
