import { Box, Button, Typography, Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import GradientAnimation from "../../components/GradientAnimation";
import { getHeroSection } from "../../api/cmsApi";
import TreeImg from "../../images/footer-tree.svg";
import { useState, useEffect } from "react";
import highlightHTML from "../../utils/highlightHTML";
import { getLanguageClass } from "../../utils/languageClass";

const PageTitleHighlighted = styled(Typography)(({ theme }) => ({
  position: "relative",
  fontFamily: "PloniBold",
  fontSize: pxToRem(80),
  lineHeight: pxToRem(75),
  "&::after": {
    content: "''",
    width: "104%",
    height: pxToRem(20),
    position: "absolute",
    left: "50%",
    bottom: pxToRem(12),
    transform: "translateX(-50%)",
    backgroundColor: "rgba(252,225,129,0.5)",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "50px",
    lineHeight: "50px",
    "&::after": {
      bottom: "6px",
    },
  },
}));

const Hero = () => {
  const [heroSection, setHeroSection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchHeroSection = async () => {
    try {
      const data = await getHeroSection();
      setHeroSection(data);
    } catch (error) {
      console.error("Error fetching hero section:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHeroSection();
  }, []);

  return (
    <Box
      position="relative"
      pt={pxToRem(30)}


      minHeight={pxToRem(835)}
      sx={{
        borderRadius: { xs: 0, md: pxToRem(40) },
        paddingBottom: { xs: pxToRem(1), md: pxToRem(40) },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          "&::before": {
            content: "''",
            position: "absolute",
            bottom: pxToRem(30),
            left: "50%",
            transform: "translateX(-125%)",
            background: `url(${TreeImg})`,
            zIndex: 1,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            width: pxToRem(520),
            height: pxToRem(702),
            opacity: 0.4,
            "@media(max-width: 1300px)": {
              left: "30px",
              transform: "none",
            },
            "@media(max-width: 899px)": {
              left: "50%",
              transform: "translateX(-50%)",
            },
          },
        }}
      ></Box>
      <GradientAnimation />
      <Header menuVisible />

      <Box
        className="rtl-section header-padding"
        position="relative"
        maxWidth={pxToRem(1000)}
        mt={pxToRem(102)}
        mb={pxToRem(90)}
        mx={{ md: "auto" }}
        ml="auto"
        zIndex={1}

        // backgroundColor="red"
      >
        {isLoading ? (
          [...Array(5).keys()].map((val) => (
            <Skeleton
              key={val}
              variant="text"
              animation="wave"
              sx={{ fontSize: "50px" }}
            />
          ))
        ) : (
          <>
            {heroSection && heroSection?.title && (
              <Box
                fontFamily="PloniBold"
                fontSize={{ xs: "20px", sm: pxToRem(40) }}
                lineHeight={{ xs: "10px", sm: pxToRem(75) }}
                sx={{
                  fontWeight: "500",
                  fontFamily: "PloniBold",
                  px: { xs: "30px", sm: "30px" },
                  // mb: { md: "-50px", sm: "30px" },
                }}
                dangerouslySetInnerHTML={{
                  __html: highlightHTML(heroSection?.title, true),
                }}
                // className={getLanguageClass(heroSection?.title,true)}
                // backgroundColor="green"
              />
             
            )}
            {heroSection?.button_text && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  px: { xs: "10px", sm: "20px" },
                  mt: { xs: "2px", sm: "5px" },

                  // backgroundColor:"yellow",
                }}
              >
                <Button
                  variant="white"
                  onClick={() =>
                    window.open(
                      heroSection.button_link,
                      heroSection.target_new_tab ? "_blank" : "_self"
                    )
                  }
                  sx={{
                    textTransform: "none",
                  }}
                >
                  {heroSection?.button_text}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
      <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
    </Box>
  );
};

export default Hero;
