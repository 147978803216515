import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Chip,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { rtlTheme } from "../../theme";
import { ThemeProvider } from "@mui/material/styles";
import CourseImg1 from "../../images/course-img1.png";
import Parsha from "./Parsha";
import { pxToRem } from "px2rem2px";
import { getCategories, getSubgroupVideos } from "../../api/parsha";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  gap: "2px",
  marginBottom: pxToRem(34),
  padding: "0 13px",
  [theme.breakpoints.down("ssm")]: {
    margin: "0 -25px",
  },
  "&::before": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "2px",
    borderRadius: "calc(infinity * 1px)",
    left: 0,
    bottom: -2,
    backgroundColor: "#026670",
    boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.07)",
    backdropFilter: "blur(5px)",
    zIndex: 1,
  },
  "& button": {
    maxWidth: "220px",
    height: pxToRem(64),
    width: "100%",
    fontSize: "14px",
    fontWeight: 600,
    color: "#333",
    backgroundColor: "#E6E5E1",
    border: "none !important",
    borderRadius: "10px 10px 0 0 !important",
    textTransform: "capitalize",
    margin: "0 !important",
    [theme.breakpoints.down("ssm")]: {
      height: "50px",
      maxWidth: "150px",
    },
    [theme.breakpoints.down("sssm")]: {
      maxWidth: "115px",
    },
    "&:hover": {
      color: "#FCE181",
      backgroundColor: "#026670",
    },
  },
  "& .Mui-selected": {
    color: "#FCE181 !important",
    fontSize: "16px",
    backgroundColor: "#026670 !important",
    "&:hover": {
      color: "#FCE181",
      backgroundColor: "#026670",
    },
  },
}));

const ParshaSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

  const [categoryTab, setCategoryTab] = useState(1);
  const [groupTab, setGroupTab] = useState(0);
  const [selectedChip, setSelectedChip] = useState(0);

  const [showLeftGradient, setShowLeftGradient] = useState(true);
  const [showRightGradient, setShowRightGradient] = useState(true);
  const [showTopGradient, setShowTopGradient] = useState(false);
  const [showBottomGradient, setShowBottomGradient] = useState(true);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [subgroups, setSubgroups] = useState([]);
  const [videos, setVideos] = useState([]);

  const [isLoading, setIsLoading] = useState({ videos: false });

  const fetchVideos = () => {
    setIsLoading({ ...isLoading, videos: true });
    getSubgroupVideos(selectedChip)
      .then((res) => {
        setVideos(res.data.results);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading({ ...isLoading, videos: false });
      });
  };

  useEffect(() => {
    fetchVideos();
  }, [selectedChip]);
  useEffect(() => {
    setSelectedChip(0);
  }, [groupTab, categoryTab]);
  const fetchCategories = () => {
    getCategories()
      .then((res) => setCategories(res.results))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categories && categories.length > 0) {
      const filteredGroup = categories.filter(
        (category) => category.id === categoryTab
      );
      setGroups(filteredGroup[0]?.children);
    } else {
      setGroups([]);
    }
  }, [categoryTab, categories]);

  useEffect(() => {
    if (groups && groups.length > 0) {
      const filteredSubgroup = groups.filter(
        (subgroup) => subgroup.id === groupTab
      );
      setSubgroups(filteredSubgroup[0]?.video_groups);
    } else {
      setSubgroups([]);
    }
  }, [categoryTab, , groupTab, categories, groups]);

  const tabsRef = useRef(null);
  const stackRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleCategoryTabChange = (event, newTab) => {
    if (newTab !== null) {
      setCategoryTab(newTab);
    }
  };

  const handleGroupTabChange = (event, newValue) => {
    setGroupTab(newValue);
  };

  // useEffect(() => {
  //   // Scroll to the active tab on load
  //   if (tabsRef.current) {
  //     const activeTab = tabsRef.current.querySelector(".Mui-selected");
  //     if (activeTab) {
  //       activeTab.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
  //     }
  //   }
  // }, []);

  const handleScroll = useCallback(() => {
    const scrollContainer = tabsRef.current?.querySelector(".MuiTabs-scroller");
    if (!scrollContainer) return;
    const { scrollWidth, clientWidth, scrollLeft } = scrollContainer;
    const currentScroll = Math.abs(scrollLeft);
    setShowRightGradient(currentScroll > 1);
    setShowLeftGradient(Math.round(currentScroll + clientWidth) < scrollWidth);
  }, []);

  useEffect(() => {
    const scrollContainer = tabsRef.current?.querySelector(".MuiTabs-scroller");
    if (!scrollContainer) return;
    const resizeObserver = new ResizeObserver(handleScroll);
    resizeObserver.observe(scrollContainer);
    scrollContainer.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
      resizeObserver.disconnect();
    };
  }, [handleScroll]);

  const handleVerticalScroll = useCallback(() => {
    const container = stackRef.current;
    if (!container) return;
    const { scrollTop, scrollHeight, clientHeight } = container;
    setShowTopGradient(scrollTop > 0);
    setShowBottomGradient(Math.round(scrollTop + clientHeight) < scrollHeight);
  }, []);

  useEffect(() => {
    const scrollContainer = stackRef.current;
    if (!scrollContainer) return;

    // Use ResizeObserver to handle both initial load and resize
    const resizeObserver = new ResizeObserver(handleVerticalScroll);
    resizeObserver.observe(scrollContainer);

    scrollContainer.addEventListener("scroll", handleVerticalScroll);
    handleVerticalScroll(); // Initial check

    return () => {
      scrollContainer.removeEventListener("scroll", handleVerticalScroll);
      resizeObserver.disconnect();
    };
  }, [handleVerticalScroll]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const slider = tabsRef.current.querySelector(".MuiTabs-scroller");
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();

    const slider = tabsRef.current.querySelector(".MuiTabs-scroller");
    const x = e.pageX - slider.offsetLeft;
    const walk = x - startX;
    slider.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => document.removeEventListener("mouseup", handleMouseUp);
  }, []);

  console.log("videos", videos);
  return (
    <Box>
      {/* <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap="15px"
        mb="25px"
      >
        <Typography
          variant="sectionTitle"
          component="h2"
          fontSize={pxToRem(35)}
          mb={0}
        >
          <span className="highlighted">Parsha</span>
        </Typography>
        {videos && (
          <Typography fontSize="12px" fontWeight={600} color="#BFBEBB">
            {videos.length} Parshas
          </Typography>
        )}
      </Stack> */}

      <StyledToggleButtonGroup
        value={categoryTab}
        exclusive
        onChange={handleCategoryTabChange}
      >
        {categories &&
          categories.length > 0 &&
          categories.map((category) => {
            return (
              <ToggleButton value={category.id}>{category.name}</ToggleButton>
            );
          })}
        {/*     <ToggleButton value="parsha">Parsha</ToggleButton>
        <ToggleButton value="holidays">Holidays</ToggleButton>
        <ToggleButton value="seasonal">Seasonal</ToggleButton> */}
      </StyledToggleButtonGroup>

      {groups && groups.length > 0 && (
        <ThemeProvider theme={rtlTheme}>
          <Box position="relative">
            {showLeftGradient && (
              <Box
                sx={{
                  position: "absolute",
                  left: { xs: "-25px", ssm: "1px" },
                  top: { xs: "2px", ssm: "1px" },
                  width: { xs: "55px", md: "78px" },
                  height: { xs: pxToRem(44), ssm: pxToRem(49) },
                  borderRadius: "100px 0px 0px 100px",
                  zIndex: 2,
                  background:
                    "linear-gradient(90deg, #E6E5E1 41.18%, rgba(230, 229, 225, 0.00) 91.18%)",
                }}
              />
            )}
            <Tabs
              ref={tabsRef}
              value={groupTab}
              position="relative"
              onChange={handleGroupTabChange}
              variant="scrollable"
              allowScrollButtonsMobile={isMobile}
              scrollButtons={isMobile ? "auto" : false}
              aria-label="scrollable force tabs example"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseUp}
              sx={{
                //direction: "rtl",
                textAlign: "center",
                mx: { xs: "-25px", ssm: "0" },
                "& .MuiTabs-indicator": { backgroundColor: "#026670" },
                minHeight: { xs: pxToRem(46), ssm: pxToRem(49) },
                border: { ssm: "1px solid #BEBEBE" },
                backgroundColor: "#E6E5E1",
                borderRadius: { xs: "0", ssm: "100px" },
                "& .MuiTabs-flexContainer": {
                  justifyContent: "center",
                },
                "& .MuiTabScrollButton-root": {
                  zIndex: 3,
                  width: "28px",
                  "& .MuiSvgIcon-root": {
                    color: "#026670",
                    width: "28px",
                    height: "39px",
                  },
                },
              }}
            >
              {groups.map((group) => (
                <Tab
                  sx={{
                    py: pxToRem(10),
                    px: pxToRem(24),
                    fontSize: pxToRem(16),
                    minHeight: { xs: pxToRem(46), ssm: pxToRem(49) },
                    color: "#333333",
                    fontWeight: 600,
                    lineHeight: pxToRem(26),
                    "&.Mui-selected": { color: "#026670" },
                  }}
                  label={group?.name}
                  value={group.id}
                />
              ))}
            </Tabs>
            {showRightGradient && (
              <Box
                sx={{
                  position: "absolute",
                  right: { xs: "-25px", ssm: "1px" },
                  top: { xs: "2px", ssm: "1px" },
                  width: { xs: "55px", md: "78px" },
                  height: { xs: pxToRem(44), ssm: pxToRem(49) },
                  borderRadius: "0px 100px 100px 0px",
                  zIndex: 2,
                  background:
                    "linear-gradient(270deg, #E6E5E1 41.18%, rgba(230, 229, 225, 0.00) 91.18%)",
                }}
              />
            )}
          </Box>
        </ThemeProvider>
      )}

      {subgroups && subgroups.length > 0 && (
        <Box
          position="relative"
          mx={{ xs: "-15px", ssm: "0" }}
          px={{ sm: pxToRem(30), md: pxToRem(35), lg: pxToRem(45) }}
        >
          {showTopGradient && (
            <Box
              position="absolute"
              width="100%"
              height="24px"
              top="0"
              sx={{
                background:
                  "linear-gradient(180deg, #F7F6F2 0%, rgba(247, 246, 242, 0.00) 100%)",
              }}
              zIndex={1}
            />
          )}

          <Stack
            ref={stackRef}
            direction="row"
            columnGap={{ xs: "8px", ssm: "10px" }}
            rowGap={{ xs: "8px", ssm: "10px" }}
            my={pxToRem(20)}
            py={pxToRem(12)}
            flexWrap="wrap"
            justifyContent={{ xs: "space-between", ssm: "center" }}
            px={pxToRem(10)}
            alignItems="center"
            sx={{ height: { xs: "118px", sm: "auto" }, overflow: "auto" }}
          >
            {subgroups.map((subgroup) => (
              <Chip
                className={selectedChip === subgroup.id ? "pulse" : ""}
                onClick={() => setSelectedChip(subgroup.id)}
                sx={{
                  fontWeight: 600,
                  px: { xs: pxToRem(16), ssm: pxToRem(30) },
                  height: { xs: pxToRem(34), ssm: "24px" },
                  backgroundColor:
                    selectedChip === subgroup.id ? "#026670" : "#E6E5E1",
                  color: selectedChip === subgroup.id ? "#FCE181" : "",
                  flexGrow: { xs: 1, ssm: 0 },
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#026670", color: "#FCE181" },
                }}
                label={subgroup.name}
              />
            ))}
          </Stack>

          {showBottomGradient && (
            <Box
              position="absolute"
              width="100%"
              height={{ xs: pxToRem(30), ssm: "18px" }}
              bottom="0"
              sx={{
                background:
                  "linear-gradient(0deg, #F7F6F2 0%, rgba(247, 246, 242, 0.00) 100%)",
              }}
              zIndex={1}
            />
          )}
        </Box>
      )}

      {isLoading.videos ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(50vh - 100px)", // Adjust the height as needed
          }}
        >
          <CircularProgress size={80} thickness={4} sx={{ color: "#036670" }} />
        </Box>
      ) : videos && videos.length > 0 ? (
        <Stack gap="40px" mt={pxToRem(38)}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", ssm: "1fr 1fr" },
              gap: "40px 20px",
            }}
          >
            {videos.map((video) => (
              <Box
                key={video.id} // Moved the key here to avoid React warnings
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <Parsha
                  video={video}
                  text={video.text}
                  subscribers={video.subscribers}
                  duration={video.duration}
                  labels={video.labels}
                  isPlaying={currentlyPlayingId === video.id}
                  setIsPlaying={setIsPlaying}
                  currentlyPlayingId={currentlyPlayingId}
                  setCurrentlyPlayingId={setCurrentlyPlayingId}
                />
              </Box>
            ))}
          </Box>
        </Stack>
      ) : null}
    </Box>
  );
};

export default ParshaSection;
