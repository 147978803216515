import { Box, Typography, Container, Grid, Stack, Button } from "@mui/material";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import TreeImg from "../../images/footer-tree.svg";
import GradientAnimation from "../../components/GradientAnimation";
import { useEffect, useState } from "react";
import BlogImg from "../../images/slide-blog3.jpg";
import BlockBordered from "../../components/BlockBordered";
import PresentationImg from "../../images/presentation-img.jpg";
import { styled } from "@mui/system";
import TextLink from "../../components/TextLink";
import Slider from "../../components/Slider";
import { SwiperSlide } from "swiper/react";
import { getBlogs } from "../../api/blogs";
import TopPost from "./components/TopPost";
import FrontPost from "./components/FrontPost";
import { getLanguageClass } from "../../utils/languageClass";
const Label = styled(Typography)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "20px",
    fontSize: "12px",
    fontWeight: 600,
    color: "#026670",
    backgroundColor: "#FFFFFF",
    // border: "2px solid #f5e093",
    borderRadius: "calc(infinity * 1px)",
    padding: "0 15px",
  };
});
const Blogs = () => {
  useEffect(() => {
    document.title = "Blogs";
  }, []);
  const [blogs, setBlogs] = useState([]);
  const [frontPost, setFrontPost] = useState(null);
  const fetchBlogs = () => {
    getBlogs()
      .then((res) => {
        const filteredBlogs = res.data.results.filter((blog) => {
          return new Date(blog.published_at) < new Date();
        });
        setBlogs(filteredBlogs.slice(1));
        if (filteredBlogs.length > 0) {
          setFrontPost(filteredBlogs[0]);
        } else {
          setFrontPost(null);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);
  return (
    <Box position="relative" overflow="hidden">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Box
        position="relative"
        pt={pxToRem(30)}
        pb={pxToRem(40)}
        minHeight={pxToRem(540)}
        sx={{
          display: "flex",
          flexDirection: "column",
          // background: "linear-gradient(45deg, #026670, #fce181, #026670, #fce181)",
          // backgroundSize: "600% 600%",
          // animation: `${gradientAnimation} 30s ease infinite`,
          borderRadius: { xs: 0, md: pxToRem(40) },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            overflowX: "clip",
            "&::before": {
              content: "''",
              position: "absolute",
              bottom: pxToRem(30),
              left: "50%",
              transform: "translateX(-125%)",
              background: `url(${TreeImg})`,
              zIndex: 1,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
              width: pxToRem(520),
              height: pxToRem(702),
              opacity: 0.4,
              "@media(max-width: 1300px)": {
                left: "30px",
                transform: "none",
              },
              "@media(max-width: 899px)": {
                left: "50%",
                transform: "translateX(-50%)",
              },
            },
          }}
        ></Box>
        <GradientAnimation />
        <Header menuVisible />

        <Box
          className="header-padding"
          sx={{
            flexGrow: 1,
            zIndex: 2,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            component="h1"
            fontFamily="PloniBold"
            fontWeight="700"
            textAlign="center"
            px={pxToRem(30)}
            fontSize={{ xs: pxToRem(40), sm: pxToRem(70) }}
            lineHeight={{ xs: "40px", sm: pxToRem(60) }}
            mb="20px"
          >
            Blogs & News
          </Typography>
          <Typography
            component="h2"
            sx={{
              fontSize: pxToRem(25),
              letterSpacing: "-0.5px",
              lineHeight: pxToRem(32),
              fontWeight: 700,
              opacity: "75%",
              px: pxToRem(20),
              textAlign: "center",
              display: { xs: "none", ssm: "block" },
            }}
          >
            Explore the latest blogs and news from Reb Yakov Moshe
          </Typography>
        </Box>
        <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
      </Box>
      <Container
        sx={{ px: pxToRem(30), py: { xs: pxToRem(77), md: pxToRem(120) } }}
        disableGutters
      >
        <Grid
          container
          spacing={{ xs: pxToRem(60), ssm: pxToRem(80) }}
          columns={{ xs: 12, lg: 11 }}
        >
          {frontPost && <FrontPost post={frontPost} />}
          <Grid item xs={12} lg={4}>
            <Typography
              sx={{
                marginBottom: pxToRem(20),
                color: "#333333",
                fontWeight: 700,
                fontSize: { xs: pxToRem(22), ssm: pxToRem(25) },
                lineHeight: pxToRem(32),
              }}
            >
              Top Posts
            </Typography>
            <Grid
              container
              columnSpacing={pxToRem(20)}
              rowSpacing={pxToRem(12)}
              display={{ xs: "none", ssm: "flex" }}
            >
              {blogs &&
                blogs.length > 0 &&
                blogs
                  .filter((blog) => blog.is_featured) // Filter blogs where is_featured is true
                  .map((blog, index) => {
                    return <TopPost key={index} post={blog} />;
                  })}
            </Grid>
            <Box
              sx={{
                "& .MuiContainer-root": {
                  p: 0,
                },
              }}
              mx={{ xs: "-25px", ssm: 0 }}
              display={{ xs: "block", ssm: "none" }}
            >
              <Slider
                slidesPerView={1.2}
                spaceBetween={10}
                arrows
                slidesOffsetBefore={25}
                slidesOffsetAfter={25}
              >
                {blogs &&
                  blogs.length > 0 &&
                  blogs
                    .filter((blog) => blog.is_featured) // Filter blogs where is_featured is true
                    .map((blog, index) => {
                      return (
                        <>
                          <SwiperSlide>
                            <Stack
                              flexDirection="row"
                              sx={{
                                gap: pxToRem(15),
                                borderRadius: pxToRem(20),
                                backgroundColor: "#EDECE8",
                                overflow: "hidden",
                                cursor: "pointer",
                                height: "100px",
                              }}
                              onClick={() =>
                                (window.location.href = `/blog/${blog.id}`)
                              }
                            >
                              <Box
                                sx={{
                                  padding: {
                                    xs: `${pxToRem(20)}`,
                                    ssm: `${pxToRem(14)} ${pxToRem(
                                      36
                                    )} ${pxToRem(14)} ${pxToRem(15)}`,
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: pxToRem(16),
                                    lineHeight: pxToRem(20),
                                    color: "#333333",
                                    fontWeight: "600",
                                    marginBottom: pxToRem(10),
                                  }}
                                >
                                  {blog.title}
                                </Typography>
                                <Typography
                                  component="p"
                                  fontSize={pxToRem(14)}
                                  fontWeight={600}
                                  color="#BFBEBB"
                                >
                                  {blog?.author
                                    ? `${blog.author.first_name} ${blog.author.last_name}`
                                    : "Unknown Author"}{" "}
                                  •{" "}
                                  {blog?.published_at
                                    ? new Date(
                                        blog.published_at
                                      ).toLocaleDateString("en-US", {
                                        month: "2-digit",
                                        day: "2-digit",
                                        year: "numeric",
                                      })
                                    : "N/A"}
                                </Typography>
                              </Box>
                            </Stack>
                          </SwiperSlide>
                        </>
                      );
                    })}
              </Slider>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={pxToRem(20)}
          rowSpacing={pxToRem(40)}
          sx={{
            marginTop: { xs: pxToRem(20), ssm: pxToRem(60), lg: pxToRem(100) },
          }}
        >
          {blogs &&
            blogs.length > 0 &&
            blogs.map((blog, index) => (
              <Grid item xs={12} ssm={6} md={4} key={index}>
                <Box
                  position="relative"
                  bgcolor="#fff"
                  borderRadius={pxToRem(20)}
                  sx={{
                    transition: "0.2s ease",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: `calc(100% - ${pxToRem(40)})`,
                      height: "100%",
                      backgroundColor: "#FCE181",
                      borderRadius: pxToRem(20),
                      transform: "translate(-50%,-50%)",
                      zIndex: -1,
                      transition: "0.2s ease",
                    },
                    "&:hover": {
                      boxShadow: {
                        xs: "none",
                        sm: `0px ${pxToRem(20)} ${pxToRem(24)} rgba(0,0,0,0.1)`,
                      },
                      "&::before": {
                        height: `calc(100% + ${pxToRem(40)})`,
                      },
                      "& .slideTitle": {
                        color: "primary.main",
                      },
                      ".duration": {
                        opacity: 1,
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: pxToRem(210),
                      borderTopLeftRadius: pxToRem(20),
                      borderTopRightRadius: pxToRem(20),
                      overflow: "hidden",
                    }}
                  >
                    <img
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                      src={blog?.image ? blog?.image : blog?.thumbnail}
                      alt="Blog"
                    />
                  </Box>
                  <Box
                    sx={{
                      padding: {
                        xs: `${pxToRem(30)} ${pxToRem(20)}`,
                        ssm: `${pxToRem(40)} ${pxToRem(38)} ${pxToRem(
                          40
                        )} ${pxToRem(40)}`,
                      },
                      position: "relative",
                    }}
                  >
                    <Stack
                      width={{
                        xs: "calc(100% - 40px)",
                        ssm: "90%",
                        sm: "calc(100% - 60px)",
                        md: "90%",
                        lg: "calc(100% - 60px)",
                      }}
                      flexDirection="row"
                      alignContent="center"
                      justifyContent="flex-end"
                      overflow="hidden"
                      flexWrap="nowrap"
                      gap="8px"
                      position="absolute"
                      top={pxToRem(-45)}
                      right={pxToRem(40)}
                    >
                      <Label>New Post</Label>
                    </Stack>
                    <Typography
                      component="p"
                      fontSize={pxToRem(14)}
                      marginBottom={pxToRem(10)}
                      fontWeight={600}
                      color="#BFBEBB"
                    >
                      {`${
                        blog?.author
                          ? `${blog.author.first_name} ${blog.author.last_name}`
                          : "Unknown Author"
                      } • ${
                        blog?.published_at
                          ? new Date(blog.published_at).toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )
                          : "N/A"
                      }`}
                    </Typography>
                    <Typography
                      className="slideTitle"
                      fontSize={pxToRem(22)}
                      fontWeight={700}
                      lineHeight={pxToRem(30)}
                      color="secondary.main"
                      mb={pxToRem(20)}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {blog?.title}
                    </Typography>
                    <BlockBordered>
                      <Box
                        className={`slideTitle ${getLanguageClass(
                          blog?.content
                        )}`}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "3",
                          WebkitBoxOrient: "vertical",
                          fontSize: pxToRem(16),
                          fontWeight: 500,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: blog.content,
                        }}
                      />
                    </BlockBordered>
                    <TextLink
                      href={`/blog/${blog.id}`}
                      // onClick={handleReadMoreClick}
                      sx={{
                        "& span": { fontSize: pxToRem(16) },
                        marginTop: pxToRem(20),
                      }}
                      // showFullDescription={showFullDescription}
                    >
                      Read More
                    </TextLink>
                  </Box>
                </Box>
              </Grid>
            ))}
          <Grid item xs={12} alignItems="center">
            <Stack alignItems="center" justifyContent="center">
              <Button variant="green">Load More Posts</Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Blogs;
