import {
  Dialog,
  useTheme,
  useMediaQuery,
  Typography,
  Stack,
  Box,
  Button,
  Divider,
  Slide,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "../../components/IconBtnCircular";
import VideoPlayer from "../course/components/VideoPlayer";

import { ReactComponent as Close } from "../../images/close.svg";
import { subscribeToFreeCourse } from "../../api/course";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Link } from "@mui/material";
import RichTextDisplay from "../../components/displayRichText";
import { formatDateDuration, isTodayBetweenDates } from "../../utils/format";
import { useAuth } from "../../utils/AuthContext";
import LoginModal from "../auth/LoginModal";
import { useModal } from "mui-modal-provider";
import LoginSubscribeModal from "./LoginSubscribeModal";
import { getLanguageClass } from "../../utils/languageClass";
import { forwardRef } from "react";
import ModalHeader from "../../components/ModalHeader";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TypographyStyled = styled(Typography)({
  fontSize: "12px",
  fontWeight: 600,
  color: "#BFBEBB",
});

const Hr = styled(Divider)({
  fontSize: "14px",
  fontWeight: 600,
  color: "#BFBEBB",
  margin: `${pxToRem(30)} 0`,
  "&::after, &::before": {
    borderTop: "1px solid #BFBEBB",
  },
  "& span": {
    padding: "0 18px",
  },
});

const PreviewModal = (props) => {
  const { id } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSpecificWidth = useMediaQuery("(max-width:767px)");
  const { openSubscriptionModal, course, ...restProps } = props;
  const navigate = useNavigate();

  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();

  const { showModal, hideModal } = useModal();

  const handleSubscribe = () => {
    if (!isAuthenticated) {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          showModal(LoginModal, { onRedirectOpenModal: "previewModal" });
        },
        loginMessage: "You need to log in first to subscribe to this course.",
        hideModal: () => hideModal(modal.id),
      });
    } else {
      if (course.is_free) {
        subscribeToFreeCourse(course.id);
        navigate(`/course/${course.slug}`);
      } else {
        openSubscriptionModal(course.subscription_price, "normal");
      }
    }
  };

  const handleBuy = () => {
    if (!isAuthenticated) {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          // showModal(LoginModal);
          showModal(LoginModal, { onRedirectOpenModal: "previewModal" });
        },
        loginMessage: "You need to log in first to buy this course.",
        hideModal: () => hideModal(modal.id),
      });
    } else {
      openSubscriptionModal(course.lifetime_price, "lifetime");
    }
  };

  const isOnSale =
    course?.is_on_sale &&
    isTodayBetweenDates(course?.sale_start_date, course?.sale_end_date);

    const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <Dialog
      {...restProps}
      // fullScreen={fullScreen}
      maxWidth="ssm"
      keepMounted
      scroll={mobile_ssm ? "paper": "body"}
      TransitionComponent={mobile_ssm ? Transition : undefined}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          return;
        }
      }}
      sx={{
        "& .MuiDialog-container": {
          alignItems: {xs: "flex-end", ssm: "center"},
        },
        "& .MuiPaper-root": {
          maxWidth: "1060px !important",
          width: { lg: "100%" },
          minHeight: "300px",
          borderRadius: {xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`, ssm: pxToRem(20)},
          verticalAlign: "center",
          backgroundColor: "#F7F6F2",
          margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
          maxHeight: {xs: "85vh", ssm: "none"},
        },
        zIndex: 1500,
      }}
    >
      {mobile_ssm && <ModalHeader
        title={course.name}
        inModal={true}
        onClose={() => props.onClose()}
      />}
      <DialogContent sx={{p: 0}}>
        <Stack flexDirection={{ xs: "column-reverse", lg: "row" }}>
          <Stack
            width={{ xs: "100%", lg: 305 }}
            bgcolor="#EDECE8"
            textAlign="center"
            p={{
              xs: `${pxToRem(30)} ${pxToRem(25)}`,
              ssm: `${pxToRem(40)} ${pxToRem(40)}`,
              sm: `${pxToRem(40)} ${pxToRem(60)}`,
            }}
          >
            {course.is_free ? (
              <Button
                onClick={handleSubscribe}
                fullWidth
                variant="yellow"
                fontSize="14px"
                sx={{ height: pxToRem(54), mb: "15px" }}
              >
                Subscribe For Free
              </Button>
            ) : (
              <>
                <Stack
                  flexDirection={{ sm: "row", lg: "column" }}
                  justifyContent={{ xs: "space-evenly", lg: "flex-start" }}
                >
                  <Box>
                    <Button
                      onClick={handleSubscribe}
                      fullWidth
                      variant="yellow"
                      fontSize="14px"
                      sx={{ height: pxToRem(54), mb: "15px" }}
                    >
                      {isOnSale ? (
                        <>
                          <Typography component="span" fontWeight={600}>
                            {course.sale_price <= 0
                              ? "Subscribe For Free"
                              : `Subscribe: $${course.sale_price}`}
                          </Typography>

                          <Typography
                            fontSize="10px"
                            fontWeight="600"
                            color="#242424"
                            sx={{
                              textDecoration: "line-through",
                              marginLeft: 0.2,
                              transform: "translateY(0.5px)",
                            }}
                          >
                            {course.subscription_price > 0 &&
                              `$${course.subscription_price}`}
                          </Typography>
                        </>
                      ) : (
                        <Typography component="span" fontWeight={600}>
                          {course.subscription_price <= 0
                            ? "Subscribe For Free"
                            : `Subscribe: $${course.subscription_price}`}
                        </Typography>
                      )}
                    </Button>
                    <TypographyStyled component="p" sx={{ color: "#BFBEBB" }}>
                      {`Valid for ${formatDateDuration(course.duration)}`}
                      {course?.is_prolong_allowed
                        ? `. Renewal is available for $${course?.prolong_price} upon expiration.`
                        : ""}
                    </TypographyStyled>
                  </Box>
                  <Hr sx={{ color: "#BFBEBB" }}>or</Hr>
                  <Box>
                    <Button
                      onClick={handleBuy}
                      fullWidth
                      variant="outlined"
                      fontSize="14px"
                      sx={{
                        height: pxToRem(54),
                        mb: "15px",
                        borderWidth: "2px",
                        "&:hover": { borderWidth: "2px" },
                      }}
                    >
                      Buy: ${course.lifetime_price}
                    </Button>
                    <TypographyStyled
                      component="p"
                      sx={{ marginBottom: "20px", color: "#BFBEBB" }}
                    >
                      Valid Forever
                    </TypographyStyled>
                  </Box>
                </Stack>
              </>
            )}
            <TypographyStyled component="p" textAlign="left" mt="auto">
              * Please note that all purchases are final. By purchasing, you agree
              to our{" "}
              <Link
                component={NavLink}
                to="/terms-conditions"
                sx={{
                  textDecoration: "none",
                  color: "#026670",
                  position: "relative",
                  "&:hover": {
                    color: "#333333",
                  },
                  "&::after": {
                    content: "''",
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    height: "2px",
                    width: "0",
                    backgroundColor: "#333333",
                    transition: "width 0.3s ease",
                  },
                  "&:hover::after": {
                    width: "100%",
                  },
                }}
              >
                Terms & Conditions
              </Link>
              . No refunds will be provided after a course is purchased,
              regardless of the circumstances. Please review the course content
              and details carefully before proceeding with your purchase.
            </TypographyStyled>
          </Stack>

          <Box
            flex={1}
            p={{
              xs: `${pxToRem(30)} ${pxToRem(25)}`,
              ssm: `${pxToRem(40)} ${pxToRem(25)}`,
              sm: `${pxToRem(40)} ${pxToRem(60)}`,
            }}
          >
            {!mobile_ssm && <Stack
              direction="row"
              alignItems="baseline"
              justifyContent="flex-end"
              gap="30px"
            >
              <Typography
                fontSize={pxToRem(30)}
                lineHeight="30px"
                fontWeight="900"
                fontFamily="PloniBold"
                letterSpacing="-0.44px"
                textAlign="right"
                sx={{
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                }}
                className={getLanguageClass(course?.name, false, true)}
              >
                {course.name}
              </Typography>
              <IconBtnCircular
                onClick={() => props.onClose()}
                sx={{
                  width: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
                  height: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
                }}
              >
                <Close color="#026670" />
              </IconBtnCircular>
            </Stack>}
            <Typography
              variant="medium"
              component="p"
              textAlign="right"
              m={{
                xs: `0 0 ${pxToRem(30)}`,
                ssm: `${pxToRem(20)} 0 ${pxToRem(30)}`,
                sm: `${pxToRem(20)} 0 ${pxToRem(40)}`,
                md: `${pxToRem(20)} 0 ${pxToRem(65)}`,
              }}
              sx={{
                wordBreak: "break-word",
                whiteSpace: "normal",
              }}
              className={getLanguageClass(course?.subtitle)}
            >
              {course.subtitle}
            </Typography>

            <VideoPlayer
              preview={course}
              noListen={true}
              id={id}
              mediaControlsHeight={52}
              modalScreen={true}
              startsPlaying={isSpecificWidth ? true : false}
              isPreview
            />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewModal;
