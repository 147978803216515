import axios from "axios";
import { url } from "./config";

// General Settings

export async function getGeneralSettings() {
  const res = await axios.get(`${url}/api/cms/general_settings/`);
  return res.data;
}

//Navigation Menu

export async function getNavigationMenu() {
  const res = await axios.get(`${url}/api/cms/navigation_menu/`);
  return res.data;
}

//Hero Section

export async function getHeroSection() {
  const res = await axios.get(`${url}/api/cms/hero_section/`);
  return res.data;
}

// Privacy Policy
export async function getPrivacyPolicy() {
  const res = await axios.get(`${url}/api/cms/policy_and_privacy/`);
  return res;
}

// Terms and Conditions

export async function getTermsAndConditions() {
  const res = await axios.get(`${url}/api/cms/terms_and_conditions/`);
  return res;
}

// FAQs :
export async function getFAQs() {
  const res = await axios.get(`${url}/api/cms/faq/`);
  return res.data;
}

// Tag cloud

export async function getTagCloud() {
  const res = await axios.get(`${url}/api/cms/tag_cloud/`);
  return res;
}

// Presentation section

export async function getPresentationSection() {
  const res = await axios.get(`${url}/api/cms/presentation_section/`);
  return res;
}

// Philosophy section

export async function getContactSection() {
  const res = await axios.get(`${url}/api/cms/contact_section/`);
  return res;
}

// Promotion Section

export async function getPromotionSection() {
  const res = await axios.get(`${url}/api/cms/promotion_section/`);
  return res;
}

// Donate settings

export async function getDonationSettings() {
  const res = await axios.get(`${url}/api/cms/donations/`);
  return res;
}

// Footer settings

export async function getFooterSettings() {
  const res = await axios.get(`${url}/api/cms/footer/`);
  return res;
}

export async function getFeaturedCourses() {
  const res = await axios.get(`${url}/api/cms/featured_courses?status=active`);
  return res;
}

export async function getAboutUs() {
  const res = await axios.get(`${url}/api/cms/about_us_settings/`);
  return res;
}

export async function getBookings() {
  const res = await axios.get(`${url}/api/cms/bookings_settings/`);
  return res;
}
