import { useState, useEffect, useRef, useCallback, createContext } from "react";

import {
  Box,
  Button,
  Container,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  Tooltip,
  Drawer,
  LinearProgress,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import VideoPlayer from "./components/VideoPlayer";
import Sidebar from "./components/Sidebar";
import CourseTabs from "./CourseTabs";
import VideosSection from "../../components/VideosSection";
import BlockBordered from "../../components/BlockBordered";
import LinkWithIcon from "../../components/LinkWithIcon";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getCourse, getLessons, subscribeToFreeCourse } from "../../api/course";

import Toast from "../../components/Toast";
import SubscriptionFeedbackModal from "./components/SubscriptionFeedbackModal";
import FooterTabs from "../../components/courses/FooterTabs";
import Testimonial from "../../components/Testimonial";
import TextLink from "../../components/TextLink";
import { ReactComponent as Chevron } from "../../../src/images/chevron.svg";
import OverviewTab from "./OverviewTab";
import shouldNavigateToPresubscribe from "../../utils/subscription";
import {
  createCheckoutSession,
  resolveCheckoutSession,
} from "../../api/payment";
import { Close } from "@mui/icons-material";
import IconBtnCircular from "../../components/IconBtnCircular";

import { getLanguageClass } from "../../utils/languageClass";
import { prolongSubscription, renewSubscription } from "../../api/student";
import { useModal } from "mui-modal-provider";
import LoginSubscribeModal from "../preSubscribe/LoginSubscribeModal";
import LoginModal from "../auth/LoginModal";
import PreviewModal from "../preSubscribe/PreviewModal";
import PurchaseModal from "../preSubscribe/PurchaseModal";
import ThankModal from "../preSubscribe/ThankModal";
import ErrorModal from "../preSubscribe/ErrorModal";
import ProlongModal from "./components/ProlongModal";
import CoursePageLoading from "../../components/CoursePageLoading";

export const CourseContext = createContext();

const Course = () => {
  //
  const { showModal, hideModal } = useModal();
  const { id } = useParams();
  const summaryRef = useRef(null);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState({});
  const [lessons, setLessons] = useState([]);
  // const memoizedLessons = useMemo(() => lessons, [lessons]);
  const [activeTab, setActiveTab] = useState(0);
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const [notes, setNotes] = useState([]);
  const [playIcon, setPlayIcon] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const [bookmarkListChanged, setBookmarkListChanged] = useState(false);
  const [noteListChanged, setNoteListChanged] = useState(false);
  const [pausedOrExpired, setPausedOrExpired] = useState(false);
  const [playingLessonId, setPlayingLessonId] = useState(null);
  const [previousLessonStates, setPreviousLessonStates] = useState({});
  const [nextLecture, setNextLecture] = useState(false);
  const [lastLesson, setLastLesson] = useState(false);
  const [blockAutoLectureId, setBlockAutoLectureId] = useState(false);
  const [bookmarkStartTime, setBookmarkStartTime] = useState(null);
  const [fetchCourseAgain, setFetchCourseAgain] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [success, setSuccess] = useState(null);
  const [cancel, setCancel] = useState(null);
  const [courseProgress, setCourseProgress] = useState(0);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [latestLessonId, setLatestLessonId] = useState(null);
  const [hideJumpToLatestProgress, setHideJumpToLatestProgress] =
    useState(false);
  const [drivingMode, setDrivingMode] = useState(false);
  const [forceJumpToLatestLecture, setForceJumpToLatestLecture] =
    useState(false);

  const [isNotFound, setIsNotFound] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({});

  const [lessonLockedAndSelected, setLessonLockedAndSelected] = useState(false);

  const [loadingLessons, setLoadingLessons] = useState(true);

  // bookmarks
  const [expiredSubToast, setExpiredSubToast] = useState(false);
  const currentTime = useRef(null);

  const [openSubscriptionFeedback, setOpenSubscriptionFeedback] =
    useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [openProlongModal, setOpenProlongModal] = useState(false);

  //

  const completedCount = lessons?.filter(
    (lesson) => lesson.progress > 90
  ).length;
  const totalCount = lessons?.length;
  const completedStatus = course.user_subscription?.id
    ? `${completedCount}/${totalCount} Completed`
    : "";

  const isCourseCompleted =
    totalCount !== 0 && completedCount / totalCount === 1;

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  //
  const currentPlayingLesson = lessons?.find(
    (lesson) => lesson.id === playingLessonId
  );

  const isEmptyRichText = useCallback(
    (text) => text === "<p><br></p>" || text === "",
    []
  );

  const hasContent =
    !isEmptyRichText(currentPlayingLesson?.summary) ||
    !isEmptyRichText(currentPlayingLesson?.refs) ||
    (currentPlayingLesson?.files && currentPlayingLesson.files.length > 0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && (
          <Box display="flex" flexDirection="column">
            {children}
          </Box>
        )}
      </div>
    );
  }

  // feedback & action
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isAssociate = params.get("is_associate");
    const isNormalSubscription = params.get("is_normal_subscription");
    const isGifted = params.get("is_gifted");
    const successParam = params.get("success");
    const cancelParam = params.get("cancel");
    const jumpToLatestLecture = params.get("jump_to_latest");
    setSuccess(successParam);
    setCancel(cancelParam);
    if (successParam === "yes" || cancelParam === "yes") {
      if (isAssociate === "yes") {
        setModalTitle("Add Partner");
      } else if (isNormalSubscription === "yes") {
        setModalTitle("Course Subscription");
      } else if (isGifted === "yes") {
        setModalTitle("Gift Course");
      }
      setModalOpen(true);
    }
    // lecture available
    if (jumpToLatestLecture === "true") {
      setForceJumpToLatestLecture(true);
      clearParams();
    }
  }, [location]);

  const handleClose = () => {
    clearParams();
    setModalOpen(false);
  };

  const clearParams = () => {
    const newUrl = window.location.pathname;
    window.history.replaceState(null, "", newUrl);
  };

  //
  const handleTabClick = (event, newValue) => {
    setOpenDropdownIndex((prevOpenDropdown) =>
      prevOpenDropdown === newValue ? null : newValue
    );
  };

  // course
  const getCourseData = async () => {
    try {
      const res = await getCourse(id);
      setCourse(res.data);
    } catch (err) {
      console.error("Error fetching course data:", err);
      if (err.response?.status === 404) {
        setIsNotFound(true);
      }
    }
  };
  // next
  const handleNextLectureClick = useCallback(() => {
    setBlockAutoLectureId(true);
    setVideoEnded(false);
    setForceJumpToLatestLecture(false);

    const overlayElement = document.querySelector(".end-overlay");
    if (overlayElement) {
      overlayElement.style.display = "none";
    }

    if (playingLessonId !== null) {
      setBookmarkStartTime(null);
      const currentLessonIndex = lessons.findIndex(
        (lesson) => lesson.id === playingLessonId
      );
      const nextLesson = lessons[currentLessonIndex + 1];
      if (nextLesson && nextLesson.status !== "locked") {
        setPlayingLessonId(nextLesson.id);
        setSelectedLesson(nextLesson);
      } else if (nextLesson?.status === "locked") {
        setToastOpen(true);
        setToastMessage(`Hours remaining: ${nextLesson.hours_remaining}`);
      }
    }
  }, [lessons, playingLessonId]);

  // go to resources tab
  const openResourcesTab = useCallback(() => {
    setActiveTab(4);
    setExpandedPanel("panel1");
    setTimeout(() => {
      if (summaryRef.current) {
        summaryRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 650);
  }, []);
  //select lesson
  const handleLessonClick = useCallback((lesson) => {
    if (lesson?.status !== "paused" && lesson?.status !== "expired") {
      setLessonLockedAndSelected(false);
      setSelectedLesson(lesson);

      const overlayElement = document.querySelector(".end-overlay");
      if (overlayElement) {
        overlayElement.style.display = "none";
      }
    }
  }, []);
  // update lessons
  const updateLessons = useCallback((updatedLessons) => {
    setLessons(updatedLessons);
  }, []);

  useEffect(() => {
    getCourseData();
    document.title = { course }?.name || "Course";
  }, [
    id,
    bookmarks,
    notes,
    nextLecture,
    playingLessonId,
    fetchCourseAgain,
    videoEnded,
  ]);

  // lessons
  useEffect(() => {
    let isCanceled = false;
    setLoadingLessons(true);
    const startTime = Date.now();
    const getLessonsData = async () => {
      try {
        const lessonsResponse = await getLessons(id);
        setLessons(lessonsResponse.data?.lessons);
        if (lessonsResponse.data?.lessons.length > 0) {
          const lastLessonId =
            lessonsResponse.data?.lessons[
              lessonsResponse.data?.lessons.length - 1
            ].id;
          setLastLesson(playingLessonId === lastLessonId);
        }
        if (forceJumpToLatestLecture) {
          const availableLessons = lessonsResponse.data?.lessons.filter(
            (lesson) => lesson.status === "available"
          );
          if (availableLessons.length > 0) {
            const lastAvailableLesson =
              availableLessons[availableLessons.length - 1];
            setPlayingLessonId(lastAvailableLesson.id);
            setSelectedLesson(lastAvailableLesson);
            return;
          }
        }
        if (!blockAutoLectureId && !videoEnded) {
          if (
            lessonsResponse.data?.lessons[0].status === "expired" ||
            lessonsResponse.data?.lessons[0].status === "paused"
          ) {
            setPlayingLessonId(null);
            setPausedOrExpired(true);
          } else {
            const currentLessonId = lessonsResponse.data?.current_lesson?.id;
            const lessons = lessonsResponse.data?.lessons || [];
            const currentLesson = lessons.find(
              (lesson) => lesson.id === currentLessonId
            );
            const isLessonComplete = (lesson) =>
              lesson?.last_tracked === lesson?.length;

            let currentLessonIndex = lessons.findIndex(
              (lesson) => lesson.id === currentLessonId
            );
            while (currentLessonIndex < lessons.length) {
              const nextLesson = lessons[currentLessonIndex];
              // Stop if next lesson is locked
              if (nextLesson.status === "locked") {
                setPlayingLessonId(nextLesson.id);
                setSelectedLesson(nextLesson);
                setLessonLockedAndSelected(true);
                break;
              }
              // If not complete, select it
              if (!isLessonComplete(nextLesson)) {
                setPlayingLessonId(nextLesson.id);
                setSelectedLesson(nextLesson);
                break;
              }
              currentLessonIndex++;
            }
            // If we reached the end without finding an eligible lesson,
            if (currentLessonIndex >= lessons.length) {
              setPlayingLessonId(currentLessonId);
              setSelectedLesson(currentLesson);
            }
          }
        }
      } catch (error) {
        console.log("Error fetching lessons:", error);
      } finally {
        setBlockAutoLectureId(false);
        const elapsedTime = Date.now() - startTime;
        const minDisplayTime = 2000;
        if (elapsedTime < minDisplayTime) {
          setTimeout(() => {
            if (!isCanceled) {
              setLoadingLessons(false);
            }
          }, minDisplayTime - elapsedTime);
        } else {
          if (!isCanceled) {
            setLoadingLessons(false);
          }
        }
      }
    };
    if (videoEnded) {
      const delay = 1000;
      const timeoutId = setTimeout(() => {
        if (!isCanceled) {
          getLessonsData();
        }
      }, delay);
      return () => clearTimeout(timeoutId);
    } else {
      getLessonsData();
    }
    return () => {
      isCanceled = true;
    };
  }, [id, playingLessonId, videoEnded, forceJumpToLatestLecture]);

  useEffect(() => {
    if (course?.progress) {
      setCourseProgress(course?.progress?.toFixed(2));
    } else {
      setCourseProgress(0);
    }
  }, [course]);

  // is subscription active
  const isSubscriptionActive = () => {
    if (course.user_subscription?.status !== "Active") {
      setExpiredSubToast(true);
      return false;
    }
    return true;
  };

  // scroll
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // navigateToLesson
  const navigateToLesson = (lesson, timeStamp) => {
    const overlayElement = document.querySelector(".end-overlay");
    if (overlayElement) {
      overlayElement.style.display = "none";
    }
    setBlockAutoLectureId(true);
    setSelectedLesson(lesson);
    setPlayingLessonId(lesson.id);
    setBookmarkStartTime(timeStamp);
    scrollToTop();
  };

  // start: hide jump to latest progress

  const findLatestLessonWithWatchTime = () => {
    console.log("debugglatestlessonshould called");
    const latestLesson = [...lessons]
      .reverse()
      .find((lesson) => lesson.watch_time > 0);

    if (latestLesson) {
      setLatestLessonId(latestLesson.id);
    } else {
      setHideJumpToLatestProgress(true);
    }
  };

  const checkIfHideJumpToLatest = () => {
    const latestLessonIndex = lessons.findIndex(
      (lesson) => lesson.id === latestLessonId
    );
    const playingLessonIndex = lessons.findIndex(
      (lesson) => lesson.id === playingLessonId
    );
    if (playingLessonIndex >= latestLessonIndex) {
      setHideJumpToLatestProgress(true);
      console.log("debugglatestlesson should be hidden");
    } else {
      setHideJumpToLatestProgress(false);
      console.log("debugglatestlessonshould be visible");
    }
  };

  // jump to latest progress
  const jumpToLatestProgress = () => {
    if (!isSubscriptionActive()) return;
    const latestLesson = [...lessons]
      .reverse()
      .find((lesson) => lesson.watch_time > 0);
    if (latestLesson) {
      navigateToLesson(latestLesson, latestLesson.watch_time);
    }
  };

  // jump to bookmark
  const jumpToBookMark = (timeStamp, lessonId) => {
    if (!isSubscriptionActive()) return;
    const selectedLesson = lessons.find((lesson) => lesson.id === lessonId);
    if (selectedLesson) {
      navigateToLesson(selectedLesson, timeStamp);
    }
  };

  // resubscribe
  const handleResubscribe = async (priceType, priceClass, prolongBy) => {
    try {
      const response = await createCheckoutSession(
        course.id,
        priceType,
        priceClass,
        prolongBy
      );
      window.location.href = response.data.checkout_url;
    } catch (error) {
      console.log("Error creating checkout session:", error);
    }
  };

  // resolve
  const handleResolve = async (subscriptionId) => {
    try {
      const response = await resolveCheckoutSession(subscriptionId);
      window.location.href = response.data.checkout_url;
    } catch (error) {
      console.log("Error creating checkout session:", error);
    }
  };

  //start: renew subscription

  const showSubscriptionModal = useCallback(
    (price, type) => {
      const purchaseModal = showModal(PurchaseModal, {
        course: course,
        type: type,
        price: price,
        openThankModal: () => {
          hideModal(purchaseModal.id);
          showModal(ThankModal);
        },
        openErrorModal: () => {
          hideModal(purchaseModal.id);
          const errorModal = showModal(ErrorModal, {
            openPurchaseModal: () => {
              hideModal(errorModal.id);
              showModal(PurchaseModal, { course: course });
            },
          });
        },
      });
    },
    [course, showModal, hideModal] // Add dependencies here
  );
  //end: renew subscription

  //start: renew prolong email notification

  const removeParamFromURL = (paramName) => {
    const url = new URL(window.location.href);
    url.searchParams.delete(paramName);
    window.history.replaceState({}, document.title, url);
  };

  const handleCloseModal = () => {
    setOpenSubscriptionFeedback(false);
    removeParamFromURL("subscription");
    removeParamFromURL("prolong");
  };

  // start: email notification
  const renewSubscriptionHelper = async (token, action) => {
    try {
      let response;

      if (action === "renew") {
        response = await renewSubscription(token);
        console.log("Renew response: ", response);
        showSubscriptionModal(course.subscription_price, "normal");
      } else if (action === "prolong") {
        response = await prolongSubscription(token);
        console.log("Prolong response: ", response);
        setOpenProlongModal(true);
      } else {
        throw new Error("Invalid action provided.");
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.detail;

      if (errorMsg === "Invalid renew link.") {
        setModalTitle(
          action === "renew" ? "Renew Subscription" : "Prolong Subscription"
        );
        setModalMessage(
          action === "renew"
            ? "It seems like you're logged in with the wrong account to resubscribe to this course."
            : "It seems like you're logged in with the wrong account to prolong the subscription for this course."
        );
        setOpenSubscriptionFeedback(true);
        setSuccess(false);
      } else {
        setModalTitle("Error");
        setModalMessage(
          "An unexpected error occurred. Please try again later."
        );
        setOpenSubscriptionFeedback(true);
        setSuccess(false);
      }
    }
  };
  // end: email notification

  //start: play previous lecture
  const handlePlayPreviousLecture = useCallback(() => {
    setBlockAutoLectureId(true);
    setVideoEnded(false);
    setLessonLockedAndSelected(false);

    const currentIndex = lessons.findIndex(
      (lesson) => lesson.id === playingLessonId
    );

    if (currentIndex > 0) {
      const previousLesson = lessons[currentIndex - 1];
      setPlayingLessonId(previousLesson.id);
      setSelectedLesson(previousLesson);
    } else {
      console.log("No previous lesson available.");
    }

    setBookmarkStartTime(null);
  }, [lessons, playingLessonId]);

  useEffect(() => {
    findLatestLessonWithWatchTime();
  }, [lessons]);

  useEffect(() => {
    if (latestLessonId !== null) {
      checkIfHideJumpToLatest();
    }
  }, [latestLessonId, playingLessonId]);

  // start: navigation

  // redirect to presubscribe if subscription is required
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams();
    const renewTokenFromURL = params.get("subscription");
    const prolongTokenFromURL = params.get("prolong");
    const localRenewToken = localStorage.getItem("renewToken");
    const localProlongToken = localStorage.getItem("prolongToken");
    if (!course?.id) return;
    if (
      renewTokenFromURL ||
      localRenewToken ||
      localProlongToken ||
      prolongTokenFromURL
    ) {
      if (renewTokenFromURL || localRenewToken) {
        newParams.set(
          "subscription",
          localRenewToken ? localRenewToken : renewTokenFromURL
        );
        localStorage.removeItem("renewToken");
      }
      if (prolongTokenFromURL || localProlongToken) {
        newParams.set(
          "prolong",
          localProlongToken ? localProlongToken : prolongTokenFromURL
        );
        localStorage.removeItem("prolongToken");
      }
    }
    if (id && course?.subscription_status) {
      if (shouldNavigateToPresubscribe(course.subscription_status)) {
        navigate(`presubscribe?${newParams.toString()}`, { replace: true });
        return;
      } else {
        if (renewTokenFromURL) {
          renewSubscriptionHelper(renewTokenFromURL, "renew");
        } else if (prolongTokenFromURL) {
          renewSubscriptionHelper(prolongTokenFromURL, "prolong");
        } else if (localRenewToken) {
          navigate(`?${newParams.toString()}`, { replace: true });
        } else if (localProlongToken) {
          navigate(`?${newParams.toString()}`, { replace: true });
        }
      }
    }
  }, [id, course?.id, course?.subscription_status, window.location.search]);

  // Redirect to 404 if course is not found
  if (isNotFound) {
    navigate(`/404`);
  }
  //end: navigation

  // devOnly
  //start: analyze performance
  const prevProps = useRef({});
  useEffect(() => {
    const currentProps = {
      subscriptionId: course?.user_subscription?.id,
      courseId: course?.id,
      lessons,
      onLessonClick: handleLessonClick,
      updateLessons,
      completedStatus,
      isSubscribed: course.is_subscribed,
      subscriptionStatus: course?.subscription_status,
      playingLessonId,
      setPlayingLessonId,
      previousLessonStates,
      setPreviousLessonStates,
      playIcon,
      nextLecture,
      setBlockAutoLectureId,
      setForceJumpToLatestLecture,
      isCollapsable: true,
      lessonLockedAndSelected,
      showSubscriptionModal,
      isProlongAllowed: course?.is_prolong_allowed,
    };
    const changes = {};
    Object.keys(currentProps).forEach((key) => {
      if (currentProps[key] !== prevProps.current[key]) {
        changes[key] = {
          before: prevProps.current[key],
          after: currentProps[key],
        };
      }
    });
    if (Object.keys(changes).length > 0) {
      console.log("Props changes detected in Sidebar:", changes);
    }
    prevProps.current = currentProps;
  }, [
    course?.user_subscription?.id,
    course?.id,
    lessons,
    handleLessonClick,
    updateLessons,
    completedStatus,
    course.is_subscribed,
    course?.subscription_status,
    playingLessonId,
    setPlayingLessonId,
    previousLessonStates,
    setPreviousLessonStates,
    playIcon,
    nextLecture,
    setBlockAutoLectureId,
    setForceJumpToLatestLecture,
    true,
    lessonLockedAndSelected,
    showSubscriptionModal,
    course?.is_prolong_allowed,
  ]);
  //end: analyze performance

  return (
    <>
      {loadingLessons && <CoursePageLoading />}

      <CourseContext.Provider
        value={{
          course,
        }}
      >
        <Toast
          open={expiredSubToast}
          onClose={() => setExpiredSubToast(false)}
          message="Your enrollment is not active"
        />
        <Box position="relative" zIndex={3}>
          <Guides color="rgba(0,0,0,0.02)" zIndex={-1} pb={pxToRem(30)} />
          <Header
            pausedOrExpired={pausedOrExpired}
            drivingMode={drivingMode}
            setDrivingMode={setDrivingMode}
            nonSticky
          />
          <Box className="header-padding"></Box>
        </Box>

        <Drawer
          anchor="right"
          open={drivingMode}
          onClose={() => setDrivingMode(false)}
          sx={{
            zIndex: 1500,
            "& .MuiPaper-root": {
              width: "100%",
              bgcolor: "#F7F6F2",
              overflowY: "initial",
              overflow: "hidden",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "30px 0 0 30px",
            }}
          >
            <IconBtnCircular onClick={() => setDrivingMode(false)}>
              <Chevron
                color="#026670"
                style={{ transform: "rotate(180deg)", marginRight: pxToRem(3) }}
              />
            </IconBtnCircular>
          </Box>
          <Box sx={{ margin: "20px 30px 0 0" }}>
            <Stack
              className="rtl-section"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
              pb={{ xs: 0, ssm: pxToRem(36) }}
            >
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: { xs: "#F7F6F2", ssm: "unset" },
                  pb: { xs: pxToRem(10), ssm: 0 },
                }}
              >
                {course && course?.name ? (
                  <Typography
                    // variant="sectionTitleHebrew"
                    fontSize={{
                      xs: pxToRem(30),
                      ssm: pxToRem(35),
                      md: pxToRem(40),
                    }}
                    fontWeight={700}
                    lineHeight={pxToRem(40)}
                    paddingLeft={pxToRem(20)}
                    textAlign="right"
                    className={getLanguageClass(course?.name)}
                    fontFamily="PloniBold"
                  >
                    {course?.name}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width="70%" height={pxToRem(50)} />
                )}
                {course && course?.subtitle ? (
                  <Tooltip title={mobile_ssm ? course.subtitle : ""}>
                    <Typography
                      fontFamily="PloniDBold"
                      fontSize={pxToRem(20)}
                      fontWeight="600"
                      color="#666564"
                      whiteSpace={{ xs: "nowrap", ssm: "normal" }}
                      overflow={{ xs: "hidden", ssm: "visible" }}
                      textOverflow={{ xs: "ellipsis", ssm: "clip" }}
                      paddingLeft={pxToRem(20)}
                      textAlign="right"
                      className={getLanguageClass(course?.subtitle)}
                    >
                      {course?.subtitle}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Skeleton variant="text" width="40%" height={pxToRem(30)} />
                )}
              </Box>
            </Stack>
          </Box>
          <VideoPlayer
            preview={course}
            selectedLesson={selectedLesson}
            openResourcesTab={openResourcesTab}
            setPlayIcon={setPlayIcon}
            playIcon={playIcon}
            setVideoEnded={setVideoEnded}
            videoEnded={videoEnded}
            setNextLecture={setNextLecture}
            nextLecture={nextLecture}
            lastLesson={lastLesson}
            isLoading={isLoading}
            id={id}
            bookmarkStartTime={bookmarkStartTime}
            setBookmarkStartTime={setBookmarkStartTime}
            handleNextLectureClick={handleNextLectureClick}
            lessons={lessons}
            setBookmarkListChanged={setBookmarkListChanged}
            setNoteListChanged={setNoteListChanged}
            setBlockAutoLectureId={setBlockAutoLectureId}
            currentTime={currentTime}
            // setCurrentTime={setCurrentTime}
            noListen={pausedOrExpired ? true : false}
            drivingMode={drivingMode}
            lessonLockedAndSelected={lessonLockedAndSelected}
            handlePlayPreviousLecture={handlePlayPreviousLecture}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            pausedOrExpired={pausedOrExpired}
            progressRestriction={course?.progress_restriction}
          />
        </Drawer>
        <Box
          className="header-padding"
          position={{ xs: "sticky", ssm: "relative" }}
          mt={{ md: pxToRem(40) }}
          display={"flex"}
          flexDirection={"column"}
          zIndex={{ xs: "3", ssm: "2" }}
          sx={{ top: { xs: "0", ssm: "auto" } }}
        >
          <Container
            disableGutters
            sx={{ display: { xs: "none", ssm: "block" } }}
          >
            <Stack
              className="rtl-section"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
              pb={{ xs: 0, ssm: pxToRem(36) }}
              columnGap={pxToRem(20)}
            >
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: { xs: "#F7F6F2", ssm: "unset" },
                  pb: { xs: pxToRem(10), ssm: 0 },
                }}
              >
                {course && course?.name ? (
                  <Typography
                    fontSize={{
                      xs: pxToRem(30),
                      ssm: pxToRem(35),
                      md: pxToRem(40),
                    }}
                    lineHeight={pxToRem(40)}
                    fontFamily="PloniBold"
                    textAlign="right"
                    className={getLanguageClass(course?.name, false, true)}
                  >
                    {course?.name}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width="70%" height={pxToRem(50)} />
                )}
                <BlockBordered>
                  {course && course?.subtitle ? (
                    <Tooltip title={mobile_ssm ? course.subtitle : ""}>
                      <Typography
                        fontFamily="PloniDBold"
                        fontSize={pxToRem(20)}
                        fontWeight="600"
                        color="#666564"
                        whiteSpace={{ xs: "nowrap", ssm: "normal" }}
                        overflow={{ xs: "hidden", ssm: "visible" }}
                        textOverflow={{ xs: "ellipsis", ssm: "clip" }}
                        paddingLeft={pxToRem(20)}
                        textAlign="right"
                        className={getLanguageClass(course?.subtitle)}
                      >
                        {course?.subtitle}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Skeleton variant="text" width="40%" height={pxToRem(30)} />
                  )}
                </BlockBordered>
              </Box>
              {!mobile_ssm && (
                <LinkWithIcon to="/courses" sx={{ marginTop: pxToRem(14) }}>
                  Back
                </LinkWithIcon>
              )}
            </Stack>
          </Container>
          {!drivingMode && (
            <Container sx={{ px: { xs: 0, md: pxToRem(30) } }} disableGutters>
              <VideoPlayer
                preview={course}
                selectedLesson={selectedLesson}
                openResourcesTab={openResourcesTab}
                setPlayIcon={setPlayIcon}
                playIcon={playIcon}
                setVideoEnded={setVideoEnded}
                videoEnded={videoEnded}
                setNextLecture={setNextLecture}
                nextLecture={nextLecture}
                lastLesson={lastLesson}
                isLoading={isLoading}
                id={id}
                bookmarkStartTime={bookmarkStartTime}
                setBookmarkStartTime={setBookmarkStartTime}
                handleNextLectureClick={handleNextLectureClick}
                lessons={lessons}
                setBookmarkListChanged={setBookmarkListChanged}
                setNoteListChanged={setNoteListChanged}
                setBlockAutoLectureId={setBlockAutoLectureId}
                currentTime={currentTime}
                // setCurrentTime={setCurrentTime}
                noListen={pausedOrExpired ? true : false}
                drivingMode={drivingMode}
                lessonLockedAndSelected={lessonLockedAndSelected}
                handlePlayPreviousLecture={handlePlayPreviousLecture}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                pausedOrExpired={pausedOrExpired}
                progressRestriction={course?.progress_restriction}
              />
            </Container>
          )}
        </Box>
        {pausedOrExpired && tablet && (
          <>
            {course?.subscription_status === "Expired" &&
            !course?.is_prolong_allowed ? (
              <></>
            ) : (
              <Box
                sx={{
                  backgroundColor: "#ffeded",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 20px",
                  width: "100%",
                  marginTop: !mobile_ssm ? "40px" : "0px",
                  marginBottom: mobile_ssm ? "20px" : "0px",
                  gap: 3,
                }}
              >
                <Typography
                  sx={{
                    color: "#ff4d4d",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                  }}
                >
                  {course?.subscription_status === "Expired"
                    ? "Your subscription has expired"
                    : "Your subscription is paused"}
                </Typography>

                <Button
                  sx={{
                    color: "#01373c",
                    fontSize: "14px",
                    fontWeight: 500,
                    "&:hover": { color: "#026670" },
                    padding: "10px 0px",
                    borderRadius: "0px",
                  }}
                  onClick={
                    course?.subscription_status === "Expired"
                      ? () =>
                          showSubscriptionModal(course.prolong_price, "normal")
                      : () => handleResolve(course?.user_subscription?.id)
                  }
                >
                  {course?.subscription_status === "Expired"
                    ? "Resubscribe"
                    : "Resolve"}
                </Button>
              </Box>
            )}
          </>
        )}
        <Container disableGutters>
          <Stack
            className="rtl-section"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            pb={{ xs: 0, ssm: pxToRem(36) }}
            sx={{ display: { xs: "block", ssm: "none" } }}
          >
            <Box
              sx={{
                width: "100%",
                backgroundColor: { xs: "#F7F6F2", ssm: "unset" },
                pb: { xs: pxToRem(10), ssm: 0 },
                pt: { xs: pxToRem(10), ssm: 0 },
              }}
            >
              {course && course?.name ? (
                <Typography
                  variant="sectionTitleHebrew"
                  fontSize={{
                    xs: pxToRem(30),
                    ssm: pxToRem(35),
                    md: pxToRem(40),
                  }}
                  sx={{
                    wordBreak: "break-word",
                    lineHeight: mobile_ssm ? "2rem" : "3rem",
                  }}
                  lineHeight={pxToRem(40)}
                >
                  {course.name}
                </Typography>
              ) : (
                <Skeleton variant="text" width="70%" height={pxToRem(50)} />
              )}
              <BlockBordered>
                {course && course?.subtitle ? (
                  <Tooltip title={mobile_ssm ? course.subtitle : ""}>
                    <Typography
                      fontFamily="PloniDBold"
                      fontSize={pxToRem(20)}
                      fontWeight="600"
                      color="#666564"
                      whiteSpace={{ xs: "nowrap", ssm: "normal" }}
                      overflow={{ xs: "hidden", ssm: "visible" }}
                      textOverflow={{ xs: "ellipsis", ssm: "clip" }}
                      paddingLeft={pxToRem(20)}
                      className={getLanguageClass(course?.subtitle, true)}
                    >
                      {course?.subtitle}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Skeleton variant="text" width="40%" height={pxToRem(30)} />
                )}
              </BlockBordered>
            </Box>
            {!mobile_ssm && (
              <LinkWithIcon to="/courses" sx={{ marginTop: pxToRem(14) }}>
                Back
              </LinkWithIcon>
            )}
          </Stack>
        </Container>
        <Container sx={{ order: 3, display: { ssm: "none" }, padding: 0 }}>
          <Tabs
            sx={{
              "& .MuiTabs-flexContainer": {
                columnGap: { xs: pxToRem(15), ssm: pxToRem(27) },
                justifyContent: {
                  xs: "space-between",
                  sssm: "space-evenly",
                  sm: "flex-start",
                },
              },
              marginTop: pxToRem(12),
              borderTop: "1px solid #BFBEBB",
              borderBottom: `${
                openDropdownIndex === null ? "1px solid #BFBEBB" : ""
              }`,
              minHeight: pxToRem(48),
            }}
            value={false}
            onChange={handleTabClick}
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <Tab
              sx={{
                padding: `${pxToRem(12)} 0`,
                minHeight: pxToRem(48),
                span: {
                  color:
                    openDropdownIndex === 0
                      ? "#333333"
                      : openDropdownIndex === null
                      ? "#026670"
                      : "",
                },
                alignItems: "flex-start",
              }}
              label={
                <Typography
                  component="span"
                  sx={{
                    fontSize: { xs: pxToRem(14), sssm: pxToRem(18) },
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "600",
                    paddingRight: pxToRem(5),
                    color: "#026670",
                    textTransform: "capitalize",
                  }}
                >
                  Course Details
                  <Chevron
                    style={{
                      marginLeft: pxToRem(15),
                      transition: "all 300ms ease-in-out",
                      transform:
                        openDropdownIndex === 0
                          ? "rotate(-90deg)"
                          : "rotate(90deg)",
                    }}
                  />
                </Typography>
              }
            />
            <Tab
              sx={{
                padding: `${pxToRem(12)} 0`,
                minHeight: pxToRem(48),
                span: { color: openDropdownIndex === 1 ? "#333333" : "" },
                alignItems: "flex-start",
              }}
              label={
                <Typography
                  component="span"
                  sx={{
                    fontSize: { xs: pxToRem(14), sssm: pxToRem(18) },
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "600",
                    paddingRight: pxToRem(5),
                    color: "#848484",
                    textTransform: "capitalize",
                  }}
                >
                  Comments (28)
                  <Chevron
                    style={{
                      marginLeft: pxToRem(15),
                      transition: "all 300ms ease-in-out",
                      transform:
                        openDropdownIndex === 1
                          ? "rotate(-90deg)"
                          : "rotate(90deg)",
                    }}
                  />
                </Typography>
              }
            />
          </Tabs>
          <TabPanel value={openDropdownIndex} index={0}>
            <OverviewTab />
          </TabPanel>
          <TabPanel value={openDropdownIndex} index={1}>
            <Typography
              fontSize={{ xs: pxToRem(20), ssm: pxToRem(35) }}
              fontWeight={700}
              my={pxToRem(20)}
            >
              Course Questions
            </Typography>
            <Stack direction={"column"} spacing={pxToRem(26)}>
              <Testimonial modal avatar reply />
              <Testimonial modal reply />
              <Testimonial modal avatar reply />
            </Stack>
            <Stack
              direction={{ xs: "column", ssm: "row" }}
              alignItems="center"
              rowGap={{ xs: pxToRem(20), ssm: "10px" }}
              justifyContent="space-between"
              mt={pxToRem(50)}
            >
              <Button
                variant="yellow"
                sx={{
                  height: pxToRem(54),
                  width: { xs: "100%", ssm: "auto" },
                  order: { xs: 2, ssm: 1 },
                }}
              >
                Rate & Comment
              </Button>
              <TextLink href="" sx={{ order: { xs: 1, ssm: 2 } }}>
                View all 28 Comments
              </TextLink>
            </Stack>
          </TabPanel>
        </Container>
        <Box
          position="relative"
          pt={{
            xs: pxToRem(25),
            ssm: pxToRem(45),
            sm: pausedOrExpired ? pxToRem(45) : pxToRem(100),
            md: pxToRem(100),
          }}
          pb={{
            xs: `${openDropdownIndex === null ? pxToRem(100) : ""}`,
            ssm: 0,
          }}
          mt={{ md: pxToRem(-30) }}
        >
          <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
          <Box
            position="relative"
            pt={{ md: pxToRem(150) }}
            mt={{ md: pxToRem(-110) }}
            mb={{ xs: pxToRem(40), ssm: pxToRem(100), md: pxToRem(150) }}
          >
            <Container
              disableGutters
              sx={{ px: { xs: activeTab === 2 ? "0" : "25px", ssm: "25px" } }}
            >
              <Stack
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                // gap="6.5%"
              >
                {mobile_ssm ? (
                  openDropdownIndex === null && (
                    <TabPanel value={activeTab} index={0}>
                      <Sidebar
                        subscriptionId={course?.user_subscription?.id}
                        courseId={course?.id}
                        lessons={lessons}
                        onLessonClick={handleLessonClick}
                        updateLessons={updateLessons}
                        completedStatus={completedStatus}
                        isSubscribed={course.is_subscribed}
                        subscriptionStatus={course?.subscription_status}
                        playingLessonId={playingLessonId}
                        setPlayingLessonId={setPlayingLessonId}
                        previousLessonStates={previousLessonStates}
                        setPreviousLessonStates={setPreviousLessonStates}
                        playIcon={playIcon}
                        nextLecture={nextLecture}
                        setBlockAutoLectureId={setBlockAutoLectureId}
                        setForceJumpToLatestLecture={
                          setForceJumpToLatestLecture
                        }
                        isCollapsable={true}
                        lessonLockedAndSelected={lessonLockedAndSelected}
                        showSubscriptionModal={showSubscriptionModal}
                        isProlongAllowed={course?.is_prolong_allowed}
                      />
                    </TabPanel>
                  )
                ) : (
                  <Sidebar
                    subscriptionId={course?.user_subscription?.id}
                    courseId={course?.id}
                    lessons={lessons}
                    onLessonClick={handleLessonClick}
                    updateLessons={updateLessons}
                    completedStatus={completedStatus}
                    isSubscribed={course.is_subscribed}
                    subscriptionStatus={course?.subscription_status}
                    playingLessonId={playingLessonId}
                    setPlayingLessonId={setPlayingLessonId}
                    previousLessonStates={previousLessonStates}
                    setPreviousLessonStates={setPreviousLessonStates}
                    playIcon={playIcon}
                    nextLecture={nextLecture}
                    setBlockAutoLectureId={setBlockAutoLectureId}
                    setForceJumpToLatestLecture={setForceJumpToLatestLecture}
                    isCollapsable={true}
                    lessonLockedAndSelected={lessonLockedAndSelected}
                    showSubscriptionModal={showSubscriptionModal}
                    isProlongAllowed={course?.is_prolong_allowed}
                  />
                )}

                {openDropdownIndex === null && (
                  <CourseTabs
                    course={course}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    expandedPanel={expandedPanel}
                    setExpandedPanel={setExpandedPanel}
                    summaryRef={summaryRef}
                    bookmarks={bookmarks}
                    notes={notes}
                    setBookmarks={setBookmarks}
                    setNotes={setNotes}
                    playingLessonId={playingLessonId}
                    setPlayingLessonId={setPlayingLessonId}
                    previousLessonStates={previousLessonStates}
                    setPreviousLessonStates={setPreviousLessonStates}
                    isSubscribed={course?.is_subscribed}
                    subscriptionStatus={course?.subscription_status}
                    setFetchCourseAgain={setFetchCourseAgain}
                    isCourseCompleted={isCourseCompleted}
                    jumpToLatestProgress={jumpToLatestProgress}
                    jumpToBookMark={jumpToBookMark}
                    hasContent={hasContent}
                    currentPlayingLesson={currentPlayingLesson}
                    bookmarkListChanged={bookmarkListChanged}
                    setBookmarkListChanged={setBookmarkListChanged}
                    noteListChanged={noteListChanged}
                    setNoteListChanged={setNoteListChanged}
                    selectedLesson={selectedLesson}
                    currentTime={currentTime}
                    courseProgress={courseProgress}
                    lessons={lessons}
                    hideJumpToLatestProgress={hideJumpToLatestProgress}
                  />
                )}
              </Stack>
            </Container>
          </Box>

          <VideosSection
            courseId={course?.id}
            categoryId={course?.category?.id}
          />
        </Box>

        <Toast
          open={toastOpen}
          onClose={() => {
            setToastOpen(false);
          }}
          message={`Next lecture will be available in ${toastMessage.hours} hours and ${toastMessage.minutes} minutes`}
        />

        <SubscriptionFeedbackModal
          open={modalOpen}
          handleClose={handleClose}
          modalTitle={modalTitle}
          success={success}
          cancel={cancel}
        />

        {/* renew prolong feedback modal */}
        <SubscriptionFeedbackModal
          open={openSubscriptionFeedback}
          handleClose={handleCloseModal}
          modalTitle={modalTitle}
          errorMessage={modalMessage}
          success={success}
          unsubscribe
        />
        <ProlongModal
          open={openProlongModal}
          setOpen={setOpenProlongModal}
          endDate={course?.user_subscription?.end_date}
          duration={course?.duration}
          prolongPrice={course?.prolong_price}
          subscriptionPrice={course?.subscription_price}
          handleLifeLongPurchase={handleResubscribe}
        />

        {/* renew prolong feedback modal */}
        {openDropdownIndex === null && (
          <FooterTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            subscriptionStatus={course?.subscription_status}
            hasContent={hasContent}
          />
        )}
      </CourseContext.Provider>
    </>
  );
};

export default Course;
